import Image from "../../components/Image.js";
import '../../.templates/css/portlets/sponsors/SponsorsItem.css';
import SponsorsTile from "./SponsorsTile.js";
import React from "react";

function SponsorsItem(params) {
    return (
        <>
            <h2 className="pb-15">{params.title}</h2>
            {
                Object.keys(params.item.Tile).map((item, i) => (
                    <SponsorsTile
                        image={params.item.Tile[item]['Image']['path']}
                        altText={params.item.Tile[item]['AltText']}
                        title={params.item.Tile[item]['Title']}
                        Text={params.item.Tile[item]['Text']}
                        Link={params.item.Tile[item]}
                        LinkName={params.item.Tile[item]}
                        NewTab={params.item.Tile[item]['NewTab']}
                        LinkImg={params.item.Tile[item]['LinkImg']}
                    />
                ))}
        </>
    );
}

export default SponsorsItem;