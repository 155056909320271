import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import WidzewFmArchivesItem from './WidzewFmArchivesItem';
import Loaders from "../../Loaders.js";
import Links from '../../components/Links';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { addDays } from 'date-fns';
import pl from 'date-fns/locale/pl';
import Select from '../../components/Select';

let optionsList = [
  {
    "showId": '',
    "title": "Wybierz Audycję"
  },
  {
    "showId": 1,
    "title": "Audycja poranna"
  },
  {
    "showId": 2,
    "title": "Widzewska podróż do przeszłości"
  },
  {
    "showId": 3,
    "title": "Młodzieżówka"
  },
  {
    "showId": 4,
    "title": "Audycja popołudniowa"
  },
  {
    "showId": 5,
    "title": "Program Meczowy"
  },
  {
    "showId": 6,
    "title": "Widzewiak"
  },
  {
    "showId": 7,
    "title": "Witamy w Klubie"
  }
]

const WidzewFmArchives = (params) => {
  const [archivesData, setArchivesData] = useState([]),
        [archivesDataOri, setArchivesDataOri] = useState([]),
        [selectedOption, setSelectedOption] = useState(0),
        [selectedOptionString, setSelectedOptionString] = useState(''),
        [select2, setSelect2] = useState(''),
        [startDate, setStartDate] = useState(new Date());

  registerLocale('pl', pl)

  if (window.origin.includes('localhost')) {
    console.log('widzew archives')
    console.log(params)
  }

  const handleDateChange = e => {
    setStartDate(e);
    setSelect2(e);
    filterFun();
  }

  function filterFun() {
    let tab = [],
        date1 = '',
        date2 = '';
    if (select2) {
      date1 = select2.toLocaleDateString().split('.');
      date2 = date1[2] + '-' + date1[1];
    }

    archivesDataOri.map(a => {
      let stringDate = a.date.split(' ')[0],
          finalString = stringDate.split(' ')[0].split('-')[0] + '-' + stringDate.split(' ')[0].split('-')[1],
          show = true;
      if (!(a.type === parseInt(selectedOptionString)) && selectedOptionString !== '') {
        show = false
      } else if (!(date2 === finalString) && date2 !== '') {
        show = false
      }

      if (show === true) {
        tab.push(a)
      }
      return null
    })

    setArchivesData(tab)
  }

  useEffect(() => {
    filterFun()
  }, [selectedOptionString, startDate])

  let colorBack = ''
  if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
    colorBack = 'b-' + params.block['en']['Background']
  }
  let innerSection = 'widzew-fm-archives';
  let innerContainer = 'container';
  if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
    if (params.block['en']['InnerBackground'] === true) {
      innerContainer = innerContainer + ' ' + colorBack;
    }
    else {
      innerSection = innerSection + ' ' + colorBack;
    }
  }

  useEffect(() => {
    let fetchArchivesData = async () => {
      let link
      if (params.line === 'offline') {
        link = window.origin + '/api/fm/fm.json';
      } else if (window.origin.includes('localhost')) {
        link = 'https://dev-widzew.qrqs.eu/api/fm/fm.json';
      } else {
        link = window.origin + '/api/fm/fm.json';
      }
      // link = 'https://test-widzew.qrqs.eu/api/fm/fm.json'
      let response = "";
      try {
        response = await Axios(link);
        if (response.data) {
          response.data.sort((a, b) => {
            return new Date(b.date) - new Date(a.date)
          })
          setArchivesData(response.data);
          setArchivesDataOri(response.data);
        }
      } catch (err) {
        response = "error";
      }
    };
    fetchArchivesData();
  }, [])

  let arrayArchives = [];
  const Items = () => {
    const [archivesToShow, setArchivesToShow] = useState('empty'),
          itemsPerRender = 10,
          ref = useRef(itemsPerRender);
    const loopWithSlice = (start, end) => {
      const slicedArchives = archivesData.slice(start, end)
      arrayArchives = arrayArchives.concat(slicedArchives)
      setArchivesToShow(arrayArchives)
    }
    useEffect(() => {
      loopWithSlice(0, itemsPerRender)
    }, [])
    const handleShowMorePosts = () => {
      loopWithSlice(ref.current, ref.current + itemsPerRender)
      ref.current += itemsPerRender
    }

    function LoadButton() {
      if (archivesToShow !== 'empty' && archivesToShow.length === archivesData.length) {
        return (
          <div className="ta-center"></div>
        )
      }
      else {
        return (
          <div className="ta-center">
            <a href="#loadmore" className="button b-gold mt-20" onClick={(e) => { e.preventDefault(); handleShowMorePosts() }}>ZAŁADUJ WIĘCEJ</a>
          </div>
        )
      }
    }
    return (
      <>
        {archivesToShow && archivesToShow !== 'empty' && archivesToShow.length > 0 &&
          archivesToShow.map((item, index) => (
            <WidzewFmArchivesItem key={index} item={item} />
          ))}
        {archivesToShow && archivesToShow === 'empty' &&
          <div><h3 className="c-red"><Loaders type="dots" color="b-red" /></h3></div>
        }
        <LoadButton />
      </>
    )
  }

  function generateCMSLink() {
    let url = '';
    url = window.origin + '/nodejs-api/Fm';
    if (window.origin.includes('localhost')) {
      url = 'https://dev-cms-widzew.qrqs.eu/nodejs-api/Fm';
    }
    return url
  }

  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          <div className="widzew-archives-menu-wrapper d-flex jc-end">
            {params.line === 'offline' &&
              <div className="mr-auto">
                <Links line={params.line} url={generateCMSLink()} target="false" class="button b-gold mr-15" name="Zarządzaj archiwum" />
              </div>
            }
            <div className="mb-30 d-flex">
              <Select
                optionsList={optionsList}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setSelectedOptionString={setSelectedOptionString}
                className="widzew-archives-menu mr-15"
              />
              <DatePicker
                selected={select2 !== '' ? startDate : null}
                onChange={handleDateChange}
                dateFormat="LLLL yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                isClearable
                placeholderText="Wybierz datę"
                locale="pl"
                maxDate={addDays(new Date(), 1)}
              />

            </div>
          </div>
          <div className="widzew-fm-archives-wrapper">

            <Items />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WidzewFmArchives