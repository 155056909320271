import React from "react";
import Links from "../../components/Links";
import useWindowDimensions from "../../Device.js";

function TopBannerCarouselItem(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew') || window.origin.includes('cms-widzew')) {
        console.log('TopBannerCarousel Item' )
        console.log(params)
    }
    const { device, width } = useWindowDimensions();

    let Image = params.block.hasOwnProperty('Image') ? (params.block['Image'].hasOwnProperty('link') ? params.block['Image']['link'] : '/.templates/img/logo/herbwidzewa.jpg') : '/.templates/img/logo/herbwidzewa.jpg',
        MobImage = params.block.hasOwnProperty('MobileImage') ? (params.block['MobileImage'].hasOwnProperty('link') ? params.block['MobileImage']['link'] : Image) : Image,
        LinkForMore = params.block.hasOwnProperty('LinkForMore') ? params.block['LinkForMore'] : '',
        LinkNameForMore = params.block.hasOwnProperty('LinkNameForMore') ? params.block['LinkNameForMore'] : '',
        SlideLabel = params.block.hasOwnProperty('SlideLabel') ? params.block['SlideLabel'] : '',
        SlideTitle = params.block.hasOwnProperty('SlideTitle') ? params.block['SlideTitle'] : '',
        ButtonsType = params.block.hasOwnProperty('ButtonsType') ? params.block['ButtonsType'] : 'b-gold',
        InNewOpen = params.block.hasOwnProperty('InNewOpen') ? (params.block['InNewOpen'] === true ? 'true' : 'false') : 'false';
    let img = Image,
        imgMob = '',
        imgUrl = '';

    if (device === 'mobile') {
        imgMob = MobImage
        if (imgMob !== undefined && imgMob !== "") {
            if (imgMob.includes("/sites/widzew")) {
                imgMob = imgMob.split('/sites/widzew');
                imgMob = imgMob[1];
                if (params.line === 'offline') {
                    imgUrl = window.origin + imgMob
                } else if (window.origin.includes('localhost')) {
                    imgUrl = 'https://dev-widzew.qrqs.eu' + imgMob
                } else {
                    imgUrl = window.origin + imgMob
                }
            }
            else if (window.origin.includes("localhost")) {
                imgUrl = 'https://dev-widzew.qrqs.eu' + imgMob
            }
            else {
                imgUrl = imgMob
            }
        }
        else {
            if (img !== undefined && img !== "") {
                if (img.includes("/sites/widzew")) {
                    img = img.split('/sites/widzew');
                    img = img[1];
                    if (params.line === 'offline') {
                        imgUrl = window.origin + img
                    } else if (window.origin.includes('localhost')) {
                        imgUrl = 'https://dev-widzew.qrqs.eu' + img
                    } else {
                        imgUrl = window.origin + img
                    }
                }
                else if (window.origin.includes("localhost")) {
                    imgUrl = 'https://dev-widzew.qrqs.eu' + img
                }
                else {
                    imgUrl = img
                }
            }
            else {
                imgUrl = '/.templates/img/logo/herbwidzewa.jpg'
            }
        }
    }
    else {
        if (img !== undefined && img !== "") {
            if (img.includes("/sites/widzew")) {
                img = img.split('/sites/widzew');
                img = img[1];
                if (params.line === 'offline') {
                    imgUrl = window.origin + img
                } else if (window.origin.includes('localhost')) {
                    imgUrl = 'https://dev-widzew.qrqs.eu' + img
                } else {
                    imgUrl = window.origin + img
                }
            }
            else if (window.origin.includes("localhost")) {
                imgUrl = 'https://dev-widzew.qrqs.eu' + img
            }
            else {
                imgUrl = img
            }
        }
        else {
            imgUrl = '/.templates/img/logo/herbwidzewa.jpg'
        }
    }
    return (
        <div className="carousel-item" style={{ backgroundImage: "url(" + imgUrl + "_width="+width+")" }}>
            <div className="container d-flex f-column ">
                <div className="slide-inner d-flex f-column jc-end">
                    <div className="main-container">
                        <div className="chip-tag d-flex f-row mb-30">
                            <span className="tag d-flex ai-center">{SlideLabel}</span>
                        </div>
                        <div className="main-text mb-30">
                            <h1>{SlideTitle}</h1>
                        </div>
                        {(!(LinkNameForMore === '' || LinkForMore === '')) &&
                            <Links line={params.line} url={LinkForMore} target={InNewOpen} class={"button " + ButtonsType} name={LinkNameForMore} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBannerCarouselItem;