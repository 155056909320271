import React from "react";
import MembersList from "../../portlets/members/MembersList";
import NewsList from "../../portlets/news/NewsList";
import TimetableList from "../../portlets/timetable/TimetableList";
const ListComponents = {
    "members-details-list": MembersList,
    "news-details-list": NewsList,
    "timetable-details-list": TimetableList
};
function ListBox(params) {
    if (window.origin.includes('localhost')) {
        console.log('ListBox')
        console.log(params)
    }
  
    let innerSection = 'list-box b-white';
    let innerContainer = 'container';
    const type = params.block.TypesToCollect[0].split(':')[0]
    const typeList = (type).replace('-details','-list');
    let idList = {'id': typeList};
    return (
        <section className={innerSection} {...idList}>
            <div className="wrapper">
                <div className={innerContainer}>
                    {params.block['Title'] &&
                        <h1>{params.block['Title']}</h1>
                    }
                    {ListComponents.hasOwnProperty(type+'-list') &&
                        React.createElement(ListComponents[type+'-list'], {
                            key: params.block.TypesToCollect[0].split(':')[1],
                            block: params.block,
                            line: params.line
                        })
                    }
                </div>
            </div>
        </section>
    );
}

export default ListBox;