import React, { useState, useEffect } from "react";
import Image from "../../components/Image";
import Links from "../../components/Links";

const ShirtCreatorItem = (params) => {
    let [shirtUrlImg, setShirtUrlImg] = useState(""),
        [shirtUrl, setShirtUrl] = useState(""),
        [shirtPrice, setShirtPrice ] = useState(""),
        [typeShirt, setTypeShirt] = useState('home'),
        [modelShirt, setModelShirt] = useState('extraclass'),
        availableType = [],
        availableModel = [];

    useEffect(() => {
        availableModel = [];
        params.data.map(item => {
            if (typeShirt === item.ShirtType) {
                if (!Object.values(availableModel).includes(item.ShirtModel)) {
                    availableModel.push(item.ShirtModel)
                }
            }
        })
    },[typeShirt]);

    useEffect(() => {
        availableType = [];
        params.data.map(item => {
            if (modelShirt === item.ShirtModel) {
                if (!Object.values(availableType).includes(item.ShirtType)) {
                    availableType.push(item.ShirtType)
                }
            }
        })
    },[modelShirt]);

    useEffect(() => {
        params.data.map(item => {
            if (typeShirt === item.ShirtType && modelShirt === item.ShirtModel) {
                setShirtUrl(item.ShirtUrl);
                setShirtPrice(item.ShirtPrice);
                setShirtUrlImg(item.ShirtImage);
            }
        })

        disabledItem();
    },[typeShirt, modelShirt]);

    params.data.map(item => {
        if (!Object.values(availableType).includes(item.ShirtType)) {
            availableType.push(item.ShirtType)
        }
        if (!Object.values(availableModel).includes(item.ShirtModel)) {
            availableModel.push(item.ShirtModel)
        }
    })

    function disabledItem() {
        let elsType = document.getElementsByClassName('type'),
            elsModel = document.getElementsByClassName('model');

        if (elsType) {
            for (let i = 0; i < elsType.length; i++) {
                if (availableType.includes(elsType[i].dataset.attr)) {
                    elsType[i].classList.remove('disabled')
                } else {
                    elsType[i].classList.add('disabled')
                }
            }
        }

        if (elsModel) {
            for (let i = 0; i < elsModel.length; i++) {
                if (availableModel.includes(elsModel[i].dataset.attr)) {
                    elsModel[i].classList.remove('disabled')
                } else {
                    elsModel[i].classList.add('disabled')
                }
            }
        }
    }

    function toggleClassType(e) {
        if (!e.target.classList.contains('disabled')) {
            let els = document.getElementsByClassName('type active');
            if (els) {
                while (els[0]) {
                    els[0].classList.remove('active')
                }
            }
            e.target.classList.add("active");
            setTypeShirt(e.target.dataset.attr)
        }
    }

    function toggleClassModel(e) {
        if (!e.target.classList.contains('disabled')) {
            let els = document.getElementsByClassName('model active');
            if (els) {
                while (els[0]) {
                    els[0].classList.remove('active')
                }
            }
            e.target.classList.add("active");
            setModelShirt(e.target.dataset.attr)
        }
    }

    function toggleClassSize(e) {
        let els = document.getElementsByClassName('size active');
        if (els) {
            while (els[0]) {
                els[0].classList.remove('active')
            }
        }
        e.target.classList.add("active");
    }

    return (
        <div className="d-flex f-wrap">
            <div className="d-flex col-lg-6 col-sm-12">
                <Image src={shirtUrlImg} width="500" alt="Koszulka" />
            </div>
            <div className="d-flex f-column col-lg-6 col-sm-12">
                <div className="d-flex f-column w-100 col-lg-6 col-sm-12 mb-20">
                    <h2 className="mb-10">Typ</h2>
                    <div className="d-flex f-wrap">
                        <span className="type mr-10 mb-10 button empty-black active" data-attr="home" onClick={(e) => {e.preventDefault();toggleClassType(e);}}>Domowa</span>
                        <span className="type mr-10 mb-10 button empty-black" data-attr="leave" onClick={(e) => {e.preventDefault();toggleClassType(e);}}>Wyjazdowa</span>
                        <span className="type mr-10 mb-10 button empty-black" data-attr="cup" onClick={(e) => {e.preventDefault();toggleClassType(e);}}>Pucharowa</span>
                    </div>
                </div>
                <div className="d-flex f-column w-100 col-lg-6 col-sm-12 mb-20">
                    <h2 className="mb-10">Model</h2>
                    <div className="d-flex f-wrap">
                        <span className="model mr-10 mb-10 button empty-black active" data-attr="extraclass" onClick={(e) => {e.preventDefault();toggleClassModel(e);}}>Ekstraklasa</span>
                        <span className="model mb-10 button empty-black" data-attr="always" onClick={(e) => {e.preventDefault();toggleClassModel(e);}}>Klub</span>
                    </div>
                </div>
                {/*<div className="d-flex f-column w-100 col-lg-6 col-sm-12 mb-20">
                    <h2 className="mb-10">Rozmiar</h2>
                    <div className="d-flex f-wrap">
                        <span className="mr-10 mb-10 size button empty-black active" onClick={(e) => {e.preventDefault();toggleClassSize(e);}}>S</span>
                        <span className="mr-10 mb-10 size button empty-black" onClick={(e) => {e.preventDefault();toggleClassSize(e);}}>M</span>
                        <span className="mr-10 mb-10 size button empty-black" onClick={(e) => {e.preventDefault();toggleClassSize(e);}}>L</span>
                        <span className="mr-10 mb-10 size button empty-black" onClick={(e) => {e.preventDefault();toggleClassSize(e);}}>XL</span>
                        <span className="mb-10 size button empty-black" onClick={(e) => {e.preventDefault();toggleClassSize(e);}}>XXL</span>
                    </div>
                </div>*/}
                {/* <div className="d-flex f-column w-100 col-lg-6 col-sm-12 mb-20">
                    <h2 className="mb-10">Twoje nazwisko</h2>
                    <div className="d-flex f-wrap">
                        <input className="w-70" type="text" />
                    </div>
                </div>
                <div className="d-flex f-column w-100 col-lg-6 col-sm-12 mb-20">
                    <h2 className="mb-10">Numer na koszulce</h2>
                    <div className="d-flex f-wrap">
                        <input className="w-70" type="text" />
                    </div>
                </div> */}
                <div className="d-flex w-100 col-lg-6 col-sm-12 mb-20">
                    {shirtPrice !== '' &&
                        <div className="d-flex f-wrap ai-center">
                            <span className="price">{shirtPrice} ZŁ</span>
                        </div>
                    }
                </div>
                <div className="d-flex w-100 col-lg-6 col-sm-12 mb-20">
                    {shirtUrl !== '' &&
                        <div className="d-flex f-wrap mr-20">
                            <Links line={params.line} url={shirtUrl} target="true" class="button b-gold" name="KUP KOSZULKĘ" />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ShirtCreatorItem