import React from "react";
import ImageBoxItem from "./ImageBoxItem";

function ImageBox(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
        console.log('Image box')
        console.log(params)
    }
    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }
    let itemLength = 'col-box-0'
    if (params.block.en.hasOwnProperty('Item')) {
        itemLength = 'col-box-' + Object.keys(params.block['en']['Item']).length
    }
    let innerSection = 'image-box',
        innerContainer = 'container jc-between',
        imgContainer = 'img-items d-flex pt-20 pb-20 my-0 mx-auto f-row ' + itemLength;

    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }
    let items = params.block['en'].hasOwnProperty('Item') ? params.block['en']['Item'] : [];
    let title = ''
    if (params.block['en']['Title'] !== '' && params.block['en'].hasOwnProperty('Title') && 
        params.block['en']['ShowTitle'] === true && params.block['en'].hasOwnProperty('ShowTitle')) {
        title = (
            <h1>{params.block['en']['Title']}</h1>
        )
    }

    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    {title}
                    <div className={imgContainer}>
                        {Object.keys(items).map((item, index) => (
                            <ImageBoxItem
                                key={index}
                                block={items[index]}
                                line={params.line}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ImageBox;