import React from "react";
import SponsorsListImages from "./SponsorsListImages";
function SponsorsListItem(params) {
    return (
        <div className="small-h">
            <h6>{params.title}</h6>
            <div className="sponsors-item">
                {params.item.hasOwnProperty('Images') && Object.keys(params.item['Images']).map((image, index) => (
                    <SponsorsListImages
                        key={index}
                        block={params.item['Images'][image]}
                    />
                ))}
            </div>
        </div>
    )
}
export default SponsorsListItem;