import React from 'react';
import Image from '../../components/Image';

const SponsorsCarouselItem = (params) => {
  let image = params.block.hasOwnProperty('Image') ? (params.block['Image'].hasOwnProperty('link') ? params.block['Image']['link'] : '/.templates/img/logo/herbwidzewa.jpg') : '/.templates/img/logo/herbwidzewa.jpg',
      AltText = params.block.hasOwnProperty('AltText') ? params.block['AltText'] : 'Widzew Sponsor',
      NewTab = params.block.hasOwnProperty('NewTab') ? (params.block['NewTab'] === true ? 'true' : 'false') : 'false',
      Link = params.block.hasOwnProperty('Link') ? (params.block['Link'].hasOwnProperty('link') ? params.block['Link']['link'] : '') : '';

  return (
      <>
        {
          Link !== '' 
          ? 
            <a
              target={NewTab === 'true' ? '_blank' : ''}
              href={Link}
              className="all-link-img"
            >
              <Image
                src={image}
                alt={AltText}
              />
            </a>
          :
            <Image
                src={image}
                alt={AltText}
            />
        }
      </>
  )
}

export default SponsorsCarouselItem