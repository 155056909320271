import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Loaders from "./Loaders.js";
import Svg from "./components/Svg";

const Redirect = {
  "aktualnosci/": 'aktualnosci/',
  "zespoly/": 'zespoly/druzyna/',
  "rozgrywki/": 'rozgrywki/terminarz-i-wyniki/',
  "klub/": 'klub/struktura/',
  "akademia/": 'akademia/aktualnosci/',
  "mlody-widzew/": 'mlody-widzew/',
  "spolecznosc/": 'spolecznosc/razem-tworzymy-spolecznosc/',
  "biznes/": 'biznes/oferta/'
};
const OuterRedirect = {
  "zespoly/statystyki/": 'https://widzew.statscore.com/'
};

function NavSide(params) {
  if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
    console.log('Build Side Nav')
  }
  let [navData, setNavData] = useState("");
  useParams();
  let [jsonStatus, setJsonStatus] = useState(null);
  let [sideNav, setSideNav] = useState([]);

  useEffect(() => {
    let fetchNavs = async () => {
      let url = null;
      if (window.location.origin.includes("cms-widzew")) {
        url = window.location.origin + "/.includes/nav/nav-json.json";
      }
      else if (window.location.origin.includes("http://localhost")) {
        url = "https://dev-widzew.qrqs.eu/.includes/nav/nav-json.json";
      }
      else {
        url = window.location.origin + "/.includes/nav/nav-json.json";
      }
      let response = "";
      try {
        response = await Axios(url);
        setJsonStatus(true);
      } catch (err) {
        setJsonStatus(false);
        response = "error";
      }
      if (response.hasOwnProperty('data')) {
        setNavData(response.data);
      }
      else {
        setJsonStatus(false);
      }
    };
    fetchNavs();
  },[]);

  if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
    console.log('navData');
    console.log(navData);
  }

  const setNavPath = path => {
    let sideArray = []
    let index = 0
    if (navData) {
      navData.map((item, i) => {
        if (item.path === path) {
          sideArray.push(item)
          index = i
        }
        return null
      })
      sideArray[0].index = index
    }
    setSideNav(sideArray)
  };

  function OpenSide(path) {
    const sidenav = document.getElementById("sidemenu");
    if (sidenav.classList.contains('show')) {
      sidenav.classList.remove("show");
    }
    else if (!sidenav.classList.contains('show')) {
      setTimeout(function(){ 
        sidenav.classList.add("show");
      }, 1);
    }
    setNavPath(path)
  };
  function CloseMenu() {
    const sidemenu = document.getElementById("sidemenu");
    if (sidemenu.classList.contains('open')) {
      sidemenu.classList.remove("open");
    }
  };
  function CloseSide() {
    const sidenav = document.getElementById("sidemenu");
    if (sidenav.classList.contains('show')) {
      sidenav.classList.remove("show");
    }
  };
  function SearchLink() {
    if (params.line === "offline") {
      return (
        <li id={`menu-item-search`} key={`menu-item-search`} className="menu-item current">
          <Svg name="SearchIcon" class="menu-icon"/>
          <a href="/wyniki-wyszukiwania/" className="menu-text" onClick={(e) => {CloseMenu();}}>
            <p>Wyszukaj</p>
          </a>
        </li>
      )
    }
    else {
      return (
        <li id={`menu-item-search`} key={`menu-item-search`} className="menu-item current">
          <Svg name="SearchIcon" class="menu-icon"/>
          <Link to="/wyniki-wyszukiwania/" className="menu-text" onClick={(e) => {CloseMenu();}}>
            <p>Wyszukaj</p>
          </Link>
        </li>
      )
    }
  }
  function RenderNavs() {
    return (
      <nav className="menu-transform-items" id="sidenav">
        <ul className="menu-items">
          <SearchLink/>
          <RenderMainNav />
        </ul>
        <ul className="menu-items submenu-items">
          <li className="menu-item back-span" onClick={(e) => {e.preventDefault(); CloseSide();}}>
            <Svg name="BackArrow" class="menu-icon"/>
          </li>
          <RenderSideNav />
        </ul>
      </nav>
    )
  }
  function RenderMainNav() {
    return (
      navData && navData.map((item, i) => {
        return (
          <li id={`menu-item-`+i} key={`menu-item-`+i} className="menu-item current" onClick={(e) => {e.preventDefault();OpenSide(item.path);}}>
            <Svg name={item.path} class="menu-icon"/>
            <span className="menu-text">
              <p>{item.text}</p>
            </span>
            <div className="childs-icon">
              <Svg name="MenuArrowLeft" />
            </div>
          </li>
        )
      })
    )
  }
  function RedirectLink(item) {
    if (item.line === 'online') {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
          <Link to={Redirect[item.link]} className="menu-text">
            <p>{item.text}</p>
          </Link>
        )
      }
      else {
        return (
          <Link to={item.link} className="menu-text">
            <p>{item.text}</p>
          </Link>
        )
      }
    }
    else {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
          <a href={Redirect[item.link]} className="menu-text">
            <p>{item.text}</p>
          </a>
        )
      }
      else {
        return (
          <a href={item.link} className="menu-text">
            <p>{item.text}</p>
          </a>
        )
      }
    }
  }
  function RenderSideNav() {
    return (
      params.line === "offline"
        ? sideNav.map((item, i) => {
          return (
            <>
              <li id={`menu-item-`+item.index+'-'+i} key={`menu-item-`+item.index+'-'+i} className="menu-item" onClick={(e) => {CloseMenu();}}>
                <span className="menu-icon menu-icon-empty"></span>
                <RedirectLink link={item.path} text={item.text} line="offline"/>
              </li>
              {item.children && item.children.map((subitem, ii) => {
                if (OuterRedirect.hasOwnProperty(subitem.path)) {
                  return (
                    <li id={`menu-item-`+item.index+'_'+ii} key={`menu-item-`+item.index+'_'+ii} className="menu-item" onClick={(e) => {CloseMenu();}}>
                      <span className="menu-icon menu-icon-empty"></span>
                      <a href={OuterRedirect[subitem.path]} className="menu-text">
                        <p>{subitem.text}</p>
                      </a>
                    </li>
                  )
                }
                else {
                  return (
                    <li id={`menu-item-`+item.index+'_'+ii} key={`menu-item-`+item.index+'_'+ii} className="menu-item" onClick={(e) => {CloseMenu();}}>
                      <span className="menu-icon menu-icon-empty"></span>
                      <a href={subitem.path} className="menu-text">
                        <p>{subitem.text}</p>
                      </a>
                    </li>
                  )
                }
              })
              }
            </>
          )
        })
        : sideNav.map((item, i) => {
          return (
            <>
              <li id={`menu-item-`+item.index+'-'+i} key={`menu-item-`+item.index+'-'+i} className="menu-item" onClick={(e) => {e.preventDefault();CloseMenu();}}>
                <span className="menu-icon menu-icon-empty"></span>
                <RedirectLink link={item.path} text={item.text} line="online"/>
              </li>
              {item.children && item.children.map((subitem, ii) => {
                if (OuterRedirect.hasOwnProperty(subitem.path)) {
                  return (
                    <li id={`menu-item-`+item.index+'_'+ii} key={`menu-item-`+item.index+'_'+ii} className="menu-item" onClick={(e) => {CloseMenu();}}>
                      <span className="menu-icon menu-icon-empty"></span>
                      <a href={OuterRedirect[subitem.path]} className="menu-text" target="_blank" rel="noreferrer noopener">
                        <p>{subitem.text}</p>
                      </a>
                    </li>
                  )
                }
                else {
                  return (
                    <li id={`menu-item-`+item.index+'_'+ii} key={`menu-item-`+item.index+'_'+ii} className="menu-item" onClick={(e) => {e.preventDefault();CloseMenu();}}>
                      <span className="menu-icon menu-icon-empty"></span>
                      <Link to={subitem.path} className="menu-text">
                        <p>{subitem.text}</p>
                      </Link>
                    </li>
                  )
                }
              })
              }
            </>
          )
        })
    )
  }

  switch (jsonStatus) {
    case null:
      return <ul className="menu"><li className="Loading-nav b-red ml-10"><Loaders type="dots" color="b-white"/></li></ul>;
    case false:
      return (
        <nav className="menu-transform-items" id="sidenav">
          <ul className="menu-items">
            <li className="menu-item current">
              <Svg name="BackArrow" class="menu-icon"/>
              <span className="menu-text">
                <p>Widzew Łódź</p>
              </span>
              <div className="childs-icon"></div>
            </li>
          </ul>
        </nav>
      );
    case true:
      return (
        <RenderNavs/>
      );
    default:
      break;
  }
};
export default NavSide;