import React, { useEffect, useState } from 'react'
import Axios from 'axios';

import Slider from "react-slick";

import ShopBoxItem from './ShopBoxItem';

const ShopBox = (params) => {
  const [shopData, setShopData] = useState('');
  const [imageLink, setImageLink] = useState('');

  const url = `/api/shop/${params.block['en']['Type']}.json`;

  function generateEnvHostnameCMS() {
    let hostname = ''
    switch (window.location.origin) {
      case 'https://dev-cms-widzew.qrqs.eu':
        hostname = 'https://dev-widzew.qrqs.eu'
          break;
      case 'https://test-cms-widzew.qrqs.eu':
        hostname = 'https://test-widzew.qrqs.eu'
          break;
      case 'https://cms-widzew.qrqs.eu':
        hostname = 'https://widzew.com'
          break;
      default:
          break;
    }
    return hostname
  }

  useEffect(() => {
    let fetchShopData = async () => {
      console.log(params.line)
      let link;
      let linkImg;
      if (params.line === 'offline') {
        link = generateEnvHostnameCMS() + url;
        linkImg = generateEnvHostnameCMS()
      } else if (window.origin.includes('localhost')) {
        link = 'https://dev-widzew.qrqs.eu' + url;
        linkImg = 'https://dev-widzew.qrqs.eu'
      } else {
        link = window.origin + url;
        linkImg = window.origin
      }

      let response = "";
      try {
        response = await Axios(link);
        if (response.data) {
          setShopData(response.data);
        }
        setImageLink(linkImg);
      } catch (err) {
        response = "error";
      }
    };
    fetchShopData();
  }, [])

  // useEffect(() => {
  //   getProducts();
  // }, [])

  const nextArrow = `
  <svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 128 128" x="0px" y="0px"xmlns="http://www.w3.org/2000/svg"><path id="Right_Arrow_4_" d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>
`;
  const prevArrow = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7
 c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"
 />
</svg>
  `;

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style, display: "block", width: '30px', height: '30px' }}
        dangerouslySetInnerHTML={
          { __html: nextArrow }
        }
      >
      </div>
    );
  }

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style, display: "block", width: '30px', height: '30px' }}
        dangerouslySetInnerHTML={
          { __html: prevArrow }
        }
      >
      </div>
    );
  }


  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };

  let colorBack = ''
  if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
    colorBack = 'b-' + params.block['en']['Background']
  }
  let innerSection = 'shop-box';
  let innerContainer = 'container';
  if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
    if (params.block['en']['InnerBackground'] === true) {
      innerContainer = innerContainer + ' ' + colorBack;
    }
    else {
      innerSection = innerSection + ' ' + colorBack;
    }
  }

  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          {params.block['en']['Title'] &&
            <h1 className="mb-30">
              {params.block['en']['Title']}
            </h1>}
          <div className="shop-box-carousel-wrapper">
            <Slider {...settings}>
              {shopData && shopData.map((item, index) => (
                <ShopBoxItem key={index} item={item} url={imageLink} />
              ))}
            </Slider>
          </div>
          <div className="d-flex jc-center mt-40">
            <a href="https://sklep.widzew.com/home" target="_blank" className="button b-gold">Przejdź do sklepu</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShopBox