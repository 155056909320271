import React from 'react'
import WidzewFmScheduleGroup from './WidzewFmScheduleGroup'

const WidzewFmSchedule = (params) => {
  if (window.origin.includes('localhost') || window.origin.includes('dev-widzew') || window.origin.includes('cms-widzew')) {
    console.log('WidzewFmSchedule')
    console.log(params)
  }
  let colorBack = ''
  if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
    colorBack = 'b-' + params.block['en']['Background']
  }
  let innerSection = 'widzew-fm-schedule';
  let innerContainer = 'container';
  if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
    if (params.block['en']['InnerBackground'] === true) {
      innerContainer = innerContainer + ' ' + colorBack;
    }
    else {
      innerSection = innerSection + ' ' + colorBack;
    }
  }

  let today = new Date();
  let tomorrow = new Date(today);
  today = new Intl.DateTimeFormat('pl-PL').format(today)
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow = new Intl.DateTimeFormat('pl-PL').format(tomorrow)


  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          {params.block['en'].Title &&
            <h1 className="mb-20">{params.block['en'].Title}</h1>
          }
          {params.block['en'].DayGroup && params.block['en'].DayGroup.map((i, index) => {
            let todayFromJson = i.Date ? new Date(i.Date) : new Date();
            const todayFormatted = new Intl.DateTimeFormat('pl-PL').format(todayFromJson);
            if (today === todayFormatted || tomorrow === todayFormatted) {
              return (
                <WidzewFmScheduleGroup key={index} item={i} />
              )
            }
            return null
          })
          }
        </div>
      </div>
    </section>
  )
}

export default WidzewFmSchedule