import TopBannerTitleItem from "./TopBannerTitleItem";
import React from "react";
import ShareBox from "../../components/ShareBox";
import Image from "../../components/Image";
import Loaders from "../../Loaders.js";
import useWindowDimensions from "../../Device.js";

function TopBannerTitle(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
        console.log("TopBannerTitle")
        console.log(params)
    }
    const { device, width } = useWindowDimensions();
    let title = ''
    if (params.block['en']['Title'] !== '' && params.block['en'].hasOwnProperty('Title') && 
        params.block['en']['ShowTitle'] === true && params.block['en'].hasOwnProperty('ShowTitle')) {
        title = (
            <h1>{params.block['en']['Title']}</h1>
        )
    }   
    let id = params.block.path.split("/").pop();
        id = id.substring(0, id.length - 4);

    function Items () {
        if (params.block['en']['Item'] && params.block['en']['Item']) {
            return (Object.keys(params.block['en']['Item']).map((item, i) => (
                params.block['en']['Item'][item].hasOwnProperty('Link') &&
                    <TopBannerTitleItem
                        name={params.block['en']['Item'][item]['LinkName']}
                        link={params.block['en']['Item'][item]['Link'].path}
                        active={params.block['en']['Item'][item]['Active']}
                        key={'tbtItem'+i}
                        line={params.line} 
                    />
            )))
        }
    }

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-'+params.block['en']['Background']
    }
    let innerSection = 'top-banner-title';
    let innerWideContainer = 'container-wide title-box';
    let innerContainer = 'container d-flex ai-end pb-0';
    if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
            innerWideContainer = innerWideContainer + ' ' + colorBack;
        }
    }
    if (params.block['en']['HoverUnder'] !== '' && params.block['en'].hasOwnProperty('HoverUnder') && params.block['en']['HoverUnder'] === true) {
        innerSection = innerSection + ' hover-under';
    }

    let wideContainer = 'container-wide d-flex f-column jc-center back-box';

    let backImage = "";
    let image = (params.block['en']['Image'] && params.block['en']['Image'].path);
    if (device === 'mobile') {
        if (params.block['en']['MobImage'] && params.block['en']['MobImage'].hasOwnProperty('path')) {
            if (params.block['en']['MobImage'].path !== '') image = (params.block['en']['MobImage'] && params.block['en']['MobImage'].path);
        }
    }
    if (image !== '' && image) {
        if (window.location.origin.includes("http://localhost") /* || isDevEnvironment*/ ) {
            image = 'https://dev-widzew.qrqs.eu'+image
            /*image = environmentUrl+image*/
        }
        backImage = 'url('+image.replace('/sites/widzew', '')+'_width='+width+')';
    }
    let itemsClass = 'links-item d-flex';
    if (params.block['en']['BigLinks'] !== '' && params.block['en'].hasOwnProperty('InnerBackground') && params.block['en']['BigLinks'] === true) {
        itemsClass = itemsClass + ' big-a';
    }
    if (title === '') {
        itemsClass = itemsClass + ' jc-around w-100'
    }
    function ImageBox() {
        if (device === 'mobile') {
            return (
                <div className={wideContainer + ` mob-image`}>
                    <div className="image-box">
                        {image !== '' 
                            ?   <>
                                    <Loaders type="circle" color="b-red" mode="true"/>
                                    <Image src={image} width={width} alt={params.block['en']['Title']}/>
                                </>
                            :   <Image src='/.templates/img/logo/herbwidzewa.jpg' class="no-img-logo" width="400" alt="Widzew Łódź" />
                        }
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className={wideContainer} style={{backgroundImage: backImage}}></div>
            )
        }
    }
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <ImageBox />
                <div className={innerWideContainer}>
                    <div className={innerContainer}>
                        {(params.block['en']['ShowShare'] === true && params.block['en'].hasOwnProperty('ShowShare')) &&
                            <ShareBox id={id} link={window.location.href} mode="page" type="stable" />
                        }
                        {title}
                        <div className={itemsClass}>
                            <Items/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TopBannerTitle;