

import Image from "../../components/Image.js";
import '../../.templates/css/portlets/partnersClub/PartnersClub.css';
import React from "react";

function PartnersClubItem(params) {
    return (
        <>
            <h1 className="p-15">{params.title}</h1>
            <div className="partner-item d-flex mb-80">
                <div className="partner-img">
                    {params.image &&
                        <Image src={params.image} altText={params.altText} />
                    }
                </div>
                <div className="partner-informations">
                    <div className="partner-text mb-45 mt-40 small-h">
                        {params.Text &&
                            <h3
                                dangerouslySetInnerHTML={
                                    { __html: params.Text }
                                }
                            >
                            </h3>
                        }
                    </div>
                    <a href={params.Link}>
                        {params.LinkName}
                    </a>
                </div>
            </div>
        </>
    );
}

export default PartnersClubItem;

