import React from "react";
import { useState, useEffect } from "react";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ModalForm from "../modalBox/Modal.js";
let siteKey = "";
let hostname = window.location.hostname;
let dataToSend = {};
if (hostname == "dev-widzew.qrqs.eu" || hostname === "localhost" || hostname === "127.0.0.1") {
    siteKey = '6LfMiRQgAAAAACft3ooQNtAkziRMPgOLuY0qSkCK';
}
else if (hostname == "test-widzew.qrqs.eu") {
    siteKey = '6LdDjBQgAAAAAGcbrt4z1XImLP07t6JgxPGmNcVd';
}
else if (hostname == "widzew.com") {
    siteKey = '6Lch2E0gAAAAANGDpyTeLLij8iYzrr3AFvZFIvKe'
}

function FormBox(params) {
    const   [name, setName] = useState(null),
            [email, setEmail] = useState(null),
            [valueNumber, setValueNumber] = useState(null),
            [show, setShow] = useState(false),
            [yellow, setYellow] = useState(false),
            [yellowName, setYellowName] = useState(false),
            [yellowMail, setYellowMail] = useState(false),
            [yellowPreMail, setYellowPreMail] = useState(true),
            [yellowErrorMail, setYellowErrorMail] = useState(false),
            [agreeRodo, setAgreeRodo] = useState(false),
            [agreeNewsletter, setAgreeNewsletter] = useState(false),
            [agreeTele, setAgreeTele] = useState(false),
            [yellowStatus, setYellowStatus] = useState(false),
            [isFormError, setIsFormError] = useState(false),
            [isFormSuccess, setIsFormSuccess] = useState(false),
            [IsAlreadyExist, setIsAlreadyExist] = useState(false);

    useEffect(() => {
        ReactRecaptcha3.init(siteKey).then(
            (status) => {

            }
        );
    }, [])
    const [formData, setFormData] = useState({
        // email: '',
        // number: '',
        // name: '',
    });
    const validateName = imie => {
        setName(imie);
    }

    const validateEmail = email => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            setEmail(email);
            setYellowPreMail(true);
        }
        else {
            setYellowPreMail(false);
        }
    }

    const statusHandlerRodo = () => {
        setAgreeRodo(!agreeRodo)
    }
    const statusHandlerNewsletter = () => {
        setAgreeNewsletter(!agreeNewsletter)
    }
    const statusHandlerTele = () => {
        setAgreeTele(!agreeTele)
    }
    const YellowHandler = () => {
        if (!email) {
            if (!name) {
                setYellow(true);
            }
            else {
                setYellowMail(true);
            }
        }
        else {
            if (yellowPreMail) {
                if (!name) {
                    setYellowName(true);
                }
                else {
                    if (!agreeRodo || !agreeNewsletter || !agreeTele) {
                        setYellowStatus(true);
                    }
                }
            }
            else {
                setYellowErrorMail(true);

            }
        }

        const timer = setTimeout(() => {
            setYellow(false);
            setYellowMail(false);
            setYellowName(false);
            setYellowErrorMail(false);
            setYellowStatus(false);
        }, 3000);
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        let allData = false
        if (email) {
            if (name) {
                if (agreeRodo && agreeNewsletter && agreeTele) {
                    allData = true
                    dataToSend = {
                        ...formData,
                        email: email,
                        phone: valueNumber,
                        name: name,
                        agreeRodo: agreeRodo,
                        agreeNewsletter: agreeNewsletter,
                        agreeTele: agreeTele
                    }
                }
            }
        }
        if (allData) {
            ReactRecaptcha3.getToken().then(
                (token) => {
                    dataToSend = {
                        ...dataToSend,
                        'g-recaptcha-response': token
                    }

                    let url;
                    let hostname = window.location.hostname;
                    if (hostname === "localhost") {
                        url = 'https://dev-widzew.qrqs.eu/api/jsonws/newsletter/add';
                    } else {
                        url = '/api/jsonws/newsletter/add'
                    }
                    axios.post(url, dataToSend)
                        .then((response) => {
                            const success = response.status === 200;
                            const alreadyExist = response.data.message === 'alreadyExists';
                            setIsAlreadyExist(false)
                            setIsFormSuccess(false);
                            setIsFormError(false);
                            if (success) {
                                if (alreadyExist) {
                                    setIsAlreadyExist(true)
                                }
                                else {
                                    setIsFormSuccess(true);
                                }
                                cleanForm()
                            } 
                            else {
                                setIsFormError(true);
                            }
                            setShow(true);
                            const timer = setTimeout(() => {
                                setIsFormError(false);
                                setIsFormSuccess(false);
                            }, 5000);
                            return () => clearTimeout(timer);
                        }).catch(error => {
                            console.error('Error: ', error);
                            setShow(true);   
                            setIsFormError(true)
                            cleanForm()
                        });
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }
    function cleanForm() {
        setEmail('')
        setName('')
        setAgreeRodo(false)
        setAgreeNewsletter(false)
        setAgreeTele(false)
        setValueNumber('')
    }
    function closeNewslForm() {
        let formid = document.getElementById('closeNewsl')
        if (formid) {
            setTimeout(function(){
                //formid.click()
            }, 0)
        }
    }
    return (
        <>
            <section className="form-box" id="newsl-form">
                <div className="wrapper">
                    <div className="container">
                        <div className="box-of-form small-p">
                            <div>
                                <h1 className="mb-20">{params.Title}</h1>
                            </div>
                            <div className="mb-20 ml-30 mr-30 desc-box text">
                                <p><b>Dołącz i bądź na bieżąco z informacjami o Twoim ulubionym klubie!</b></p>
                                <br/>
                                <p>Zasady newslettera:</p>
                                <p>- newslettery wysyłamy nie częściej niż 2 razy w miesiącu</p>
                                <p>- otrzymujesz informacje o działalności Klubu oraz informacje o planowanych imprezach 
                                i wydarzeniach organizowanych przez Klub </p>
                                <p>- jesteś na bieżąco z ważnymi komunikatami i ogłoszeniami dla kibiców Klubu</p>
                                <p>- Twój adres email jest bezpieczny – dane oprócz Nas otrzymują tylko zaufani partnerzy Klubu: </p>
                                <br/>
                                <p>Zapoznaj się z <a href="/polityka-prywatnosci/"> Polityką prywatności </a> WIDZEW ŁÓDŹ S.A</p>
                            </div>
                        </div>
                        <div>
                            <form onSubmit={submitHandler}>
                                <div className="d-flex f-row jc-evenly mb-20 input-box">
                                    <fieldset>
                                        <input
                                            id="email"
                                            className="button b-white newsl"
                                            type="text"
                                            placeholder="Adres email*"
                                            name="email"
                                            onChange={e => validateEmail(e.target.value)}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <input
                                            onChange={e => { validateName(e.target.value); }}
                                            className="button b-white newsl"
                                            placeholder="Imię*"
                                            value={name}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <PhoneInput
                                            defaultCountry="PL"
                                            value={valueNumber}
                                            placeholder="Numer telefonu"
                                            onChange={setValueNumber}
                                            className="button b-white newsl d-flex"
                                        />
                                    </fieldset>
                                </div>
                                <fieldset className="mb-30">
                                    <div className="d-flex f-row form-button">
                                        <div>
                                            <small>* Zgoda obligatoryjna, niezbędna w przypadku chęci otrzymywania newslettera. Możliwa do wycofania w każdym czasie. </small>
                                            <small> Przed zapisem do Newslettera prosimy o zapoznanie się z <a href="/dokumenty/WIDZEW_Klauzula-newsletter.pdf" target="_blank">klauzulą informacyjną</a>.</small>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div className="d-flex f-row form-button">
                                        <div>
                                            <div>
                                                <label>
                                                    <input className="approval-checkbox" name="agreeRodo" type="checkbox" onChange={statusHandlerRodo} />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <small>
                                                Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z art. 6 ust. 1 lit. a) ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r (Dz. Urz. UE L 119 z 04.05.2016), dla celów związanych z zapisem do newslettera. *
                                            </small>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div className="d-flex f-row form-button">
                                        <div>
                                            <div>
                                                <label>
                                                    <input className="approval-checkbox" name="agreeNewsletter" type="checkbox" onChange={statusHandlerNewsletter} />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <small>
                                                Wyrażam zgodę na przesyłanie informacji handlowych przez WIDZEW ŁÓDŹ S.A. oraz zaufanych partnerów WIDZEW ŁÓDŹ S.A. w celu wysłania do mnie drogą elektroniczną (e-mail/sms) newslettera. *
                                            </small>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div className="d-flex f-row form-button">
                                        <div>
                                            <div>
                                                <label>
                                                    <input className="approval-checkbox" name="agreeTele" type="checkbox" onChange={statusHandlerTele} />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <small>
                                                Wyrażam zgodę na używanie telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem (telefonu, komputera) przez WIDZEW ŁÓDŹ S.A. oraz zaufanych partnerów WIDZEW ŁÓDŹ S.A. dla celów marketingu bezpośredniego. *
                                            </small>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="down-panel mt-20">
                                    <button className='form-field button b-gold mb-20' type="submit" onClick={YellowHandler}>
                                        Zapisz się
                                    </button>
                                    <div className="mb-20">    
                                        {isFormSuccess && <ModalForm onClose={() => setShow(false)} show={show} children={
                                            "Dziękujemy za zapisanie się na widzewski newsletter. Za chwilę otrzymasz wiadomość e-mail z linkiem aktywacyjnym. Użyj tego linku aby potwierdzić chęć otrzymywania wiadomości."
                                        } title="DZIĘKUJEMY" />}

                                        {isFormError && <ModalForm onClose={() => setShow(false)} show={show} children={`
                                        Przepraszamy. Zapisanie na widzewski newsletter nie było teraz możliwe.
                                        Spróbuj ponownie później lub skontaktuj się z nami aby uzyskać pomoc.

                                        `} title="NIEOCZEKIWANY BŁĄD" />}

                                        {IsAlreadyExist && <ModalForm onClose={() => setShow(false)} show={show} children={`
                                        Twój adres e-mail znajduje się już w naszej bazie.
                                        Sprawdź swoją skrzynkę e-mail w poszukiwaniu wiadomości o Widzewie.

                                        `} title="E-MAIL ISTNIEJE" />}
                                        { (isFormSuccess || isFormError || IsAlreadyExist) && closeNewslForm()}          

                                        {yellow && <span className="form-confirmation success">Uzupełnij imię i adres email</span>}
                                        {yellowName && <span className="form-confirmation success">Uzupełnij imię</span>}
                                        {yellowMail && <span className="form-confirmation success">Uzupełnij email</span>}
                                        {yellowErrorMail && <span className="form-confirmation success">Email nieprawidłowy</span>}
                                        {yellowStatus && <span className="form-confirmation success">Zaakceptuj zgody</span>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
export default FormBox;