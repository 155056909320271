import React from 'react'

const WidzewFmArchivesItem = (params) => {
  if (window.origin.includes('localhost')) {
    console.log(params)
  }

  let url;
  if (params.line === 'offline') {
    url = params.item['mp3'] ? `${window.origin}/audio/${params.item['mp3']}` : '';
  } else if (window.origin.includes('localhost')) {
    url = params.item['mp3'] ? `https://dev-widzew.qrqs.eu/audio/${params.item['mp3']}` : '';
  } else {
    url = params.item['mp3'] ? `${window.origin}/audio/${params.item['mp3']}` : '';
  }


  return (
    <div className="widzew-fm-archives-item p-20">
      <div className="archives-info small-h">
        {params.item.title &&
          <h2>{params.item.title}</h2>
        }
        {params.item.description &&
          <div className="archives-description mb-10"><p>{params.item.description}</p></div>
        }
        {params.item.author &&
          <div className="archives-authors"><p>{params.item.author}</p></div>
        }
      </div>
      <div className="archives-player">
        {params.item['mp3'] &&
          <audio controls>
            <source src={url} type="audio/mpeg" />
            Twoja przeglądarka nie wspiera odtwarzacza audio.
          </audio>
        }
      </div>
    </div>
  )
}

export default WidzewFmArchivesItem