import React, { useState } from "react";
import Links from "../../components/Links";
import Image from "../../components/Image";

import MembersCarouselItem from './MembersCarouselItem';

function MembersCarousel(params) {
    if (window.origin.includes('localhost')) {
        console.log('MembersCarousel')
        console.log(params)
    }

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }

    let innerSection = 'members-carousel';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className="container d-flex f-column">
                    <h1>{params.block['en']['Title']}</h1>
                    <div className="main-carousel mt-40">
                        <MembersCarouselItem params={params}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MembersCarousel;