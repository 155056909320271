import React, { useState, useRef } from "react";
import Slider from "react-slick";
import Links from "../../components/Links";
import TopBannerCarouselItem from "./TopBannerCarouselItem";
import TopBannerCarouselItemItem from "./TopBannerCarouselItemItem";

function TopBannerCarousel(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew') || window.origin.includes('cms-widzew')) {
        console.log('TopBannerCarousel')
        console.log(params)
    }
    let newTabPar1 = true, newTabPar2 = true;

    params.block['en']['InNewOpen1'] ? newTabPar1 = "true" : newTabPar1 = "false";
    params.block['en']['InNewOpen2'] ? newTabPar2 = "true" : newTabPar2 = "false";

    const nextArrow = `<svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 128 128" x="0px" y="0px"xmlns="http://www.w3.org/2000/svg"><path fill="white" id="Right_Arrow_4_" d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>`;
    const prevArrow = `
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <path fill="white" id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7
            c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"
            />
        </svg>
    `;
    const   [currentSilde, setCurrentSilde] = useState(0),
            autoTime = (params.block['en']['RotationTime']) ? parseFloat(params.block['en']['RotationTime']) : 3000,
            speedSliderTime = 1000,
            sliderRef = useRef(),
            progresRef = useRef();

    const changeSlideTo = index => {
        progresRef.current.style.animation = 'none';
        sliderRef.current.slickGoTo(index);
    };

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, display: "block", width: '30px', height: '30px' }}
                dangerouslySetInnerHTML={
                    { __html: nextArrow }
                }
            >
            </div>
        );
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, display: "block", width: '30px', height: '30px' }}
                dangerouslySetInnerHTML={
                    { __html: prevArrow }
                }
            >
            </div>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: speedSliderTime,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: autoTime,
        pauseOnHover: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: current => {
            setCurrentSilde(current);
            progresRef.current.style.animation = "none";
            progresRef.current.style.animation = "progres " + (autoTime - speedSliderTime) / 1000 + "s infinite linear";
            return progresRef.current.offsetHeight;
        },
        beforeChange: () => {
            progresRef.current.style.animation = 'none';
        }
    };

    let colorBack = ''
    if (params.block['en']['FontColor'] !== '' && params.block['en'].hasOwnProperty('FontColor')) {
        colorBack = 'b-' + params.block['en']['FontColor']
    }
    let innerSection = 'top-carousel';
    let innerContainer = 'container-wide';
    if (params.block['en']['InnerBackground'] !== '') {
        innerSection = innerSection + ' ' + colorBack;
    }
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <Slider {...settings} ref={sliderRef}>
                        {params.block['en'].hasOwnProperty('Slide') && Object.keys(params.block['en']['Slide']).map((slider, i) => (
                            <TopBannerCarouselItem
                                key={'TopBannerCarouselItem-' + i}
                                block={params.block['en']['Slide'][slider]}
                                line={params.line}
                            />
                        ))}
                    </Slider>
                    <div className="container">
                        <div ref={progresRef} className="progress" role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ backgroundSize: "0% 100%", animationDuration: (autoTime + 300) / 1000 + "s" }}>
                            <span className="slider__label sr-only"></span>
                        </div>
                    </div>
                    <div className="container banner-bottom d-flex jc-center">
                        {Object.keys(params.block['en']['Slide']).map((slider, i) => (
                            <TopBannerCarouselItemItem
                                key={'TopBannerCarouselItemItem' + i}
                                SlideLabel={params.block['en']['Slide'][slider]['SlideLabel']}
                                SlideTitle={params.block['en']['Slide'][slider]['SlideTitle']}
                                SlideNumber={slider}
                                SlideActive={currentSilde}
                                ChangeActive={setCurrentSilde}
                                ChangeSlide={changeSlideTo}
                            />
                        ))}
                        <div className="bottom-button d-flex f-column">
                            {((params.block['en'].hasOwnProperty('LinkFirst')) && (params.block['en'].hasOwnProperty('LinkSecond'))) && (
                                <>
                                    <Links line={params.line} url={params.block['en']['LinkFirst']} class={"button " + params.block['en']['ButtonsType']} target={newTabPar1} name={params.block['en']['LinkNameFirst']} />
                                    <Links line={params.line} url={params.block['en']['LinkSecond']} class={"button " + params.block['en']['ButtonsType2']} target={newTabPar2} name={params.block['en']['LinkNameSecond']} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TopBannerCarousel;


