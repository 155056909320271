import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import TimetableDetailsBanner from './TimetableDetailsBanner'
import TimetableDetailsBody from './TimetableDetailsBody'

const TimetableDetails = (params) => {
  const [matchInProgressScore, setMatchInProgressScore] = useState();
  const [activeSeason, setActiveSeason] = useState(new Date(params['block']['en'].DateOfGame).getFullYear())
  const [isVideoOpened, setIsVideoOpened] = useState(false)

  const [changeTab, setChangeTab] = useState('relation')


  let intervalActive = true;

  if (window.origin.includes('localhost')) {
    console.log('Timetable details')
    console.log(params)
  }

  useEffect(() => {
    let
        link,
        match = null,
        item = null,
        letStart = null;


    let fetchCurrentActiveSeason = async () => {
      let link2
      let response = "";
      if (params.line === 'offline') {
        link2 = window.origin + '/api/matches/current.json?' + Date.now();
      } else if (window.origin.includes('localhost')) {
        link2 = 'https://test-widzew.qrqs.eu/api/matches/current.json?' + Date.now();
      } else {
        link2 = window.origin + '/api/matches/current.json?' + Date.now();
      }

      try {
        response = await Axios(link2);
        if (response.hasOwnProperty('data')) {
          // year = response.data[0]
          setActiveSeason(response.data[0])
        }
        
      } catch (err) {
        response = "error";
      }
    }

    fetchCurrentActiveSeason()

    let fetchAllMatchesData = async () => {
      let response = "";
      if (params.line === 'offline') {
        link = window.origin + '/api/matches/' + activeSeason + '.json?' + Date.now();
      } else if (window.origin.includes('localhost')) {
        link = 'https://test-widzew.qrqs.eu/api/matches/' + activeSeason + '.json?' + Date.now();
      } else {
        link = window.origin + '/api/matches/' + activeSeason + '.json?' + Date.now();
      }

      try {
        response = await Axios(link);
        if (response.hasOwnProperty('data')) {
          item = response.data.find(e => e.timestamp === params.block['en'].DateOfGame)
        }
        match = item;

        if (item && match !== null) {
          letStart = params.block['en'].DateOfGame - (59 * 60000);
          if (match !== null && Date.now() > letStart) {
            fetchMatchesData();
          }
        }
        
      } catch (err) {
        response = "error";
      }
    }
    fetchAllMatchesData();

    let responseMatch = "";
    let fetchMatchesData = async () => {
      if (params.line === 'offline') {
        link = window.origin + '/api/matches/' + activeSeason + '/' + match.id + '.json?' + Date.now();
      } else if (window.origin.includes('localhost')) {
        link = 'https://test-widzew.qrqs.eu/api/matches/' + activeSeason + '/' + match.id + '.json?' + Date.now();
      } else {
        link = window.origin + '/api/matches/' + activeSeason + '/' + match.id + '.json?' + Date.now();
      }

      try {
        responseMatch = await Axios(link);
      } catch (err) {
        responseMatch = "error";
        console.log('fetchMatchesData - '+err)
      }
      
      if (responseMatch.data && Date.now() > letStart) {
        setMatchInProgressScore(responseMatch.data);
        if (intervalActive === true && responseMatch.data && responseMatch.data[0].fixture.status.short !== "FT" && responseMatch.data[0].fixture.status.short !== "AET" && responseMatch.data[0].fixture.status.short !== "PEN") {
          intervalActive = false;

          setInterval(() => {
            fetchMatchesData();
          }, 60000);
        }
      }
    }
  }, [activeSeason])

  function youtube_parser(url){
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const id = (match&&match[7].length==11)? match[7] : false

    const embedUrl = `https://www.youtube.com/embed/${id}`
    return embedUrl;
}

  function FloatingIframe() {
    const url = params.block.en.CustomButtonLink?.link
    return (
      <div className="relation-floating-video">
        <iframe src={`${youtube_parser(url)}?autoplay=1`}  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe>
        <span className="relation-floating-video__close" onClick={() => setIsVideoOpened(false)}><span /> <span /></span>
      </div>
    )
  }

  return (
    <>
      <TimetableDetailsBanner params={params} matchInProgressScore={matchInProgressScore} />
      <TimetableDetailsBody params={params} matchInProgressScore={matchInProgressScore} setIsVideoOpened={setIsVideoOpened} isVideoOpened={isVideoOpened} setChangeTab={setChangeTab} changeTab={changeTab} />
      {isVideoOpened && changeTab == 'relation' &&
        <div className="relation-floating-video-wrapper">
          <FloatingIframe />
        </div> 
      }
    </>
  )
}

export default TimetableDetails