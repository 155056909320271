import React, { useState, useEffect } from 'react'
import TimetableTeaser from '../../portlets/timetable/TimetableTeaser';
import TimetableCarousel from './TimetableCarousel';
const TeaserComponents = {
  "timetable-details": TimetableTeaser,
};

let matchesByYearTerm = {}
let matchesByYearScore = {}

let monthToNames = {
  '0': 'styczeń',
  '1': 'luty',
  '2': 'marzec',
  '3': 'kwiecień',
  '4': 'maj',
  '5': 'czerwiec',
  '6': 'lipiec',
  '7': 'sierpień',
  '8': 'wrzesień',
  '9': 'październik',
  '10': 'listopad',
  '11': 'grudzień'
}

const TimetableList = (params) => {
  const [showTimetable, setShowTimetable] = useState(true);
  const [showScore, setShowScore] = useState(false);
  const [justCarousel, setJustCarousel] = useState(false)
  if (window.origin.includes('localhost')) {
    console.log('Timetable List')
    console.log(params)
  }

  useEffect(() => {
    if (window.location.href.includes('terminarz')) {
      setJustCarousel(true);
    }

    if (window.location.pathname === '/' || window.location.pathname === '/index.html') {
      let sectionList = document.getElementById('timetable-list');
      if (sectionList) {
        sectionList.classList.add('timetable-hp');
      }
    }
  }, [])

  function showTimetableHandler() {
    setShowScore(false);
    setShowTimetable(true);
  }
  function showScoreHandler() {
    setShowScore(true);
    setShowTimetable(false);
  }

  function getMatches() {
    matchesByYearTerm = {}
    matchesByYearScore = {}
    params.block.list.map((teaser, i) => {
      if (teaser['en'].IsFinished === true) {
        let matchDateYear = new Date(teaser['en'].DateOfGame).getFullYear();
        let matchDate = new Date(teaser['en'].DateOfGame).getMonth();
        
        if (matchDateYear in matchesByYearScore) {
          if (matchDate in matchesByYearScore[matchDateYear]) {
            matchesByYearScore[matchDateYear][matchDate].push(teaser)
          } else {
            matchesByYearScore[matchDateYear][matchDate] = []
            matchesByYearScore[matchDateYear][matchDate].push(teaser);
          }

        } else {
          matchesByYearScore[matchDateYear] = {}
          matchesByYearScore[matchDateYear][matchDate] = []
          matchesByYearScore[matchDateYear][matchDate].push(teaser);
        }
      } else {
        let matchDateYear = new Date(teaser['en'].DateOfGame).getFullYear();
        let matchDate = new Date(teaser['en'].DateOfGame).getMonth();

        if (matchDateYear in matchesByYearTerm) {
          if (matchDate in matchesByYearTerm[matchDateYear]) {
            matchesByYearTerm[matchDateYear][matchDate].push(teaser)
          } else {
            matchesByYearTerm[matchDateYear][matchDate] = []
            matchesByYearTerm[matchDateYear][matchDate].push(teaser);
          }

        } else {
          matchesByYearTerm[matchDateYear] = {}
          matchesByYearTerm[matchDateYear][matchDate] = []
          matchesByYearTerm[matchDateYear][matchDate].push(teaser);
        }
      }
      return null;
    })
  }

  getMatches()

  function getMonthByName(x) {
    return monthToNames[x];
  }

  let classListTimetable = showTimetable ? 'link-style ml-40 bigger active' : 'link-style ml-40 bigger'
  let classListScore = showScore ? 'link-style bigger active' : 'link-style bigger'

  return (
    <>
      <TimetableCarousel item={params.block.list} params={params} />
      {justCarousel &&
        <div className="list-box list-dynamic list-paginate">
          <div className="list-buttons d-flex ai-baseline">
            <h1>Terminarz</h1>
            <div className="match-buttons">
              <button className={classListTimetable} onClick={showTimetableHandler}>Mecze przyszłe</button>
              <button className={classListScore} onClick={showScoreHandler}>Mecze rozegrane</button>
            </div>
          </div>
          {showTimetable === true &&
            <ul className="list-entries d-flex f-column ">
              {/* each teaser */}
              {Object.keys(matchesByYearTerm).map((teaser, i) => {
                let yearGroup = Object.keys(matchesByYearTerm[teaser]).map((teas, index) => {
                  let dateGroup = matchesByYearTerm[teaser][teas].map((tt, i) => {
                    let type = tt.attributes.type;
                    if (TeaserComponents.hasOwnProperty(type)) {
                      let key = tt.path.split("/").pop();
                      key = key.substring(0, key.length - 4);
                      return (
                        <li className="list-entry" key={key + '_' + i}>
                          {/*portlet place*/}
                          {React.createElement(TeaserComponents[type], {
                            key: key + '_' + i,
                            block: tt,
                            line: params.line,
                          })}
                        </li>
                      )
                    }
                  })

                  return (
                    <div className="timetable-month-group mt-40" key={i}>
                      <h2>{getMonthByName(teas)} {teaser}</h2>
                      {dateGroup}
                    </div>
                  );
                })
                return yearGroup

              })}
            </ul>
          }


          {showScore === true &&
            <ul className="list-entries d-flex f-column ">
              {/* each teaser */}
              {Object.keys(matchesByYearScore).reverse().map((teaser, i) => {
                let yearGroup = Object.keys(matchesByYearScore[teaser]).reverse().map((teas, index) => {
                  let dateGroup = matchesByYearScore[teaser][teas].reverse().map((tt, i) => {
                    let type = tt.attributes.type;
                    if (TeaserComponents.hasOwnProperty(type)) {
                      let key = tt.path.split("/").pop();
                      key = key.substring(0, key.length - 4);
                      return (
                        <li className="list-entry" key={key + '_' + i}>
                          {/*portlet place*/}
                          {React.createElement(TeaserComponents[type], {
                            key: key + '_' + i,
                            block: tt,
                            line: params.line,
                          })}
                        </li>
                      )
                    }
                  })
                  return (
                    <div className="timetable-month-group mt-40" key={i}>
                      <h2>{getMonthByName(teas)} {teaser}</h2>
                      {dateGroup}
                    </div>
                  );
                })
                return yearGroup;

              })}
            </ul>
          }
          <div className="list-pagination "></div>
        </div>
      }
    </>
  );
}

export default TimetableList