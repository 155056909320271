import React from 'react'

const GamePlayTableItem = (params) => {
  const isWidzew = (params.item.team.name.toLowerCase().includes('widzew') && params.item.team.name.toLowerCase().includes('łódź')) ? 'active' : ' ';
  let logoUrl = params.item.team.guid ? `${params.imgUrl}${params.item.team.guid}.png` : ``;

  return (
    <tr className={isWidzew}>
      <td className="w-9">
        <div>{params.item.rank}</div>
      </td>
      <td className="gameplay-table-bold w-41">
        <div className="gameplay-table-name-logo">
          {
            logoUrl &&
            <img src={logoUrl} alt={params.item.team.name} />
          }
          {params.item.team.name}
        </div>
      </td>
      <td className="w-6-half">{params.item.all.played}</td>
      <td className="w-4-half mob-h">{params.item.all.win}</td>
      <td className="w-4-half mob-h">{params.item.all.draw}</td>
      <td className="w-5 mob-h">{params.item.all.lose}</td>
      <td className="w-6-half mob-h">{params.item.all.goals.for}</td>
      <td className="w-6-half mob-h">{params.item.all.goals.against}</td>
      <td className="w-6-half">{params.item.goalsDiff}</td>
      <td className="gameplay-table-bold w-10">{params.item.points}</td>
    </tr>
  )
}

export default GamePlayTableItem