import React, { useState, useEffect } from "react";
import Image from "../../../components/Image";
import { format, parseISO } from "date-fns";
import svgIcon from "../../../components/SvgRelation";
import { TwitterTweetEmbed } from "react-twitter-embed";

const TimetableRelation = (params) => {

  function generateMinOrTime(entryType, time, date) {
    if (entryType === "duringMatch") {
      return time + "'";
    } else {
      return format(parseISO(date), "HH:mm");
    }
  }

  function generateVideoUrl(url) {
    const ytParam = new URL(url).searchParams.get("v");
    const embedUrl = `https://www.youtube.com/embed/${ytParam}`;
    return embedUrl;
  }

  function generateTweetUrl(url) {
    const tweetPathname = new URL(url); 
    let tweetId = tweetPathname.pathname.split('/')[3]
    const embedUrl = `https://twitter.com${tweetPathname}`;
    return tweetId;
  }

  const hostnameImage = window.origin.includes("localhost")
    ? `https://dev-widzew.qrqs.eu/images/relations/${params.title}/`
    : `/images/relations/${params.title}/`;



  function Element({item}) {
    return (
      <div
        className={`relation-item d-flex mb-15 ${!item.textContent ? "no-top" : ""}`}
      >
        <div className="d-flex relation-top">
          {item.eventType && svgIcon(item.eventType)}
          <div
            className={`event ${item.entryType === "duringMatch" ? "during" : ""} ${
              item.eventType === "goal" ? "goal" : ""
            } d-flex ai-center`}
          >
            {generateMinOrTime(item.entryType, item.minute, item.date)}
          </div>
          {item.textContent && (
            <p
              className="relation-text relation-content"
              dangerouslySetInnerHTML={{ __html: item.textContent }}
            ></p>
          )}
        </div>
        <div className="relation-item-body">
          {item.additionalContent == "image" && item.additionalContentLink && (
            <div className="relation-image">
              <img
                src={`${hostnameImage}${item.additionalContentLink}`}
                alt={params.id}
              />
            </div>
          )}
          {item.additionalContent == "youtube" && item.additionalContentLink && (
            <div>
              <iframe src={generateVideoUrl(item.additionalContentLink)}></iframe>
            </div>
          )}
          {item.additionalContent == "twitter" && item.additionalContentLink && (
            <TwitterTweetEmbed
              tweetId={generateTweetUrl(item.additionalContentLink)}
            />
          )}
        </div>
      </div>
    )
  }

  function fixFrame() {
    const column = document.querySelector('.relation-additional-column')
    let frame
    frame = document.querySelector('.relation-floating-video-wrapper')
    if(column) {
    if(column.getBoundingClientRect().top < 90) {
      frame.style.top = `150px`
    } else {

      frame.style.left = `${column.getBoundingClientRect().x}px`
      frame.style.top = `${column.getBoundingClientRect().top + 63}px`
      frame.style.width = `${column.getBoundingClientRect().width}px`
      frame.style.position = 'fixed'
      frame.style.minWidth = `auto`
    }
  }
  }

useEffect(() => {
  if(params.isVideoOpened) {
      fixFrame() 

    document.addEventListener('scroll', () => {
        fixFrame()
    })
  }
}, [params.isVideoOpened, params.changeTab])



  return (
    <>
    {params.isRelationRefreshVisible && 
      <div className='container mb-30 d-flex jc-center small-h'>
            <span className='ta-center alert'>RELACJA ODŚWIEŻA SIĘ AUTOMATYCZNIE</span>
      </div>
    }
    <div className="timetable-relation">
      <div className="relation-column">
      {params.customButtonLink?.link && params.customButtonLink?.link.includes("youtube") && <button onClick={() => params.setIsVideoOpened(!params.isVideoOpened)} className="button b-gold button-relation-rwd">Relacja Live TV</button>}
      {params.relationsAfter && 
          params.relationsAfter.map((i, ind, ar) => {
              return (
                <React.Fragment key={ind}>
                  {params.relationsAfter.length > 1 && <div className="timetable-date-group"><p>{i[0]}</p></div>}
                  {i[1].map((item, index) => {
                    return (
                      <Element key={index} item={item} />
                    )
                  })}
                  {ind + 1 === ar.length && params.relationsAfter.length > 1 && <div className="timetable-date-group"><p>W trakcie meczu</p></div>}

                </React.Fragment>
                
              )
            })
        }
        {params?.relationsDuring &&
          params?.relationsDuring?.map((item, index) => {
            return (
              <Element key={index} item={item} />
            );
          })}
          {params.relationsBefore && 
          params.relationsBefore.map((i, ind, ar) => {
              return (
                <React.Fragment key={ind}>
                  {params.relationsBefore.length > 1 && <div className="timetable-date-group"><p>{i[0]}</p></div>}
                  {i[1].map((item, index) => {
                    return (
                      <Element key={index} item={item} />
                    )
                  })}

                </React.Fragment>
                
              )
            })
        }
      </div>
      <div className="relation-additional-column">
        <div className="timetable-relation-images">
          {params.customButtonLink?.link && params.customButtonLink?.link.includes("youtube") && <button onClick={() => params.setIsVideoOpened(!params.isVideoOpened)} className="button b-gold">Relacja Live TV</button>}
          {params.relationJson?.sponsors?.map((item, index) => {
            if (item.url) {
              return (
                <div key={index} className="timetable-relation-image">
                  <a href={item.url} target="_blank">
                    <Image
                      src={`/images/relations/${params.title}/${item.image}_width=1000`}
                    />
                  </a>
                </div>
              );
            }
            return (
              <div className="timetable-relation-image">
                <Image
                  src={`/images/relations/${params.title}/${item.image}_width=1000`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </>
  );
};

export default TimetableRelation;