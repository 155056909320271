import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Loaders from "./Loaders.js";
import SearchField from "./SearchField";
import SearchBox from "./portlets/searchBox/SearchBox";
const Redirect = {
  "aktualnosci/": 'aktualnosci/',
  "zespoly/": 'zespoly/druzyna/',
  "rozgrywki/": 'rozgrywki/terminarz-i-wyniki/',
  "klub/": 'klub/struktura/',
  "akademia/": 'akademia/aktualnosci/',
  "mlody-widzew/": 'mlody-widzew/',
  "spolecznosc/": 'spolecznosc/razem-tworzymy-spolecznosc/',
  "biznes/": 'biznes/oferta/'
};
const OuterRedirect = {
  "zespoly/statystyki/": 'https://widzew.statscore.com/'
};

function Nav(params) {
  if (window.origin.includes('localhost') || window.origin.includes('dev-widzew') || window.origin.includes('cms-widzew')) {
    console.log('Build Nav')
  }
  let [navData, setNavData] = useState("");
  useParams();
  let [jsonStatus, setJsonStatus] = useState(null);
  useEffect(() => {
    let fetchNavs = async () => {
      let url = null;
      if (window.location.origin.includes("cms-widzew")) {
        url = window.location.origin + "/.includes/nav/nav-json.json";
      }
      else if (window.location.origin.includes("http://localhost")) {
        url = "https://dev-widzew.qrqs.eu/.includes/nav/nav-json.json";
      }
      else {
        url = window.location.origin + "/.includes/nav/nav-json.json";
      }
      let response = "";
      try {
        response = await Axios(url);
        setJsonStatus(true);
      } catch (err) {
        setJsonStatus(false);
        response = "error";
        console.log('error get nav: ' + err)
      }
      if (response.hasOwnProperty('data')) {
        setNavData(response.data);
      }
      else {
        setJsonStatus(false);
      }
    };
    fetchNavs();
  },[]);
  if (window.origin.includes('localhost') || window.origin.includes('dev-widzew') || window.origin.includes('cms-widzew')) {
    console.log(navData);
  }
  function RedirectLink(item) {
    if (item.line === 'online') {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
          <Link to={Redirect[item.link]} className="menu-item-href">
            {item.text}
          </Link>
        )
      }
      else {
        return (
          <Link to={item.link} className="menu-item-href">
            {item.text}
          </Link>
        )
      }
    }
    else {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
          <a href={`/`+Redirect[item.link]} className="menu-item-href">
            {item.text}
          </a>
        )
      }
      else {
        return (
          <a href={`/`+item.link} className="menu-item-href">
            {item.text}
          </a>
        )
      }
    }
  }
  function RenderNavs() {
    return (
    params.line === "offline"
    ? navData.map((item, i) => {
      return (
        <li id={`menu-item-`+i} key={`menu-item-`+i} className="dropdown active">
          <RedirectLink link={item.path} text={item.text} line="offline"/>
          {item.children &&
              (<ul className="submenu">
                {item.children.map((subitem, ii) => {
                  return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className="dropdown">
                    <a href={`/`+subitem.path} className="menu-item-href">
                      {subitem.text}
                    </a>
                  </li>)
                })}
              </ul>)
            }
        </li>)
      })
    : navData.map((item, i) => {
        return (
          <li id={`menu-item-`+i} key={`menu-item-`+i+'_'+i} className="dropdown">
            <RedirectLink link={item.path} text={item.text} line="online"/>
            {item.children &&
              (<ul className="submenu">
                {item.children.map((subitem, ii) => {
                  if (OuterRedirect.hasOwnProperty(subitem.path)) {
                    return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className="dropdown">
                      <a href={OuterRedirect[subitem.path]} target="_blank" rel="noreferrer" className="menu-item-href">
                        {subitem.text}
                      </a>
                    </li>)
                  }
                  else {
                    return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className="dropdown">
                      <Link to={subitem.path} className="menu-item-href">
                        {subitem.text}
                      </Link>
                    </li>)
                  }
                })}
              </ul>)
            }
          </li>)
      })
    )
  }

  switch (jsonStatus) {
    case null:
      return <ul className="menu"><li className="Loading-nav b-white ml-10"><Loaders type="dots" color="b-red"/></li></ul>;
    case false:
      return <ul className="menu"><li className="Loading-nav b-red"><a href="/" className="menu-item-href">Strona Główna</a></li></ul>;
    case true:
      return (
        <>
          <ul className="menu"><RenderNavs/></ul>
          {params.line === 'online' && <SearchBox type="nav" line={params.line}/>}
        </>
      );
    default:
      break;
  }
};
export default Nav;