import React, { useState, useEffect } from "react";
import Axios from "axios";
import { format, parseISO } from "date-fns";
import TimetableLive from "./timetableDetails/TimetableLive";
import TimetableSquad from "./timetableDetails/TimetableSquad";
import TimetableNews from "./timetableDetails/TimetableNews";
import TimetableMultimedia from "./timetableDetails/TimetableMultimedia";
import TimetableStats from "./timetableDetails/TimetableStats";
import TimetableRelation from "./timetableDetails/TimetableRelation";

const TimetableDetailsBody = ({ matchInProgressScore, params, isVideoOpened, setIsVideoOpened, setChangeTab, changeTab }) => {
  const [showStats, setShowStats] = useState(true);
  const [showLive, setShowLive] = useState(false);
  const [showSquad, setShowSquad] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [showMultimedia, setShowMultimedia] = useState(false);
  const [showRelation, setShowRelation] = useState(false);
  const [isRelationVisible, setIsRelationVisible] = useState(false);

  const [isRelationRefreshVisible, setIsRelationRefreshVisible] = useState(false)

  const [relationsAfter, setRelationsAfter] = useState([]);
  const [relationsDuring, setRelationsDuring] = useState([]);
  const [relationsBefore, setRelationsBefore] = useState([]);

  const [relationJson, setRelationJson] = useState({});

  function showStatsHandler() {
    setShowStats(true);
    setShowLive(false);
    setShowSquad(false);
    setShowNews(false);
    setShowMultimedia(false);
    setShowRelation(false);
    setChangeTab(new Date())
  }

  function showLiveHandler() {
    setShowStats(false);
    setShowLive(true);
    setShowSquad(false);
    setShowNews(false);
    setShowMultimedia(false);
    setShowRelation(false);
    setChangeTab(new Date())
  }
  function showSquadHandler() {
    setShowStats(false);
    setShowLive(false);
    setShowSquad(true);
    setShowNews(false);
    setShowMultimedia(false);
    setShowRelation(false);
    setChangeTab(new Date())
  }
  function showNewsHandler() {
    setShowStats(false);
    setShowLive(false);
    setShowSquad(false);
    setShowNews(true);
    setShowMultimedia(false);
    setShowRelation(false);
    setChangeTab(new Date())
  }
  function showMultimediaHandler() {
    setShowStats(false);
    setShowLive(false);
    setShowSquad(false);
    setShowNews(false);
    setShowMultimedia(true);
    setShowRelation(false);
    setChangeTab(new Date())
  }

  function showRelationHandler() {
    setShowStats(false);
    setShowLive(false);
    setShowSquad(false);
    setShowNews(false);
    setShowMultimedia(false);
    setShowRelation(true);
    setChangeTab('relation')
  }

  let classListStats = showStats
    ? "link-style bigger active"
    : "link-style bigger";
  let classListLive = showLive
    ? "link-style bigger active"
    : "link-style bigger";
  let classListSquad = showSquad
    ? "link-style bigger active"
    : "link-style bigger";
  let classListTimeNews = showNews
    ? "link-style bigger active"
    : "link-style bigger";
  let classListMultimedia = showMultimedia
    ? "link-style bigger active"
    : "link-style bigger";
  let classListRelation = showRelation
    ? "link-style bigger active"
    : "link-style bigger";

  // useEffect(() => {
  //   let fetchRelation = async () => {
  //     let link = '/widzew.json'
  //     // if (params.line === 'offline') {
  //     //   link = window.origin + '/api/matches/tickets.json';
  //     // } else if (window.origin.includes('localhost')) {
  //     //   link = 'https://test-widzew.qrqs.eu/api/matches/tickets.json';
  //     // } else {
  //     //   link = window.origin + '/api/matches/tickets.json';
  //     // }
  //     let response = "";
  //     try {
  //       response = await Axios(link);
  //       if (response.data) {
  //         response.data.sort((a, b) => a.sortId - b.sortId)
  //         setRelationJson(response.data)
  //       }
  //     } catch (err) {
  //       response = "error";
  //     }
  //   };
  //   fetchRelation();
  // }, [])

  function sortRelationsByTime(relations) {
    relations?.events?.sort((a, b) => {
      if (a.entryType === "duringMatch" && b.entryType === "duringMatch") {
        let aTime = a.minute.split("+");
        let bTime = b.minute.split("+");
        if (aTime.length > 1 && bTime.length > 1) {
          if (aTime.length > 1) {
            aTime = parseInt(aTime[0]) + parseInt(aTime[1]);
          }
          if (bTime.length > 1) {
            bTime = parseInt(bTime[0]) + parseInt(bTime[1]);
          } else {
            aTime = parseInt(aTime[0]);
            bTime = parseInt(bTime[0]);
          }
        } else {
          aTime = parseInt(aTime[0]);
          bTime = parseInt(bTime[0]);
        }
        if (aTime === bTime) {
          return parseISO(b.date) - parseISO(a.date);
        }
        return bTime - aTime;
      }
      return parseISO(b.date) - parseISO(a.date);
    });
    let after = []
    let during = []
    let before = []
    relations?.events?.forEach((relation) => {
      if (relation.entryType === "afterMatch") {
        after.push(relation);
      }
    });
    relations?.events?.forEach((relation) => {
      if (relation.entryType === "duringMatch") {
        during.push(relation);
      }
    });
    relations?.events?.forEach((relation) => {
      if (relation.entryType === "beforeMatch") {
        before.push(relation);
      }
    });
    let newAfter = {};
    let newBefore = {};
    after?.forEach((item, index, array) => {
      let relationMonth = parseISO(item.date).getMonth();
      let relationDay = parseISO(item.date).getDate();

      if (relationMonth in newAfter) {
        if (relationDay in newAfter[relationMonth]) {
          newAfter[relationMonth][relationDay].push(item)
        } else {
          newAfter[relationMonth][relationDay] = []
          newAfter[relationMonth][relationDay].push(item);
        }

      } else {
        newAfter[relationMonth] = {}
        newAfter[relationMonth][relationDay] = []
        newAfter[relationMonth][relationDay].push(item);
      }
    })

    before?.forEach((item, index, array) => {
      let relationMonth = parseISO(item.date).getMonth();
      let relationDay = parseISO(item.date).getDate();

      if (relationMonth in newBefore) {
        if (relationDay in newBefore[relationMonth]) {
          newBefore[relationMonth][relationDay].push(item)
        } else {
          newBefore[relationMonth][relationDay] = []
          newBefore[relationMonth][relationDay].push(item);
        }

      } else {
        newBefore[relationMonth] = {}
        newBefore[relationMonth][relationDay] = []
        newBefore[relationMonth][relationDay].push(item);
      }
    })
    setRelationsAfter(makeDateGroupJson(after))
    setRelationsBefore(makeDateGroupJson(before))

    setRelationsDuring(during);
  }

  function makeDateGroupJson(arr) {
    const sortedObj = {};
    arr.forEach(e => {
      const k = format(parseISO(e.date), "dd.MM.yyyy");        // key in YYYY-MM--DD
      const fk = `${k}`;                // key with appended '-01'   
      sortedObj[fk] = sortedObj[fk] || []; // create new entry if no value for key exists
      sortedObj[fk].push(e);               // add key to existing list
    });

    const sortedArr = Object.entries(sortedObj).sort((a, b) => parseISO(a[0]) - parseISO(b[0]));
    return (sortedArr)
  }

  let fetchRelation = async () => {
    let link = "";
    if (params.line === "offline") {
      link =
        window.origin +
        `/api/relations/${params.block["en"].Title}.json?${new Date()}`;
    } else if (window.origin.includes("localhost")) {
      link = `https://dev-widzew.qrqs.eu/api/relations/${params.block["en"].Title}.json?${Date.now()}`;
    } else {
      link =
        window.origin + `/api/relations/${params.block["en"].Title}.json?${Date.now()}`;
    }
    let response = "";
    try {
      response = await Axios(link);
      if (response.data) {
        setRelationJson(response.data);
        sortRelationsByTime(response.data);
        if (response.data?.events.length !== 0 || response.data?.sponsors.length !== 0) {
          setIsRelationVisible(true);
          setShowStats(false);
          setShowRelation(true);
        }
      }
    } catch (err) {
      response = "error";
    }
  };

  //initial relation fetch
  useEffect(() => {
    fetchRelation();
  }, []);

  // check if match is close
  useEffect(() => {
    const dateOfGame = params.block["en"].DateOfGame;
    const today = new Date().getTime();
    const timeDiff = (dateOfGame - today) / 1000 / 60 / 60;
    if (timeDiff < 4 && timeDiff > -4) {
      setIsRelationRefreshVisible(true)
      if(showRelation) {
        
        const interval = setInterval(() => {
          fetchRelation();
        }, 10000);
        return () => clearInterval(interval)
      } else {

      }
    } else {
      setIsRelationRefreshVisible(false)
    }
  }, [showLive, showMultimedia, showNews, showRelation,  showSquad, showStats]);

  return (
    <section className="timetable-details-body">
      <div className="wrapper">
        <div className="container b-white">
          <div className="timetable-details-menu">
            {isRelationVisible && (
              <button
                className={classListRelation}
                onClick={() => { showRelationHandler(); fetchRelation() }}
              >
                Relacja live
              </button>
            )}
            <button className={classListLive} onClick={showLiveHandler}>
              Wydarzenia
            </button>
            <button className={classListSquad} onClick={showSquadHandler}>
              Skład
            </button>
            <button className={classListStats} onClick={showStatsHandler}>
              Statystyki
            </button>
            <button className={classListTimeNews} onClick={showNewsHandler}>
              Aktualności
            </button>
            <button
              className={classListMultimedia}
              onClick={showMultimediaHandler}
            >
              Multimedia
            </button>
          </div>
          <div className="timetable-detail-content">
            {showStats && (
              <TimetableStats matchInProgressScore={matchInProgressScore} />
            )}
            {showLive && (
              <TimetableLive matchInProgressScore={matchInProgressScore} />
            )}
            {showSquad && (
              <TimetableSquad matchInProgressScore={matchInProgressScore} />
            )}
            {showNews && <TimetableNews tags={params.block["en"]["Tags"]} />}
            {showMultimedia && (
              <TimetableMultimedia
                params={params}
                tags={params.block["en"]["Tags"]}
              />
            )}
            {showRelation && (
              <TimetableRelation
                relationJson={relationJson}
                relationsAfter={relationsAfter}
                relationsDuring={relationsDuring}
                relationsBefore={relationsBefore}
                title={params.block["en"].Title}
                isRelationRefreshVisible={isRelationRefreshVisible}
                customButtonLink={params?.block?.en?.CustomButtonLink}
                setIsVideoOpened={setIsVideoOpened}
                isVideoOpened={isVideoOpened}
                changeTab={changeTab}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TimetableDetailsBody;