import React from "react";
/*import { MemoryRouter as Router } from 'react-router-dom';*/
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./Main";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop"
import Cookies from "./Cookies.js";

//INDEX WWW
/*const header = document.getElementById("header");
const headerRoot = createRoot(header);
headerRoot.render(
  <Router>
    <Header />
  </Router>
);*/

const container = document.getElementById("page-wrapper");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <header id="header"><Header line="online"/></header>
    <Routes>
      <Route path="*" element={<main><App /></main>} />
    </Routes>
    <footer id="footer"><Footer line="online"/></footer>
    <Cookies type="policy"/>
  </BrowserRouter>
);