import React, { useState, useEffect } from "react";
import Links from "../../components/Links";
import Image from "../../components/Image";
import axios from "axios";

function NewsLatest(params) {
    if (window.origin.includes('localhost')) {
        console.log('NewsLatest')
        console.log(params)
    }

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }

    let innerSection = 'news-latest';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    let [catData, setCatData] = useState([]);
    let [catDataShort, setCatDataShort] = useState([]);

    useEffect(() => {
        let fetchCats = async () => {
          let url = null;
          if (window.location.origin.includes("cms-widzew")) {
            url = window.location.origin + "/.includes/categories/categories.json";
          }
          else if (window.location.origin.includes("http://localhost")) {
            url = "https://dev-widzew.qrqs.eu/.includes/categories/categories.json";
          }
          else {
            url = window.location.origin + "/.includes/categories/categories.json";
          }
          let response = "";
          try {
            response = await axios(url);
          } catch (err) {
            response = "error";
          }
          if (response.hasOwnProperty('data')) {
            let mainCat = []
            Object.keys(response.data).filter(function(key) {
                if (key.includes('tagi-glowne')) {
                    let tagLast = key.split('/'),
                    last = tagLast[tagLast.length - 2];
                    mainCat[last] = response.data[key]
                }
                return null
            });
            setCatData(mainCat);
            let shortCat = {};
            Object.keys(response.data).map(function(key) {
                //if (key.includes('tagi-glowne')) {
                    let tagLast = key.split('/'),
                    last = tagLast[tagLast.length - 2],
                    name = response.data[key];
                    if (name === '') name = last;
                    shortCat[last] = name;
                //}
                return null
            });
            setCatDataShort(shortCat);
          }
        };
        fetchCats();
      },[]);

    let [newsDisplayLarge, setNewsDisplayLarge] = useState([]),
        [newsDisplaySmall, setNewsDisplaySmall] = useState([]),
        urlNews = "",
        urlO = '';
    switch (window.location.origin) {
        case 'https://dev-cms-widzew.qrqs.eu':
            urlO = 'https://dev-widzew.qrqs.eu'
            break;
        case 'https://test-cms-widzew.qrqs.eu':
            urlO = 'https://test-widzew.qrqs.eu'
            break;
        case 'https://cms-widzew.qrqs.eu':
            urlO = 'https://widzew.com'
            break;
        default:
            break;
    }
    if (window.origin.includes('localhost')) {
        urlNews = 'https://dev-widzew.qrqs.eu/api/jsonws/news/search?index=news&maxResults=5' //'https://dev-widzew.qrqs.eu/aktualnosci/index.html.json'
    } 
    else if (window.origin.includes('cms-widzew')) {
        urlNews = urlO + '/api/jsonws/news/search?index=news&maxResults=5'
    } 
    else {
        urlNews = window.location.origin + '/api/jsonws/news/search?index=news&maxResults=5'
    }

    useEffect(() => {
        axios
          .get(
            urlNews
          )
          .then(({ data }) => { 
            if (data.news.length > 0) {
                data.news.sort(function(a, b){
                    return b['en']['Date'] - a['en']['Date']
                });

                data.news.map((item, i) => {
                    if (i <= 1) {
                        setNewsDisplayLarge(newsDisplayLarge, newsDisplayLarge.push(item));
                    }

                    if (i > 1 && i <= 4) {
                        setNewsDisplaySmall(newsDisplaySmall, newsDisplaySmall.push(item));
                    }
                    
                    return null;
                })

                setNewsDisplayLarge([...newsDisplayLarge]);
                setNewsDisplaySmall([...newsDisplaySmall]);
            }
        });
    }, []);  

    function renderLargeItem() {
        return newsDisplayLarge.map((item,i) => {

            let date = item['en']['Date']
            let currentTimestamp = new Date(date)
            let dates = new Intl.DateTimeFormat('pl-PL', { dateStyle: 'full', timeStyle: 'short'} ).format(currentTimestamp)
                dates = dates.split(" ");

            let newsType = item['en']['Type'];
            let newsTypeSvg = '';
            let newsTag = item['en']['Tags'];
            let newsTagName = "";

                if (!(newsTag === undefined) && newsTag !== "") {
                    let tagLast = newsTag.split('/');
                    newsTagName = tagLast[tagLast.length - 2];
                    newsTagName = catDataShort[newsTagName];
                }
   
                if (newsType === 'photo') {
                    newsTypeSvg = `<svg id="photo-type" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m467 121h-86.703l-11.177-55.883c-2.794-13.974-15.166-24.117-29.417-24.117h-167.406c-14.25 0-26.623 10.143-29.417 24.117l-11.177 55.883h-86.703c-24.813 0-45 20.187-45 45v260c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45v-260c0-24.813-20.187-45-45-45zm-294.703-50h167.406l10 50h-187.406zm309.703 355c0 8.271-6.729 15-15 15h-422c-8.271 0-15-6.729-15-15v-260c0-8.271 6.729-15 15-15h422c8.271 0 15 6.729 15 15z"/><path d="m256 185c-61.206 0-111 49.794-111 111s49.794 111 111 111 111-49.794 111-111-49.794-111-111-111zm0 192c-44.664 0-81-36.337-81-81s36.336-81 81-81 81 36.337 81 81-36.336 81-81 81z"/><path d="m128 185h-48c-8.284 0-15 6.716-15 15s6.716 15 15 15h48c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/></g></svg>
                    `
                } else if (newsType === 'video') {
                    newsTypeSvg = `<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g id="Watching_Movies" data-name="Watching Movies"><path d="m41 6h-34a5.006 5.006 0 0 0 -5 5v21a5.006 5.006 0 0 0 5 5h12v3h-10a1 1 0 0 0 0 2h30a1 1 0 0 0 0-2h-10v-3h12a5.006 5.006 0 0 0 5-5v-21a5.006 5.006 0 0 0 -5-5zm-14 34h-6v-3h6zm17-8a3 3 0 0 1 -3 3h-34a3 3 0 0 1 -3-3v-21a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3z"/><path d="m27.977 19.768-4.952-2.859a2 2 0 0 0 -3 1.732v5.718a2 2 0 0 0 3 1.732l4.953-2.859a2 2 0 0 0 0-3.464zm-5.953 4.591v-5.718l4.952 2.859z"/><path d="m24 11a10.5 10.5 0 1 0 10.5 10.5 10.512 10.512 0 0 0 -10.5-10.5zm0 19a8.5 8.5 0 1 1 8.5-8.5 8.51 8.51 0 0 1 -8.5 8.5z"/></g></svg>`
                } else if (newsType === 'audio') {
                    newsTypeSvg = `<svg enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256,31C115.39,31,0,145.39,0,286v120c0,24.814,20.186,45,45,45h15V301H45c-5.284,0-10.285,1.082-15,2.763V286    C30,161.928,131.928,61,256,61s226,100.928,226,225v17.763c-4.715-1.681-9.716-2.763-15-2.763h-15v150h15    c24.814,0,45-20.186,45-45V286C512,145.39,396.61,31,256,31z"/>
                    <path d="m135 271h-15c-16.569 0-30 13.431-30 30v150c0 16.569 13.431 30 30 30h15c8.284 0 15-6.716 15-15v-180c0-8.284-6.716-15-15-15z"/>
                    <path d="m392 271h-15c-8.284 0-15 6.716-15 15v180c0 8.284 6.716 15 15 15h15c16.569 0 30-13.431 30-30v-150c0-16.569-13.431-30-30-30z"/>
                </svg>`
                } else {
                    newsTypeSvg = `<svg id="article-type" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m22.5 9h-21c-.827 0-1.5-.673-1.5-1.5v-6c0-.827.673-1.5 1.5-1.5h21c.827 0 1.5.673 1.5 1.5v6c0 .827-.673 1.5-1.5 1.5zm-21-8c-.275 0-.5.224-.5.5v6c0 .276.225.5.5.5h21c.275 0 .5-.224.5-.5v-6c0-.276-.225-.5-.5-.5z"/></g><g><path d="m9.5 18h-8c-.827 0-1.5-.673-1.5-1.5v-4c0-.827.673-1.5 1.5-1.5h8c.827 0 1.5.673 1.5 1.5v4c0 .827-.673 1.5-1.5 1.5zm-8-6c-.275 0-.5.224-.5.5v4c0 .276.225.5.5.5h8c.275 0 .5-.224.5-.5v-4c0-.276-.225-.5-.5-.5z"/></g><g><path d="m23.5 12h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m23.5 15h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m23.5 18h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m23.5 21h-23c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h23c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m23.5 24h-23c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h23c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g></svg>`
                }

            return (
                <div className="d-flex col-lg-6 col-sm-12 item-news-latest" key={'articleLarge-'+i}>
                    <div className="d-flex thumbnail-large">
                        {item['link'] &&
                            <Links line={params.line} url={item['link']} class="all-link" name="Artykuł" />
                        }
                        {item['en']['Image'] && 
                            <Image src={item['en']['Image']} width="600" class="thumbnail-home" alt="miniatura" />
                        }
                        <div className="d-flex ff-column pl-20 pt-20 h-100 jc-between">
                            <div>
                                {dates &&
                                    <span className="date d-flex">
                                        <span className="day"><p>{dates[1]}</p></span>
                                        <span className="msc-hours"><p>{dates[2]} {dates[3]}</p><p>{dates[4] === 'o' ? dates[5] : dates[4]}</p></span>
                                    </span>
                                }
                            </div>
                            <div className="mb-20">
                                <div className="icon mb-20">
                                    <span className="icon-svg"
                                        dangerouslySetInnerHTML= {
                                            { __html: newsTypeSvg }
                                        }
                                    ></span>
                                </div>
                                <div className="chip-tag d-flex f-row mb-20">
                                    {newsTagName !== "" &&
                                        <p className="tag d-flex ai-center">{newsTagName}</p>
                                    }
                                </div>
                                <div className="d-flex f-row pr-30">
                                    {item['en']['Title'] &&
                                        <h3>
                                            {item['en']['Title']}
                                        </h3>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    function renderSmallItem() {
        return newsDisplaySmall.map((item,i) => {

            let date = item['en']['Date']
            let currentTimestamp = new Date(date)
            let dates = new Intl.DateTimeFormat('pl-PL', { dateStyle: 'full', timeStyle: 'short'} ).format(currentTimestamp)
                dates = dates.split(" ");

            let newsTag = item['en']['Tags'];
            let newsTagName = "";

                if (!(newsTag === undefined) && newsTag !== "") {
                    let tagLast = newsTag.split('/');
                    newsTagName = tagLast[tagLast.length - 2];
                    newsTagName = catDataShort[newsTagName];
                }

            return (
                <div className="d-flex col-lg-4 col-sm-12 item-news-latest" key={'articleSmall-'+i}>
                    <div className="d-flex thumbnail-small">
                        {item['link'] &&
                            <Links line={params.line} url={item['link']} class="all-link" name="Artykuł" />
                        }
                        {item['en']['Image'] && 
                            <Image src={item['en']['Image']} width="400" class="thumbnail-home" alt="miniatura" />
                        }
                        <div className="d-flex ff-column pl-20 pt-20 h-100 jc-between">
                            <div>
                                {dates &&
                                    <span className="date d-flex">
                                        <span className="day"><p>{dates[1]}</p></span>
                                        <span className="msc-hours"><p>{dates[2]} {dates[3]}</p><p>{dates[4] === 'o' ? dates[5] : dates[4]}</p></span>
                                    </span>
                                }
                            </div>
                            <div className="mb-20">
                                <div className="chip-tag d-flex f-row mb-20">
                                    {newsTagName !== "" &&
                                        <p className="tag d-flex ai-center">{newsTagName}</p>
                                    }
                                </div>
                                <div className="d-flex f-row pr-30">
                                    {item['en']['Title'] &&
                                        <h3>
                                            {item['en']['Title']}
                                        </h3>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }


    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <div className="d-flex f-wrap ai-end pb-0 b-red header-title">
                        <h1 className="d-flex f-column jc-center mr-40">
                            {params.block['en']['Title']}
                        </h1>
                        <div className="d-flex">
                            {params.block['en']['Item'].map(item => {
                                if (item['NameLabel'] && item['UrlLink']) {
                                    return <Links line={params.line} url={item['UrlLink']} class="act-true" name={item['NameLabel']} />
                                }
                            })}
                        </div>
                    </div>
                    <div className="d-flex f-wrap col-12 mt-30">
                        <>
                            {renderLargeItem()}
                        </>
                        <>
                            {renderSmallItem()}
                        </>
                    </div>
                    <div className="d-flex jc-center mt-20">
                        <Links line={params.line} url="/aktualnosci/" class="clean button empty-white" name="Wszystkie aktualności"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default NewsLatest;