import React from "react";
import Image from "../../components/Image.js";
import '../../.templates/css/portlets/achievements/AchievementsItem.css';

function AchievementsItem(params) {
    return (
        <div className="achievements-item d-flex f-row col-set">
            {params.image &&
                <Image src={params.image.path} class="img-box" />
            }
            <div className="achievement-description d-flex f-column custom-big">
                {params.title &&
                    <h1>{params.title}</h1>

                }
                <div className="achievement-item-texts">
                    {params.text &&
                        <h3
                            dangerouslySetInnerHTML={
                                { __html: params.text }
                            }
                        >
                        </h3>
                    }
                </div>
            </div>
        </div>
    );
}

export default AchievementsItem;