import React from "react";
import '../../.templates/css/portlets/achievements/Achievements.css';
import AchievementItem from "./AchievementItem";

function Achievements(params) {
    function Items() {
        if (params.block['en'].hasOwnProperty('Item')) {
            return (Object.keys(params.block['en']['Item']).map((item, i) => (
                <AchievementItem
                    title={params.block['en']['Item'][item]['Title']}
                    text={params.block['en']['Item'][item]['Text']}
                    altText={params.block['en']['Item'][item]['AltText']}
                    image={params.block['en']['Item'][item]['Image']}
                    key={'achivItem' + i}
                />
            )))
        }
    }

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }
    let itemLength = 'col-set-0'
    if (params.block['en'].hasOwnProperty('Item')) {
        itemLength = 'col-set-' + Object.keys(params.block['en']['Item']).length
    }
    let itemContainer = 'achievements-container d-flex f-row jc-between mb-50 ' + itemLength;
    let innerSection = 'achievements';
    let innerContainer = 'container d-flex f-column jc-center';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    {params.block['en']['Title'] && <h1>{params.block['en']['Title']}</h1>}
                    <div className={itemContainer}>
                        <Items />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Achievements;