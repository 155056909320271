import React from "react";
import SponsorsItem from "./SponsorsItem";

function Sponsors(params) {
    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }

    let innerSection = 'sponsors';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <div className="custom-small">
                        {Object.keys(params.block['en']['Item']).map((item, i) => (
                            <SponsorsItem
                                item={params.block['en']['Item'][item]}
                                title={params.block['en']['Item'][item]['Title']}

                            />

                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Sponsors;