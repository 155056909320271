import React from "react";
import Image from "../../components/Image.js";
import Links from "../../components/Links";

function SponsorsTile(params) {
    return (
        <div className="sponsors-item text d-flex mb-80">
            <div className="sponsors-img">
                {(params.image && params.Link.Link) &&
                    <a href={params.Link.Link.link} target="_blank">
                        <Image src={params.image} altText={params.altText} />
                    </a>
                }
                {(params.image && !params.Link.Link) &&
                    <span>
                        <Image src={params.image} altText={params.altText} />
                    </span>
                }
            </div>
            <div className="sponsor-informations small-h">
                {params.title &&
                    <h3>{params.title}</h3>
                }
                {params.Text &&
                    <div className="text mb-30 small-h"
                        dangerouslySetInnerHTML={
                            { __html: params.Text }
                        }
                    >
                    </div>
                }
                {params.Link.Link && 
                    <p><Links line={params.line} url={params.Link.Link} target="true" name={params.LinkName.LinkName} /></p>               
                }
            </div>
        </div>
    );
}

export default SponsorsTile;