import React, { useState } from "react";
import Loaders from "../Loaders";
import Svg from "../components/Svg"

function Image(params) {
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    let image = '';

    if (params.src !== "") {
        if (params.src.hasOwnProperty('link')) {
            image = params.src.link;
        } else if (params.src.hasOwnProperty('path')) {
            image = params.src.path;
        } else {
            image = params.src;
        }
    }

    if (image.includes("/sites/widzew")) {
        image = image.split('/sites/widzew');
        image = image[1];
    }

    if ( window.location.origin.includes("http://localhost") && !(image.includes("http://") || image.includes("https://")) ) {
        image = 'https://dev-widzew.qrqs.eu'+image;
    }
    else if ( window.location.origin.includes('cms-widzew') && !(image.includes("http://") || image.includes("https://")) ) {
        image = window.location.origin+image
        /*let urlSite = window.location.origin;
        urlSite = urlSite.replace('cms-', '');
        image = urlSite+image; (not work for prod cms)*/
    }

    if (params.width !== "" && params.width) {
        image = image + '_width=' + params.width;
    }

    if(params?.haveLoader) {
        return (
            <>
                {isLoading && <Loaders type="dots" color="b-black"/>}
                {!isError && <img loading="lazy" className={params.class} src={image} alt={params.alt} onLoad={() => {setIsLoading(false)}} onError={() => {setIsLoading(false); setIsError(true)}} />}
                {isError && <div className="error-image"><Svg name="preimage1" /></div>}
            </>
        )
    }

    return (
        <img loading="lazy" className={params.class} src={image} alt={params.alt}/>
    )
    

}

export default Image;