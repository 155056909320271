import React from "react";
import Links from "../../components/Links";

function AcademyButton(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew') || window.origin.includes('dev-cms-widzew')) {
        console.log('AcademyButton')
        console.log(params)
    }
    
    let url = params.block.hasOwnProperty('Link') ? (params.block['Link'].hasOwnProperty('link') ? params.block['Link']['link'] : '') : '',
        linkName = params.block.hasOwnProperty('LinkName') ? params.block['LinkName'] : '',
        buttonsType = params.block.hasOwnProperty('ButtonsType') ? params.block['ButtonsType'] : 'b-white',
        InNewOpen = params.block.hasOwnProperty('NewTab') ? (params.block['NewTab'] === true ? 'true' : 'false') : 'false';

    return (
        <div class="buttons-item">
            {(!(linkName === '' || url === '')) &&
                <Links line={params.line} url={url} target={InNewOpen} class={"button " + buttonsType} name={linkName} />
            }
        </div>
    )
}
export default AcademyButton;