import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import FormBox from "../formBox/FormBox";
import { CSSTransition } from "react-transition-group";
const Modal = props => {
    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    function closeNewslForm() {
        let formid = document.getElementById('closeNewsl')
        if (formid) {
            setTimeout(function(){
                formid.click()
            }, 0)
        }
    }
    return ReactDOM.createPortal(
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className="modal d-flex jc-center" onClick={props.onClose}>
                <div className="modal-content container" onClick={e => e.stopPropagation()}>
                    {props.setFormBox
                        ? 
                        <button onClick={props.onClose} className="button-close" id="closeNewsl">
                            x
                        </button>
                        :
                        <button onClick={() => {props.onClose();closeNewslForm()}} className="button-close" id="closeForm">
                            x
                        </button>
                    }  
                    <div className="modal-header">
                        <h1 className="ta-center">{props.title}</h1>
                    </div>
                    <div className="modal-body">
                        {props.setFormBox && <FormBox />}
                        {props.children && <p>{props.children}</p>}
                    </div>
                </div>
            </div>
        </CSSTransition>,
        document.getElementsByTagName("BODY")[0]
    );
};

export default Modal;
