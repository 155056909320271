import React from 'react'

const ShopBoxItem = (params) => {
  let url = `${params.url}/images/api-shop/${params.item.image}`;
  const svgMarket = `
  <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg" style="fill: #000;" id="bag-icon-id"><g id="bag-icon" data-name="bag-icon"><path d="m16 1a6 6 0 0 0 -6 6v1h-.83a3.27 3.27 0 0 0 -3.27 3.12l-.79 16.45a3.28 3.28 0 0 0 3.27 3.43h15.24a3.28 3.28 0 0 0 3.27-3.43l-.79-16.45a3.27 3.27 0 0 0 -3.27-3.12h-.83v-1a6 6 0 0 0 -6-6zm-4 6a4 4 0 0 1 8 0v1h-8zm12.1 4.21.79 16.46a1.31 1.31 0 0 1 -.35.94 1.29 1.29 0 0 1 -.92.39h-15.24a1.29 1.29 0 0 1 -.92-.39 1.31 1.31 0 0 1 -.35-.94l.79-16.46a1.27 1.27 0 0 1 1.27-1.21h13.66a1.27 1.27 0 0 1 1.27 1.21z"/></g></svg>
`;

  return (
    <div className="shop-box-carousel-item d-flex jc-between f-column p-20">
      <div>
        <img src={url} alt={params.item.productName} />
      </div>
      <div className="shop-box-item-desc d-flex jc-between ai-end mt-10">
        <div className="small-p">
          <p>
            {params.item.productName}
          </p>
          <p className="shop-item-price">{params.item.price.toFixed(2).replace('.', ',')}<small>zł</small></p>
        </div>
        <div>
          <a href={params.item.url} className="button b-gold d-flex ai-center jc-center" target="_blank">
            <span className="svgMarket"
              dangerouslySetInnerHTML={
                { __html: svgMarket }
              }
            >
            </span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ShopBoxItem