import React from "react";

function Head(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
        console.log('Head')
        console.log(params.block)
    }
    if (params.block['Title'] && params.block['Title'] !== '' && !window.location.pathname.includes('/item/')) {
        document.title = params.block['Title']
        document.querySelector("meta[property='og:title']").setAttribute("content", params.block['Title'])
        document.querySelector("meta[name='twitter:title']").setAttribute("content", params.block['Title'])
    }
    if (params.block['Description'] && params.block['Description'] !== '' && !window.location.pathname.includes('/item/')) {
        document.querySelector('meta[name="description"]').setAttribute("content", params.block['Description']);
        document.querySelector("meta[property='og:description']").setAttribute("content", params.block['Description'])
        document.querySelector("meta[name='twitter:description']").setAttribute("content", params.block['Description'])
    }
    document.querySelector("meta[property='og:url']").setAttribute("content", window.location.href)
    document.querySelector("meta[name='facebook-domain-verification']").setAttribute("content", window.location.href)
};  
export default Head;