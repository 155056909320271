import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ImageGallery from 'react-image-gallery';
import { createRoot } from "react-dom/client";
import Loaders from "../../Loaders.js";
import Svg from "../../components/Svg";

let newsPageLoaded = 28;

function Galleries(params) {
    if (window.origin.includes('localhost')) {
        console.log('Galleries')
        console.log(params)
    }

    const   [allGalleries, setAllGalleries] = useState([]),
            [allGalleriesJson, setAllGalleriesJson] = useState([]),
            [datas, setData] = useState(null),
            [hiddenChooseTags, setHiddenChooseTags] = useState('hidden'),
            [sortType, setSortType] = useState('all'),
            [showGallery, setShowGallery] = useState('hidden'),
            [gallery, setGallery] = useState([]),
            [FullScreen, setFullScreen] = useState(false),
            [numbersGallery, setNumersGallery] = useState(28),
            [setCat, setSetCat] = useState(''),
            closeIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 682.66669 682.66669" style="enable-background:new 0 0 512 512" xml:space="preserve"><g><path xmlns="http://www.w3.org/2000/svg" d="m460.175781 215.175781-104.800781 104.800781 104.800781 104.796876-35.351562 35.359374-104.800781-104.800781-104.796876 104.800781-35.359374-35.359374 104.800781-104.796876-104.800781-104.800781 35.359374-35.351562 104.796876 104.800781 104.800781-104.800781zm179.824219 104.824219c0 85.472656-33.285156 165.835938-93.726562 226.273438-60.4375 60.441406-140.800782 93.726562-226.273438 93.726562s-165.835938-33.285156-226.273438-93.726562c-60.441406-60.4375-93.726562-140.800782-93.726562-226.273438s33.285156-165.835938 93.726562-226.273438c60.4375-60.441406 140.800782-93.726562 226.273438-93.726562s165.835938 33.285156 226.273438 93.726562c60.441406 60.4375 93.726562 140.800782 93.726562 226.273438zm-50 0c0-148.875-121.125-270-270-270s-270 121.125-270 270 121.125 270 270 270 270-121.125 270-270zm0 0" fill="#cca34a" data-original="#000000"/></g></svg>`;

    let [beforeScroll, setBeforeScroll] = useState(0),
        [catData, setCatData] = useState([]),
        [catDataShort, setCatDataShort] = useState([]),
        showOnly = '',
        colorBack = '',
        sortParams = '';

    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }

    if (params.block['en']['ShowOnlyGalleriesByTags']) {
        showOnly = [ params.block['en']['ShowOnlyGalleriesByTags'] ];
    }

    let innerSection = 'galleries',
        innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    useEffect(() => {
        let fetchCats = async () => {
          let url = null;
          if (window.location.origin.includes("cms-widzew")) {
            url = window.location.origin + "/.includes/categories/categories.json";
          }
          else if (window.location.origin.includes("http://localhost")) {
            url = "https://dev-widzew.qrqs.eu/.includes/categories/categories.json";
          }
          else {
            url = window.location.origin + "/.includes/categories/categories.json";
          }
          let response = "";
          try {
            response = await axios(url);
          } catch (err) {
            response = "error";
          }
          if (response.hasOwnProperty('data')) {
            let mainCat = []
            Object.keys(response.data).filter(function(key) {
                if (key.includes('tagi-glowne')) {
                    let tagLast = key.split('/'),
                    last = tagLast[tagLast.length - 2];
                    mainCat[last] = response.data[key]
                }
                return null
            });
            setCatData(mainCat);
            let shortCat = {};
            Object.keys(response.data).map(function(key) {
                    let tagLast = key.split('/'),
                    last = tagLast[tagLast.length - 2],
                    name = response.data[key];
                    if (name === '') name = last;
                    shortCat[last] = name;
                return null
            });
            setCatDataShort(shortCat);
          }
        };
        fetchCats();
      },[]);

    let link = "",
        linkImg = "",
        path = '/images/galleries/list.json';
    switch (window.location.origin) {
        case 'https://dev-cms-widzew.qrqs.eu':
            link = 'https://dev-widzew.qrqs.eu'+ path
            break;
        case 'https://test-cms-widzew.qrqs.eu':
            link = 'https://test-widzew.qrqs.eu'+ path
            break;
        case 'https://cms-widzew.qrqs.eu':
            link = 'https://widzew.com'+ path
            break;
        case 'http://localhost:3001':
            link = 'https://dev-widzew.qrqs.eu'+ path
            break;
        default:
            link = window.location.origin + path
            break;
    }
    useEffect(() => {
        axios
          .get(
            link
          )
          .then(({ data }) => {
            sortParams = Object.values(data);
            let thumbnail = "";
            sortParams.map((item, i) => {
                if (item.path && item.path.includes('/sites/widzew')) {
                    item.path = item.path.split('/sites/widzew');
                    item.path = item.path[1];
                }

                let path = item.path;
                switch (window.location.origin) {
                    case 'https://dev-cms-widzew.qrqs.eu':
                        link = 'https://dev-widzew.qrqs.eu'+ path
                        break;
                    case 'https://test-cms-widzew.qrqs.eu':
                        link = 'https://test-widzew.qrqs.eu'+ path
                        break;
                    case 'https://cms-widzew.qrqs.eu':
                        link = 'https://widzew.com'+ path
                        break;
                    case 'http://localhost:3001':
                        link = 'https://dev-widzew.qrqs.eu'+ path
                        break;
                    default:
                        link = window.location.origin + path
                        break;
                }
                linkImg = link;
                let categories = [];

                item.categories.map(item => {
                    let i = item.split('/')
                    let tag = i[i.length - 2]

                    categories.push(tag);
                })
                
                if (item.own_properties["default-file"]) {
                    thumbnail = item.path + item.own_properties["default-file"] + '_width=290';
                } else {
                    thumbnail = item.path + item.properties["default-file"] + '_width=290';
                }
                
                if (showOnly) {
                    let showOnlyTags = [];
                    showOnly = showOnly[0].split(',');
                    showOnly.map(item => {
                        let i = item.split('/')
                        let tag = i[i.length - 2]
                        if (!Object.values(showOnlyTags).includes(tag)) {
                            showOnlyTags.push(tag)
                        }
                    })

                    let thisItem = false;
                    categories.map(item => {
                        if (showOnlyTags.includes(item)) {
                            thisItem = true;
                        }
                    })

                    if (thisItem === true) {
                        setAllGalleriesJson(allGalleriesJson, allGalleriesJson.push({'urlGallery': linkImg, 'thumbnail': thumbnail, 'title': item.own_properties.Title, 'tags': categories}))
                        thisItem = false;
                    }
                    
                } else {
                    setAllGalleriesJson(allGalleriesJson, allGalleriesJson.push({'urlGallery': linkImg, 'thumbnail': thumbnail, 'title': item.own_properties.Title, 'tags': categories}))
                }          
            });
            setAllGalleries([...allGalleries]);
            setAllGalleriesJson([...allGalleriesJson])
            setData([...allGalleriesJson])
        });
    }, [numbersGallery]);

    function renderGallerySlider(e) {
        setGallery(new Array())
        if (e) {
            let link = e + 'list.json'
            fetch(link)
                .then(response => response.json())
                .then(data => {
                    const items = Object.values(data)
                    items.map(item => {
                        if (item.path && item.path.includes('/sites/widzew')) {
                            item.path = item.path.split('/sites/widzew');
                            item.path = item.path[1];
                        }

                        if (window.origin.includes('localhost')) {
                            linkImg = 'https://dev-widzew.qrqs.eu' + item.path;
                        } else {
                            let link = '',
                                path = item.path;
                            switch (window.location.origin) {
                                case 'https://dev-cms-widzew.qrqs.eu':
                                    link = 'https://dev-widzew.qrqs.eu'+ path
                                    break;
                                case 'https://test-cms-widzew.qrqs.eu':
                                    link = 'https://test-widzew.qrqs.eu'+ path
                                    break;
                                case 'https://cms-widzew.qrqs.eu':
                                    link = 'https://widzew.com'+ path
                                    break;
                                case 'http://localhost:3001':
                                    link = 'https://dev-widzew.qrqs.eu'+ path
                                    break;
                                default:
                                    link = window.location.origin + path
                                    break;
                            }
                            linkImg = link;
                        }

                        let thumbnail = linkImg + '_width=92';

                        return setGallery( gallery, gallery.push({'original': linkImg+ '_width=1200', 'fullscreen': linkImg,'thumbnail': thumbnail, 'description': item.properties.Title, 'originalTitle': item.properties.Title, 'loading': 'lazy'}) )
                    })
                });
            setShowGallery('');

            setTimeout(function () {
                const   element = document.getElementById('gallery-div'),
                        header = document.getElementById('nav').offsetHeight,
                        y = element.getBoundingClientRect().top + window.pageYOffset - header - 40;
                setBeforeScroll(window.pageYOffset)
                window.scrollTo({top: y, behavior: 'smooth'});
            }, 200)
            
        } else {
            setShowGallery('hidden');
            setTimeout(function () {
                window.scrollTo({top: beforeScroll, behavior: 'smooth'});
            }, 100)
        }
    }

    let activeClickOne = (event) => {
        if (event.target.classList.contains('active')) {
            event.target.classList.remove('active');
        } 
        else { 
            event.target.classList.add('active');         
        }
    }

    useEffect(() => {
        const sortTypes = type => {
            const newList = [];
            setChoosenTagsList([])
            if (type === 'all' ) {
                setData(allGalleriesJson);
            }
            else {
                allGalleriesJson.map((teaser, i) => {
                    if (teaser['tags'] === type) {
                        newList.push(teaser)
                    }
                    return null
                });
                setData(newList);
            }
        };
        sortTypes(sortType);
    }, [sortType]);

    useEffect(() => {
        const setCats = cat => {
            let setTag = document.getElementById('add-'+cat)
            if (setTag) {
                setTag.click();
            }
        };
        setCats(setCat);
    }, [setCat]);

    const [choosenTagsList, setChoosenTagsList] = useState([]);
    let tagsList = [];
    function Tags() {
        allGalleriesJson.map((item, i) => {
            item.tags.map(tag => {
                if (!Object.values(tagsList).includes(tag)) {
                    tagsList.push(tag)
                }
            })
            
            return null;
        });

        if (tagsList.length === 0) {
            return (<div><span className="c-white tag-item">Nie wybrano żadnej kategorii w panelu zarządzania...</span></div>)
        }
        else {
            return (tagsList.map((tag) => {
                if (tag in catDataShort) {
                    let name = catDataShort[tag],
                        href = '#'+tag,
                        addHide = '';
                    if (name === undefined || name === '') name = tag;
                    
                    if (!(tag in catData)) {
                        addHide = ' hidden'
                    }

                    if (Object.values(choosenTagsList).includes(tag)) {
                        return (<a key={href} href={href} className={'tag-item c-white active'+addHide} data-tags={tag} onClick={(e) => {e.preventDefault();}}>{name}</a>)
                    } else if (window.location.hash && tag === window.location.hash.substring(1)) {
                        setTimeout(() => setSetCat(window.location.hash.substring(1)), 0);
                        return (<a key={href} href={href} id={`add-`+tag} className={'tag-item c-white'+addHide} data-tags={tag} onClick={(e) => {e.preventDefault();setHiddenChooseTags('');activeClickOne(e);setSortTag(e.target.attributes['data-tags'].value);}}>{name}</a>)
                    } else {
                        return (<a key={href} href={href} id={`add-`+tag} className={'tag-item c-white'+addHide} data-tags={tag} onClick={(e) => {e.preventDefault();setHiddenChooseTags('');activeClickOne(e);setSortTag(e.target.attributes['data-tags'].value);}}>{name}</a>)
                    }
                } else {
                    return ''
                }
            }))
        }
    }

    const [sortTag, setSortTag] = useState('all');
    useEffect(() => {
        const sortTagType = tag => {
            const newList = [];
            if (tag !== 'all') {

                if (!Object.values(choosenTagsList).includes(tag)) {
                    choosenTagsList.push(tag)
                }

                allGalleriesJson.map((teaser, i) => {  
                    if (teaser['tags'] !== undefined && teaser['tags'] !== '' && teaser['tags'].length > 0) {
                        if (teaser['tags'] === sortType || sortType === 'all') {
                            let added = false;
                            choosenTagsList.map((tag) => {
                                if (teaser['tags'].includes(tag)) {
                                    if (!added) {
                                        newList.push(teaser)
                                        added = true
                                    }
                                }
                                return null;
                            })
                        }
                    }
                    return null
                });
                setData(newList);
            }
        };
        sortTagType(sortTag);
    }, [sortTag]);

    function ChooseTags() {
        return (choosenTagsList.map((tag) => {
            let href = '#'+tag,
                idTag = 'tag-'+tag,
                name = catDataShort[tag]
            return (
                <div className="chosen-tag d-flex ai-center mb-20" key={tag + 'x'} id={idTag}>
                    <a href={href} className="all-link" data-tag={tag} onClick={(e) => {e.preventDefault();RemoveTags(e.target.attributes['data-tag'].value);}}>
                        {href}
                    </a>
                    <div className="close-tag">
                        <span className="close-icon"
                            dangerouslySetInnerHTML= {
                                { __html: closeIcon }
                            }
                        >
                        </span>
                    </div>
                    <p className="c-gold">{name}</p>
                </div>
            )
        }))
    }
    function RemoveTags(e) {
        choosenTagsList.splice(choosenTagsList.indexOf(e),1);
        setChoosenTagsList(choosenTagsList);
        setSortTag('all')
        if (choosenTagsList.length === 0) {
            const newsLists = [];
            if (sortType === 'all') {
                setData(allGalleriesJson);
                setHiddenChooseTags('hidden');
            }
            else {
                allGalleriesJson.map((teaser, i) => {
                    if (teaser['tags'].includes(sortType)) {
                        newsLists.push(teaser)
                    }
                    return null
                });
                setData(newsLists);
            }
        }
        else {
            const newsLists = [];
            if (sortType === 'all') {
                allGalleriesJson.map((teaser, i) => {
                    if (teaser['tags'] !== undefined && teaser['tags'] !== '') {
                        let added = false;
                        choosenTagsList.map((tag) => {
                            if (teaser['tags'].includes(tag)) {
                                if (!added) {
                                    newsLists.push(teaser)
                                    added = true
                                }
                            }
                            return null;
                        })
                    }
                    return null
                })
            }
            else {
                allGalleriesJson.map((teaser, i) => {
                    if (teaser['tags'] === sortType) {
                        if (teaser['tags'] !== undefined && teaser['tags'] !== '') {
                            let added = false
                            choosenTagsList.map((tag) => {
                                if (teaser['tags'].includes(tag)) {
                                    if (!added) {
                                        newsLists.push(teaser)
                                        added = true
                                    }
                                }
                                return null;
                            })
                        }
                    }
                    return null
                });
            }
            setData(newsLists);
        }
    }

    let arrayNewsPosts = [];
    const Items = () => {
        const [newsToShow, setNewsToShow] = useState('empty'),
            newsPerPage = newsPageLoaded, //change news per page, go to the beginning of the code (required for closing galleries)
            newsPerLoad = 8,
            ref = useRef(newsPerPage),
            loopWithSlice = (start, end) => {
                const slicedNews = datas.slice(start, end)
                arrayNewsPosts = arrayNewsPosts.concat(slicedNews)
                setNewsToShow(arrayNewsPosts)
            }
        useEffect(() => {
            loopWithSlice(0, newsPerPage)
        }, [])
        const handleShowMorePosts = () => {
            loopWithSlice(ref.current, ref.current + newsPerLoad)
            ref.current += newsPerLoad
            newsPageLoaded += newsPerLoad;
        }
        function LoadButton() {
            if (newsToShow !== 'empty' && newsToShow.length === datas.length) {
                return ( 
                    <div className="d-flex jc-center w-100"></div>
                )
            }
            else {
                return (
                    <div className="d-flex jc-center w-100">
                        <a href="#loadmore" className="button b-gold mt-20"  onClick={(e) => {e.preventDefault();handleShowMorePosts()}}>ZAŁADUJ WIĘCEJ</a>
                    </div>
                )
            }
        }
        return (
            <div className="all-galleries d-flex f-wrap mt-20 mb-20">
                {newsToShow && newsToShow !== 'empty' && newsToShow.length > 0 &&
                    newsToShow.map((item, i) => {
                        let GalleryUrl = '';
                        let linkGallery = item.thumbnail;
            
                        if (linkGallery !== '' && linkGallery.includes("/sites/widzew")) {
                            linkGallery = linkGallery.split('/sites/widzew');
                            linkGallery = linkGallery[1];
                        }
            
                        GalleryUrl = item.urlGallery
            
                        if (GalleryUrl !== '' && GalleryUrl.includes("/sites/widzew")) {
                            GalleryUrl = GalleryUrl.split('/sites/widzew');
                            GalleryUrl = GalleryUrl[1];
                        }
            
                        return (
                            <div className="item mb-20" key={'galleries-0'+i}>
                                <div className="d-flex f-wrap thumbnail">
                                    <span onClick={() => renderGallerySlider(GalleryUrl)} className="gallery-url">
                                        <RenderImageThumb src={linkGallery} alt={item.title} />
                                    </span>
                                </div>
                                {item.title &&
                                    <h3>
                                        {item.title}
                                    </h3>
                                }
                                {!item.title &&
                                    <h3>
                                        Galeria Widzew Łódź {i+1}
                                    </h3>
                                }
                            </div>
                        )
                    })
                }
                {newsToShow && newsToShow.length === 0 && 
                    <div><h3 className="error-text">NIE ZNALEZIONO GALERII Z WYBRANEJ KATEGORII</h3></div>
                }
                {newsToShow && newsToShow === 'empty' && 
                    <li className="list-entry">
                        <div><h3 className="c-red"><Loaders type="dots" color="b-red"/></h3></div>
                    </li>
                }
                
                <LoadButton/>
            </div>
        )
    }
    function ImageError(elem) {
        let span = document.createElement("div");
        span.classList.add("error-image");
        elem.before(span);
        const root = createRoot(span);
        root.render(
            <Svg name="preimage1" />
        );
    }
    function ImageLoad(elem) {
        let idl = document.getElementById(elem);
        if (idl) idl.remove()
    }
    function RenderImage(elem, type) {
        if (type === 'thumb') {
            let id = elem.thumbnail.split('/'),
                last = id[id.length - 1].replace('.jpg_width=92',''),
                idL = 'ith_'+last
            return (
                <span className="image-gallery-thumbnail-inner">  
                    <div className="before-image" id={idL}><Loaders type="dots" color="b-red" /></div>
                        <img src={elem.thumbnail} className='image-gallery-thumbnail-image' loading="lazy" alt={elem.description} title={elem.description} 
                            onError={(e) => {ImageError(e.target); ImageLoad(idL, 'err')}}
                            onLoad={(e) => {ImageLoad(idL, 'load')}} 
                        />
                </span>
            )
        }
        else {
            let id = FullScreen ? elem.fullscreen.split('/') : elem.original.split('/'),
                last = id[id.length - 1].replace('.jpg_width=1200',''),
                idL = 'ig_'+last
            return (
                <>  
                    <div className="before-image" id={idL}><Loaders type="circle" mode="true" color="b-t" /></div>
                    <img src={FullScreen ? elem.fullscreen : elem.original} className='image-gallery-image' loading="lazy" alt={elem.description} title={elem.description} 
                        onError={(e) => {ImageError(e.target); ImageLoad(idL, 'err')}}
                        onLoad={(e) => {ImageLoad(idL, 'load')}} 
                    />
                </>
            )
        }
    }
    function RenderImageThumb(params) {
        const id = params.src.split('/');
        const last = id[id.length - 1].split('.jpg','')
        let idL = 'imth_'+last[0];
        let thumbnail = params.src
        if (window.origin.includes('localhost')) {
            thumbnail = 'https://dev-widzew.qrqs.eu' + thumbnail;
        }
        return (
            <>  
                <div className="before-image" id={idL}><Loaders type="circle" mode="true" color="b-t" /></div>
                <img src={thumbnail} className='thumbnail-home' loading="lazy" alt={params.alt} title={params.alt} 
                    onError={(e) => {ImageError(e.target); ImageLoad(idL, 'err')}}
                    onLoad={(e) => {ImageLoad(idL, 'load')}} 
                />
            </>
        )
    }
    if (showOnly) {
        return (
            <section className={innerSection}>
                <div className="wrapper">
                    <div className={innerContainer}>
                        { params.block['en']['showTitle'] && params.block['en']['showTitle'] !== "" &&
                            <h1>{params.block['en']['showTitle']}</h1>
                        }
                        {datas && <Items />}
                    </div>
                    <div className={'gallery-overlay ' + showGallery} onClick={() => renderGallerySlider()}></div> 
                    {gallery && 
                        <div id="gallery-div" className={'gallery-div container ' + showGallery}>
                            <ImageGallery items={gallery} showIndex={true} originalHeight={600} useBrowserFullscree="false"
                                renderItem={(elem) => (
                                    RenderImage(elem, 'item')
                                )}
                                renderThumbInner={(elem) => (
                                    RenderImage(elem, 'thumb')
                                )}
                                onScreenChange={(elem) => (
                                    setFullScreen(elem)
                                )}
                            />
                        </div>
                    }
                </div>
            </section>
        )
    } else {
        return (
            <section className={innerSection}>
                <div className="wrapper">
                    <div className={innerContainer}>
                        <div className="all-tags mt-20 mb-50">
                            <div className="tags f-wrap d-flex col-12 pt-20 pb-20 b-red">
                                <div className={"tags-item chosen-tags d-flex ai-center w-100 " + hiddenChooseTags}>
                                    <ChooseTags />
                                </div>
                                <div className="tags-item d-flex ai-center w-100">
                                    <Tags/>
                                </div>
                            </div>
                        </div>
                        <div className="all-galleries d-flex f-wrap mt-20 mb-20">
                            {datas && <Items />}
                        </div>
                    </div>
                    <div className={'gallery-overlay ' + showGallery} onClick={() => renderGallerySlider()}></div> 
                    {gallery && 
                        <div id="gallery-div" className={'gallery-div container ' + showGallery}>
                            <ImageGallery items={gallery} showIndex={true} originalHeight={600} useBrowserFullscree="false"
                                renderItem={(elem) => (
                                    RenderImage(elem, 'item')
                                )}
                                renderThumbInner={(elem) => (
                                    RenderImage(elem, 'thumb')
                                )}
                                onScreenChange={(elem) => (
                                    setFullScreen(elem)
                                )}
                            />
                        </div>
                    }
                </div>
            </section>
        )
    }
}

export default Galleries;