import React, { useState } from "react";
import Image from "../../components/Image";
import Links from "../../components/Links";
import NewsCarousel from "../newsCarousel/NewsCarousel"

function MembersDetails(params) {
    if (window.origin.includes('localhost')) {
        console.log('MembersDetails')
        console.log(params)
    }

    let [prevUrl, setPrevUrl] = useState("")
    let [hiddenPrev, setHiddenPrev] = useState("hidden")
    let [nextUrl, setNextUrl] = useState("")
    let [hiddenNext, setHiddenNext] = useState("hidden")
    let [currentUrl, setCurrentUrl] = useState("")

    let urlPlayers = "";
    let dataList = "";

    if (window.origin.includes('localhost')) {
        urlPlayers = 'https://dev-widzew.qrqs.eu/zespoly/druzyna/index.html.json'
    } else {
        urlPlayers = window.origin + '/zespoly/druzyna/index.html.json'
        if (params.line === 'offline') {
            switch (window.location.origin) {
                case 'https://dev-cms-widzew.qrqs.eu':
                    urlPlayers = 'https://dev-widzew.qrqs.eu/zespoly/druzyna/index.html.json'
                    break;
                case 'https://test-cms-widzew.qrqs.eu':
                    urlPlayers = 'https://test-widzew.qrqs.eu/zespoly/druzyna/index.html.json'
                    break;
                case 'https://cms-widzew.qrqs.eu/':
                    urlPlayers = 'https://widzew.com/zespoly/druzyna/index.html.json'
                    break;
                default:
                    break;
                }
        }
    }

    fetch(urlPlayers)
        .then(response => response.json())
        .then(data => {
            const arr = ["list", "list_"];

            var getAllKeys = Object.keys(data.linkedContents);
            arr.forEach(function (item) {
                getAllKeys.forEach(function (keyName) {
                    if (keyName.indexOf(item) !== -1) {
                        dataList = data.linkedContents[keyName].list
                    }
                })
            })

            if (dataList.length > 0) {
                
                dataList.sort(function(a, b){
                    return parseFloat(a['en']['Number']) - parseFloat(b['en']['Number'])
                });

                dataList.map((item, i) => {
                    if (item.link.includes('/sites/widzew')) {
                        item.link = item.link.split('/sites/widzew');
                        item.link = item.link[1];
                    }
                    if (window.location.pathname === item.link) {
                        setCurrentUrl(i);
                    }
                    return null;
                })
            }

            if (currentUrl !== "") {

                dataList.sort(function(a, b){
                    return parseFloat(a['en']['Number']) - parseFloat(b['en']['Number'])
                });

                dataList.map((item, i) => {
                    if (currentUrl === 0) {
                        setHiddenPrev("hidden");
                    } else if (i === currentUrl - 1) {
                        setHiddenPrev("");
                        setPrevUrl(item.link);
                    }

                    if (currentUrl === dataList.length - 1) {
                        setHiddenNext("hidden");
                    } else if (i === currentUrl + 1) {
                        setHiddenNext("");
                        setNextUrl(item.link);
                    }
                    return null;
                })
            }


        });

    let positionName = params.block['en'].Position;

    if (positionName === "br") {
        positionName = "BRAMKARZ"
    } else if (positionName === "def") {
        positionName = "OBROŃCA"
    } else if (positionName === "mid") {
        positionName = "POMOCNIK"
    } else {
        positionName = "NAPASTNIK"
    }

    let linkAboutPlayer = '';
    let tagPlayer = params.block['en']['Tags'];
    let i = tagPlayer.split('/')
    let tag = i[i.length - 2]

    if (params.line === 'offline') { 
        linkAboutPlayer = window.origin + '/aktualnosci/#' + tag;
    } else if (window.origin.includes('localhost')) {
        linkAboutPlayer = 'https://dev-widzew.qrqs.eu/aktualnosci/#' + tag;
    } else {
        linkAboutPlayer = window.origin + '/aktualnosci/#' + tag;
    }

    let birthday = new Date(params.block['en'].Birthday);
    birthday = new Intl.DateTimeFormat('pl-PL', { dateStyle: 'short' }).format(birthday)
    let debut = new Date(params.block['en'].DebutInWidzew);
    debut = new Intl.DateTimeFormat('pl-PL', { dateStyle: 'short' }).format(debut)

    let showDebut = debut.split(".");
    if (parseFloat(showDebut[2]) < 1900) {
        debut = "";
    }

    return (
        <section className="members-details">
            <div className="wrapper">
                <div className="carousel-members">
                    <div className="container-wide d-flex f-column jc-center b-red">
                        <div className="container player d-flex jc-evenly">
                            <div className={"slick-prev " + hiddenPrev} style={{ display: "block", width: "40px", height: "40px" }}>
                                <Links
                                    line={params.line}
                                    url={prevUrl}
                                    name={<svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"> <path fill="white" id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7 c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"></path></svg>} />
                            </div>
                            <div className="player-info d-flex">
                                <div className="p-20">
                                    <span className="number">
                                        {params.block['en'].Number}
                                    </span>
                                </div>
                                <div className="p-20">
                                    <span className="name">
                                        {params.block['en'].Name}<br />{params.block['en'].Surname}
                                    </span>
                                    <div className="social pt-20">
                                        {params.block['en'].UrlFacebook &&
                                            <a href={params.block['en'].UrlFacebook} className="pr-20" target="_blank" rel="noreferrer" title="facebook">
                                                <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m232.73 197.82v23.273h-23.273v34.909h23.273v104.73h46.545v-104.73h30.953l3.956-34.909h-34.909v-20.364c0-9.425 0.931-14.429 15.476-14.429h19.433v-35.026h-31.185c-37.236 1e-3 -50.269 17.455-50.269 46.546z"></path>
                                                    <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"></path>
                                                </svg>
                                            </a>
                                        }
                                        {params.block['en'].UrlTwitter &&
                                            <a href={params.block['en'].UrlTwitter} className="pr-20" target="_blank" rel="noreferrer" title="twitter">
                                                <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m377.6 166.4c-9.394 5.566-19.663 9.5-30.371 11.636-9.1-9.544-21.722-14.93-34.909-14.895-25.977-0.195-47.263 20.571-47.709 46.545 0.011 3.606 0.44 7.199 1.28 10.706-38.258-1.806-73.989-19.629-98.444-49.105-4.183 7.049-6.431 15.077-6.516 23.273 1e-3 15.803 8.02 30.525 21.295 39.098-7.581-0.247-14.997-2.28-21.644-5.935v0.582c-0.076 22.697 15.998 42.241 38.284 46.545-7.025 1.873-14.381 2.151-21.527 0.815 6.425 19.226 24.298 32.292 44.567 32.582-16.874 13.122-37.621 20.283-58.996 20.364-3.889-4e-3 -7.775-0.237-11.636-0.698 21.898 13.852 47.281 21.197 73.193 21.178 73.893 1.35 134.89-57.459 136.24-131.35 0.014-0.783 0.022-1.566 0.022-2.35v-6.051c9.12-6.684 16.997-14.915 23.273-24.32-8.736 3.825-17.99 6.334-27.462 7.447 9.961-5.831 17.451-15.1 21.06-26.065z"></path>
                                                    <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"></path>
                                                </svg>
                                            </a>
                                        }
                                        {params.block['en'].UrlInstagram &&
                                            <a href={params.block['en'].UrlInstagram} target="_blank" rel="noreferrer" title="instagram">
                                                <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"></path>
                                                    <path d="m325.82 139.64h-139.64c-25.706 0-46.545 20.839-46.545 46.545v139.64c0 25.706 20.839 46.545 46.545 46.545h139.64c25.706 0 46.545-20.839 46.545-46.545v-139.64c1e-3 -25.707-20.838-46.546-46.545-46.546zm-23.273 34.909h34.909v34.909h-34.909v-34.909zm-46.545 34.91c25.706 0 46.545 20.839 46.545 46.545s-20.839 46.545-46.545 46.545-46.545-20.839-46.545-46.545 20.839-46.545 46.545-46.545zm93.091 116.36c0 12.853-10.42 23.273-23.273 23.273h-139.64c-12.853 0-23.273-10.42-23.273-23.273v-81.455h24.436c-6.988 37.921 18.087 74.327 56.008 81.316s74.327-18.087 81.316-56.008c0.831-4.508 1.216-9.087 1.149-13.671-0.058-3.905-0.447-7.797-1.164-11.636h24.436v81.454z"></path>
                                                </svg>
                                            </a>
                                        }
                                    </div>
                                    {positionName &&
                                        <h3 className="pt-20">
                                            {positionName}
                                        </h3>
                                    }
                                    {params.block['en'].Matches && params.block['en'].Goals && params.block['en'].Assists &&
                                        <div className="stats d-flex pt-30">
                                            <div className="pr-20"><span className="label">Mecze</span><br /><span className="value">{params.block['en'].Matches}</span></div>
                                            <div className="pr-20"><span className="label">Bramki</span><br /><span className="value">{params.block['en'].Goals}</span></div>
                                            <div><span className="label">Asysty</span><br /><span className="value">{params.block['en'].Assists}</span></div>
                                        </div>
                                    }

                                    <div className="button-members pt-30 pb-30">
                                        {linkAboutPlayer &&
                                            <Links line={params.line} url={linkAboutPlayer} class="button empty-gold mr-20" name="MATERIAŁY O ZAWODNIKU" />
                                        }
                                        {params.block['en'].UrlBuyShirt &&
                                            <Links line={params.line} url={params.block['en'].UrlBuyShirt} target="true" class="button b-gold" name="KUP KOSZULKĘ" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="player-img d-flex ai-end pb-70">
                                {params.block['en'].ImageCelebration && params.block['en'].ImageCelebration.hasOwnProperty('path') &&
                                    <Image src={params.block['en'].ImageCelebration.path} altText={params.block['en'].Surname} />
                                }
                            </div>
                            <div className={"slick-next " + hiddenNext} style={{ display: "block", width: "40px", height: "40px" }}>
                                <Links
                                    line={params.line}
                                    url={nextUrl}
                                    name={<svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}><path fill="white" id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7 c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"></path></svg>} />
                            </div>
                        </div>
                        <div className="container-wide title-box">
                            <div className="container d-flex ai-end pb-0 b-white">
                                <h1>Profil zawodnika</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container d-flex f-column jc-center">
                    <div className="d-flex b-white player-description">
                        {params.block['en'].AboutPlayer &&
                            <div
                                className="about pr-50"
                                dangerouslySetInnerHTML={
                                    { __html: params.block['en'].AboutPlayer }
                                }
                            >
                            </div>
                        }
                        <div className="mini-stats d-flex f-wrap b-gold p-20">
                            {birthday !== "" &&
                            <span>
                                <span className="label">
                                    Data urodzenia
                                </span><br />
                                <span className="value">
                                    {birthday}
                                </span>
                            </span>
                            }
                            {params.block['en'].Nationality &&
                            <span>
                                <span className="label">
                                    Narodowość
                                </span><br />
                                <span className="value">
                                    {params.block['en'].Nationality}
                                </span>
                            </span>
                            }
                            {params.block['en'].Height &&
                            <span>
                                <span className="label">
                                    Wzrost
                                </span><br />
                                <span className="value">
                                    {params.block['en'].Height} <span className="mini-tip">CM</span>
                                </span>
                            </span>
                            }
                            {params.block['en'].Weight && 
                                <span>
                                    <span className="label">
                                        Waga
                                    </span><br />
                                    <span className="value">
                                        {params.block['en'].Weight} <span className="mini-tip">KG</span>
                                    </span>
                                </span>
                            }
                            {debut !== "" && 
                                <span>
                                    <span className="label">
                                        Debiut w Widzewie
                                    </span><br />
                                    <span className="value">
                                        {debut}
                                    </span>
                                </span>
                            }
                        </div>
                    </div>
                </div>
                {params.block['en']['Tags'] && params.block['en']['Tags'] !== "" &&
                    <div class="b-white">
                        <NewsCarousel block={{en: {"Tags": params.block['en']['Tags'], "Title": "Materiały o zawodniku", "ShowTitle": true} }} />
                    </div>
                }
            </div>
        </section>
    );
}

export default MembersDetails;