import React from "react";

function RemoveLoader() {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
        console.log('Reomve Loader')
    }	
    setTimeout(function(){ 
        var loader = document.getElementById('loader');
        if (loader) {
            loader.remove();
        }
    }, 1);
};
export default RemoveLoader;