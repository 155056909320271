import React, { useState, useEffect } from 'react'
import Axios from 'axios';

import Image from '../../components/Image';
import Links from '../../components/Links';
import GamePlayTableItem from './GamePlayTableItem';

const GameplayTable = (params) => {
  const [tableData, setTableData] = useState([]);
  let url;
  let imageUrl;
  if (params.line === 'offline') {
    imageUrl = params.block['en']['LeagueTableId'] ? `${generateEnvHostnameCMS()}/images/tables/${params.block['en']['LeagueTableId']}/` : ''
    url = params.block['en']['LeagueTableId'] ? `${generateEnvHostnameCMS()}/api/tables/${params.block['en']['LeagueTableId']}/teams.json` : '';
  } else if (window.origin.includes('localhost')) {
    imageUrl = params.block['en']['LeagueTableId'] ? `https://dev-widzew.qrqs.eu/images/tables/${params.block['en']['LeagueTableId']}/` : ''
    url = params.block['en']['LeagueTableId'] ? `https://dev-widzew.qrqs.eu/api/tables/${params.block['en']['LeagueTableId']}/teams.json` : '';
  } else {
    imageUrl = params.block['en']['LeagueTableId'] ? `${window.origin}/images/tables/${params.block['en']['LeagueTableId']}/` : ''
    url = params.block['en']['LeagueTableId'] ? `${window.origin}/api/tables/${params.block['en']['LeagueTableId']}/teams.json` : '';
  }
  // const imageUrl = params.block['en']['LeagueTableId'] ? `https://dev-widzew.qrqs.eu/images/tables/${params.block['en']['LeagueTableId']}/` : ''
  // const url = params.block['en']['LeagueTableId'] ? `https://dev-widzew.qrqs.eu/api/tables/${params.block['en']['LeagueTableId']}/teams.json` : '';

  function generateEnvHostnameCMS() {
    let hostname = ''
    switch (window.location.origin) {
      case 'https://dev-cms-widzew.qrqs.eu':
        hostname = 'https://dev-widzew.qrqs.eu'
          break;
      case 'https://test-cms-widzew.qrqs.eu':
        hostname = 'https://test-widzew.qrqs.eu'
          break;
      case 'https://cms-widzew.qrqs.eu':
        hostname = 'https://widzew.com'
          break;
      default:
          break;
    }
    return hostname
  }

  useEffect(() => {
    let fetchTable = async () => {
      let response = '';
      try {
        response = await Axios(url);
        let sortedByRank = response.data.sort((a, b) => a.rank - b.rank)
        setTableData(sortedByRank);
      } catch (err) {
        response = "error";
      }
    }
    fetchTable();
  }, [url])


  let colorBack = ''
  if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
    colorBack = 'b-' + params.block['en']['Background']
  }
  let innerSection = 'gameplay-table';
  let innerContainer = 'container';
  if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
    if (params.block['en']['InnerBackground'] === true) {
      innerContainer = innerContainer + ' ' + colorBack;
    }
    else {
      innerSection = innerSection + ' ' + colorBack;
    }
  }

  function generateCMSLink() {
    let url = '';
    url = window.origin + '/nodejs-api/Scoreboard';
    if (window.origin.includes('localhost')) {
      url = 'https://dev-cms-widzew.qrqs.eu/nodejs-api/Scoreboard';
    }
    return url
  }


  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          <div className="d-flex jc-between mb-20">
            {params.block['en']['Title'] &&
              <h3>
                {params.block['en']['Title']}
              </h3>
            }
            {params.block['en']['Logo'] &&
              <Image src={params.block['en']['Logo']['path']} alt={params.block['en']['AltText']} />
            }
          </div>
          <div className="gameplay-table-wrapper">
            {params.line === 'offline' &&
              <Links line={params.line} url={generateCMSLink()} target="false" class="button empty-gold" name="Zarządzaj tabelą" />
            }
            <table id="gameplay-table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>M</th>
                  <th className="mob-h">Z</th>
                  <th className="mob-h">R</th>
                  <th className="mob-h">P</th>
                  <th className="mob-h">BZ</th>
                  <th className="mob-h">BS</th>
                  <th>+/-</th>
                  <th>PKT</th>
                </tr>
              </thead>
              <tbody>
                {tableData ? tableData.map((item, index) => (
                  <GamePlayTableItem key={index} item={item} imgUrl={imageUrl} />
                )) : null}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </section>
  )
}

export default GameplayTable