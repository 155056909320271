import React, { useEffect } from "react";

const ElfsightVideos = (params) => {

  let colorBack = ''
  if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
    colorBack = 'b-' + params.block['en']['Background']
  }
  let innerSection = 'elfsight-videos';
  let innerContainer = 'container';
  if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
    if (params.block['en']['InnerBackground'] === true) {
      innerContainer = innerContainer + ' ' + colorBack;
    }
    else {
      innerSection = innerSection + ' ' + colorBack;
    }
  }

  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          <div className="d-flex pb-30 jc-between ai-center">
            <>
            {params.block['en']['Title'] &&
              <h1>{params.block['en']['Title']}</h1>
            }
            </>
            <div className="social">
            {params.block['en'].FacebookUrl.link !== undefined &&
                <a href={params.block['en'].FacebookUrl.link} className="pl-20" target="_blank" rel="noreferrer" title="facebook">
                    <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m232.73 197.82v23.273h-23.273v34.909h23.273v104.73h46.545v-104.73h30.953l3.956-34.909h-34.909v-20.364c0-9.425 0.931-14.429 15.476-14.429h19.433v-35.026h-31.185c-37.236 1e-3 -50.269 17.455-50.269 46.546z"></path>
                        <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"></path>
                    </svg>
                </a>
            }
            {params.block['en'].TwitterUrl.link !== undefined &&
                <a href={params.block['en'].TwitterUrl.link} className="pl-20" target="_blank" rel="noreferrer" title="twitter">
                    <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m377.6 166.4c-9.394 5.566-19.663 9.5-30.371 11.636-9.1-9.544-21.722-14.93-34.909-14.895-25.977-0.195-47.263 20.571-47.709 46.545 0.011 3.606 0.44 7.199 1.28 10.706-38.258-1.806-73.989-19.629-98.444-49.105-4.183 7.049-6.431 15.077-6.516 23.273 1e-3 15.803 8.02 30.525 21.295 39.098-7.581-0.247-14.997-2.28-21.644-5.935v0.582c-0.076 22.697 15.998 42.241 38.284 46.545-7.025 1.873-14.381 2.151-21.527 0.815 6.425 19.226 24.298 32.292 44.567 32.582-16.874 13.122-37.621 20.283-58.996 20.364-3.889-4e-3 -7.775-0.237-11.636-0.698 21.898 13.852 47.281 21.197 73.193 21.178 73.893 1.35 134.89-57.459 136.24-131.35 0.014-0.783 0.022-1.566 0.022-2.35v-6.051c9.12-6.684 16.997-14.915 23.273-24.32-8.736 3.825-17.99 6.334-27.462 7.447 9.961-5.831 17.451-15.1 21.06-26.065z"></path>
                        <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"></path>
                    </svg>
                </a>
            }
            {params.block['en'].InstagramUrl.link !== undefined &&
                <a href={params.block['en'].InstagramUrl.link} className="pl-20" target="_blank" rel="noreferrer" title="instagram">
                    <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"></path>
                        <path d="m325.82 139.64h-139.64c-25.706 0-46.545 20.839-46.545 46.545v139.64c0 25.706 20.839 46.545 46.545 46.545h139.64c25.706 0 46.545-20.839 46.545-46.545v-139.64c1e-3 -25.707-20.838-46.546-46.545-46.546zm-23.273 34.909h34.909v34.909h-34.909v-34.909zm-46.545 34.91c25.706 0 46.545 20.839 46.545 46.545s-20.839 46.545-46.545 46.545-46.545-20.839-46.545-46.545 20.839-46.545 46.545-46.545zm93.091 116.36c0 12.853-10.42 23.273-23.273 23.273h-139.64c-12.853 0-23.273-10.42-23.273-23.273v-81.455h24.436c-6.988 37.921 18.087 74.327 56.008 81.316s74.327-18.087 81.316-56.008c0.831-4.508 1.216-9.087 1.149-13.671-0.058-3.905-0.447-7.797-1.164-11.636h24.436v81.454z"></path>
                    </svg>
                </a>
            }
            {params.block['en'].TiktokUrl.link !== undefined &&
              <a href={params.block['en'].TiktokUrl.link} className="pl-20" target="_blank" rel="noreferrer" title="tiktok">
                <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M346,205.1c-0.2,0-18.8-0.4-34.7-12.1c-13.9-10.2-18.6-32.4-19.4-40.6v-1.1h-33.7v146.6c0,17.6-14.2,32-31.7,32
                      s-31.7-14.3-31.7-32S209,266,226.4,266h10.7v-34h-10.7c-36.1,0-65.5,29.6-65.5,65.9s29.4,65.9,65.5,65.9s65.5-29.6,65.5-65.9
                      v-77.1c24.5,17.7,51,18.3,54,18.3L346,205.1L346,205.1z"></path>
                    <path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M256,488.7
                      C127.5,488.7,23.3,384.5,23.3,256S127.5,23.3,256,23.3S488.7,127.5,488.7,256S384.5,488.7,256,488.7z"></path>
                </svg>
              </a>
            }
            {params.block['en'].YoutubeUrl.link !== undefined &&
              <a href={params.block['en'].YoutubeUrl.link} className="pl-20" target="_blank" rel="noreferrer" title="tiktok">
                <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="m245.76 208.64h4.771c10.559 0 19.136-8.525 19.2-19.084v-31.069c-0.189-10.422-8.66-18.789-19.084-18.851h-4.771c-10.559 0-19.136 8.525-19.2 19.084v31.069c0.19 10.422 8.66 18.79 19.084 18.851zm-4.771-51.316c3e-3 -3.984 3.235-7.213 7.219-7.21 3.981 3e-3 7.208 3.229 7.21 7.21v33.396c-3e-3 3.984-3.235 7.213-7.219 7.21-3.981-3e-3 -7.207-3.229-7.21-7.21v-33.396z"/>
                <polygon points="189.09 207.48 205.96 207.48 205.96 170.36 225.16 117.76 209.46 117.76 197.82 152.67 186.18 117.76 168.84 117.76 189.32 170.36"/>
                <path d="m293.82 208.64c6.792-0.653 13.156-3.61 18.036-8.378v7.215h13.964v-67.84h-13.964v52.596s-4.771 5.585-9.309 5.585-4.771-3.607-4.771-3.607v-54.575h-14.778v59.695c0.807 5.354 5.408 9.312 10.822 9.309z"/>
                <path d="m284.28 286.6c-2.804 0.137-5.422 1.446-7.215 3.607v48.175c1.841 2.036 4.47 3.181 7.215 3.142 7.215 0 7.215-8.029 7.215-8.029v-38.865c0-1e-3 -1.28-8.03-7.215-8.03z"/>
                <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"/>
                <path d="m339.55 286.6c-7.215 0-7.215 8.029-7.215 8.029v10.356h14.429v-10.356c1e-3 0 1e-3 -8.029-7.214-8.029z"/>
                <path d="m344.93 223.52c-0.202 3e-3 -0.404 8e-3 -0.606 0.015h-0.233s-43.869-2.444-88.087-2.444-88.32 2.327-88.32 2.327c-19.269-0.643-35.411 14.457-36.053 33.726-0.013 0.394-0.02 0.789-0.019 1.183-2.222 14.638-3.428 29.413-3.607 44.218 0.164 14.96 1.369 29.891 3.607 44.684-0.011 19.28 15.61 34.918 34.89 34.928 0.394 0 0.789-6e-3 1.183-0.019 0 0 43.404 2.327 88.32 2.327s88.32-2.327 88.32-2.327c19.269 0.643 35.411-14.457 36.053-33.726 0.013-0.394 0.02-0.789 0.019-1.183 2.227-14.794 3.432-29.724 3.607-44.684-0.147-14.961-1.353-29.893-3.607-44.684-0.311-19.276-16.19-34.652-35.467-34.341zm-141.17 38.996h-17.571v90.531h-17.338v-90.531h-17.571v-14.895h52.829l-0.349 14.895zm45.731 90.531h-14.545v-6.865c-4.95 4.61-11.298 7.436-18.036 8.029-5.413 0.113-10.058-3.833-10.822-9.193v-71.098h15.011v66.444s0 3.491 4.771 3.491 9.658-5.702 9.658-5.702v-64.233h14.429l-0.466 79.127zm43.171 1.164c-6.121 0.055-11.906-2.794-15.593-7.68v6.516h-15.709v-105.42h15.593v34.211c3.991-4.521 9.581-7.316 15.593-7.796 9.658 0 13.265 8.029 13.265 18.385l0.116 43.404s0 18.385-13.265 18.385zm69.701-37.469h-30.022v17.222s0 8.029 7.215 8.029 7.215-8.029 7.215-8.029v-8.146h15.593v12.567c-2.087 10.074-11.397 16.982-21.644 16.058-10.614 0.956-20.399-5.796-23.273-16.058v-44.916c0.324-11.178 9.647-19.977 20.825-19.653 0.82 0.024 1.637 0.097 2.448 0.22 10.37-1.484 19.979 5.719 21.464 16.088 0.159 1.107 0.219 2.227 0.18 3.345v23.273z"/>
                </svg>
              </a>
            }
            </div>
          </div>
          {params.block['en']['ElfsightCode'] &&
            <div className="elfisght-videos-content"><div className={params.block['en']['ElfsightCode']}></div></div>}
        </div>
      </div>
    </section>
  )
}

export default ElfsightVideos