import React from "react";

function Func() {
  
  function toggleFix() {
    if (window.scrollY !== 'undefined') {
      if ((window.scrollY < 50) && document.body.classList.contains('fix-menu')) {
        document.body.classList.remove("fix-menu");
      } 
      if ((window.scrollY > 50) && !document.body.classList.contains('fix-menu')) {
        document.body.classList.add("fix-menu");
      }
    }
  };

  // function allowing just one audio player playing at same time (have to improve nav react player)
  if (window.innerWidth > 1024)  window.addEventListener("scroll", toggleFix);

  document.addEventListener("play", function (d) {
    let c = document.getElementsByTagName("audio");
    for (let b = 0, a = c.length; b < a; b++) {
      if (c[b] !== d.target) {
        c[b].pause()
      }
    }
  }, true);
  
  //add class by device
  /*const { device } = useWindowDimensions();
  if (!document.body.classList.contains(device)) {
    if (document.body.classList.contains('mobile')) {
      document.body.classList.remove('mobile');
    }
    else if (document.body.classList.contains('tablet')) {
      document.body.classList.remove('tablet');
    }
    else if (document.body.classList.contains('desktop')) {
      document.body.classList.remove('desktop');
    }
    document.body.classList.add(device);
  }*/

};
export default Func;