import React from "react";
import ShirtCreatorItem from './ShirtCreatorItem';

function ShirtCreator(params) {
    if (window.origin.includes('localhost') || window.origin.includes('cms-')) {
        console.log('ShirtCreator')
        console.log(params)
    }

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }

    let innerSection = 'shirt-creator';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <h1 className="d-flex f-column jc-center">{params.block['en']['Title']}</h1>
                    <div className="d-flex jc-between pt-30">
                        <ShirtCreatorItem data={params.block['en']['SchemaItem']} />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ShirtCreator;