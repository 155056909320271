import React from "react";
import { useState } from "react";
import Image from "../../components/Image.js";
import Modal from "../modalBox/Modal.js";
import Links from "../../components/Links";

function ImageBoxItem(params) {
    const [show, setShow] = useState(false);
    
    let image = params.block.hasOwnProperty('Image') ? params.block['Image'] : '/.templates/img/logo/herbwidzewa.jpg',
        altText = params.block.hasOwnProperty('AltText') ? (params.block['AltText'] !== '' ? params.block['AltText'] : 'Widzew Łódź') : 'Widzew Łódź',
        newsletter = params.block.hasOwnProperty('IsNewsletter') ? params.block['IsNewsletter'] : false,
        link = params.block.hasOwnProperty('Link') ? (params.block['Link'] !== '' ? params.block['Link'] : false) : false,
        newTab = params.block.hasOwnProperty('NewTab') ? (params.block['NewTab'] ? 'true' : 'false') : 'false';
    
    if (newsletter || newsletter === 'true') {
        return (
            <div className="modal-click" onClick={() => setShow(true)} title={altText}>
                <Image src={image} alt={altText} />
                <Modal onClose={() => setShow(false)} show={show} setShow={setShow} setFormBox={true} title="Zapisz się do newslettera" />
            </div>
        )
    }
    else {
        return (
            <div className="img-item">
                {link &&
                    <>
                        <Links line={params.line} url={link} class="all-link" name={altText} target={newTab}/>
                        <Image
                            src={image} alt={altText}
                        />
                    </>
                }
                {!link &&
                    <Image
                        src={image} alt={altText}
                    />
                }
            </div>
        )
    }
}

export default ImageBoxItem;