import React from "react";
import Image from "../../components/Image.js";


function Bio(params) {

    return (
        <div className="management-item pr-15 col-box">
            <div direction="column" className="d-flex f-row">
                <div className="management-information jc-between d-flex f-row">
                    <div className="image-box">
                        {params.ImageModal &&

                            <figure>
                                <Image src={params.ImageModal} />
                            </figure>
                        }
                    </div>
                    <div className="d-flex f-column ml-15 info-text-box">
                        <h1 className="above-text mb-10">{params.Text}</h1>
                        <h3 className="under-text mb-10" >{params.underText}</h3>
                        <h5 className="text-about" style={{ fontWeight: "lighter", fontFamily: 'Rajdhani Medium', textTransform: 'none', textAlign: 'start', letterSpacing: '2px', lineHeight: '3rem' }}
                            dangerouslySetInnerHTML={
                                { __html: params.textAbout }
                            }
                        >
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Bio;


