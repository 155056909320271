import React, { useState } from "react";
import Image from "../../components/Image.js";
import '../../.templates/css/portlets/managementBox/ManagementBox.css';
import Modal from "../modalBox/ModalManagement";

function ManagementItem(params) {
    const [show, setShow] = useState(false);
    let hello = params;

    function checkIfEmail(link) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(link)) {
            return (
                <div className="modal-click ml-15 mb-15">
                    <a href={`mailto:${params.Link.link}`} className="small-mail">
                        {params.LinkName}
                    </a>
                </div>
            )
        } else {
            return (
                <div className="modal-click js-end" onClick={() => setShow(true)}>
                    <a className="mr-30">
                        {params.LinkName}
                    </a>
                    <Modal
                        item={hello}
                        onClose={() => setShow(false)}
                        show={show}
                        setShow={setShow}
                        setFormBox={true}
                    />
                </div>
            )
        }
    }
    return (

        <div className="management-item pr-15 col-box">
            <div direction="column" className="image-item d-flex f-row">
                {params.image &&
                    <Image src={`${params.image}_width=350`} altText={params.altText} />
                }
                <div className="management-information jc-between d-flex f-column">
                    <div className="d-flex f-column ml-15">
                        <h2 className="above-text mb-20">{params.Text}</h2>
                        <h3 className="under-text">{params.underText}</h3>
                    </div>
                    {params.LinkName && 
                        checkIfEmail(params.Link?.link)
                    }
                </div>
            </div>
        </div>
    )
}





export default ManagementItem;