import React, { useState, useEffect } from "react";
import axios from "axios";
import Image from "../../components/Image";
import Links from "../../components/Links";
import Slider from "react-slick";

const MembersCarouselItem = ({params}) => {

    const nextArrow = `<svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 128 128" x="0px" y="0px"xmlns="http://www.w3.org/2000/svg"><path id="Right_Arrow_4_" d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>`;
    const prevArrow = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><path id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7 c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"/></svg>`;
    const NextArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          onClick={onClick}
          style={{ ...style, display: "block", width: '30px', height: '30px' }}
          dangerouslySetInnerHTML={
            { __html: nextArrow }
          }
        >
        </div>
      );
    }
  
    const PrevArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          onClick={onClick}
          style={{ ...style, display: "block", width: '30px', height: '30px' }}
          dangerouslySetInnerHTML={
            { __html: prevArrow }
          }
        >
        </div>
      );
    }
  
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      fade: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
    };

    const   [allPlayers, setAllPlayers] = useState(new Array()),
            [allPlayersImg, setAllPlayersImg] = useState(new Array()),
            [allPlayersUrl, setAllPlayersUrl] = useState(new Array()),
            [allPlayersWithImg, setAllPlayersWithImg] = useState(new Array());
    let urlPlayers = "",
        dataList = "";
    
    if (window.origin.includes('localhost')) {
        urlPlayers = 'https://dev-widzew.qrqs.eu/zespoly/druzyna/index.html.json'
    } else {
        urlPlayers = window.origin + '/zespoly/druzyna/index.html.json'
        if (params.line === 'offline') {
            switch (window.location.origin) {
                case 'https://dev-cms-widzew.qrqs.eu':
                    urlPlayers = 'https://dev-widzew.qrqs.eu/zespoly/druzyna/index.html.json'
                    break;
                case 'https://test-cms-widzew.qrqs.eu':
                    urlPlayers = 'https://test-widzew.qrqs.eu/zespoly/druzyna/index.html.json'
                    break;
                case 'https://cms-widzew.qrqs.eu/':
                    urlPlayers = 'https://widzew.com/zespoly/druzyna/index.html.json'
                    break;
                default:
                    break;
                }
        }
    }

    useEffect(() => {
        axios
          .get(
            urlPlayers
          )
          .then(({ data }) => {
            const arr = ["list", "list_"];

            var getAllKeys = Object.keys(data.linkedContents);
            arr.forEach(function (item) {
                getAllKeys.forEach(function (keyName) {
                    if (keyName.indexOf(item) !== -1) {
                        dataList = data.linkedContents[keyName].list
                    }
                })
            })

            if (dataList.length > 0) {
                dataList.sort(function(a, b){
                    return parseFloat(a['en']['Number']) - parseFloat(b['en']['Number'])
                });

                dataList.map((item, i) => {
                    setAllPlayers(allPlayers, allPlayers.push(item['en']));
                    setAllPlayersImg(allPlayersImg, allPlayersImg.push(item['en']['ImageFront']))
                    setAllPlayersUrl(allPlayersUrl, allPlayersUrl.push(item.link))
                })
            }

            const allPlayersLength = allPlayers.length - 1;
            allPlayers.map((item, i) => {
                if (i === 0) {
                    setAllPlayersWithImg(allPlayersWithImg, allPlayersWithImg.push({item, 'urlDetails': allPlayersUrl[i], 'prevImg': allPlayersImg[allPlayersLength], 'nextImg': allPlayersImg[i+1]}))
                } else if (i === allPlayersLength) { 
                    //ignore last one
                } else {
                    setAllPlayersWithImg(allPlayersWithImg, allPlayersWithImg.push({item, 'urlDetails': allPlayersUrl[i], 'prevImg': allPlayersImg[i-1], 'nextImg': allPlayersImg[i+1]}))
                }
            })

            let lastOne = allPlayers[allPlayersLength],
                lastOneUrl = allPlayersUrl[allPlayersLength],
                beforeLastOne = allPlayersImg[allPlayersLength-1],
                firstOne = allPlayersImg[0];
            setAllPlayersWithImg(allPlayersWithImg, allPlayersWithImg.push({item: lastOne, 'urlDetails': lastOneUrl, 'prevImg': beforeLastOne, 'nextImg': firstOne}))            
            setAllPlayersWithImg([...allPlayersWithImg]);
        });
    }, [dataList]);   

    return (  
        <Slider {...settings}>
            {allPlayersWithImg && allPlayersWithImg.map((item, index) => (
                <div className="item" key={'zawodnik-'+index}>
                    {item.prevImg &&
                        <div className="member-img prev d-flex ai-end jc-center">
                            <Image src={item.prevImg.path} alt="poprzedni zawodnik" />
                        </div>
                    }
                    {item.item.ImageFront &&
                        <div className="member-img current d-flex">
                            <div className="img">
                                <Image src={item.item.ImageFront.path} alt="aktualny zawodnik" />
                                <span className="number">
                                    {item.item.Number}
                                </span>
                            </div>
                            <div className="about pt-10">
                                <div className="player-info d-flex">
                                    <div className="p-20">
                                        <span className="name">
                                            {item.item.Name}<br />{item.item.Surname}
                                        </span>
                                        <div className="social pt-10">
                                            {item.item.UrlFacebook &&
                                                <a href={item.item.UrlFacebook} className="pr-20" target="_blank" rel="noreferrer" title="facebook">
                                                    <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m232.73 197.82v23.273h-23.273v34.909h23.273v104.73h46.545v-104.73h30.953l3.956-34.909h-34.909v-20.364c0-9.425 0.931-14.429 15.476-14.429h19.433v-35.026h-31.185c-37.236 1e-3 -50.269 17.455-50.269 46.546z"></path>
                                                        <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"></path>
                                                    </svg>
                                                </a>
                                            }
                                            {item.item.UrlTwitter &&
                                                <a href={item.item.UrlTwitter} className="pr-20" target="_blank" rel="noreferrer" title="twitter">
                                                    <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m377.6 166.4c-9.394 5.566-19.663 9.5-30.371 11.636-9.1-9.544-21.722-14.93-34.909-14.895-25.977-0.195-47.263 20.571-47.709 46.545 0.011 3.606 0.44 7.199 1.28 10.706-38.258-1.806-73.989-19.629-98.444-49.105-4.183 7.049-6.431 15.077-6.516 23.273 1e-3 15.803 8.02 30.525 21.295 39.098-7.581-0.247-14.997-2.28-21.644-5.935v0.582c-0.076 22.697 15.998 42.241 38.284 46.545-7.025 1.873-14.381 2.151-21.527 0.815 6.425 19.226 24.298 32.292 44.567 32.582-16.874 13.122-37.621 20.283-58.996 20.364-3.889-4e-3 -7.775-0.237-11.636-0.698 21.898 13.852 47.281 21.197 73.193 21.178 73.893 1.35 134.89-57.459 136.24-131.35 0.014-0.783 0.022-1.566 0.022-2.35v-6.051c9.12-6.684 16.997-14.915 23.273-24.32-8.736 3.825-17.99 6.334-27.462 7.447 9.961-5.831 17.451-15.1 21.06-26.065z"></path>
                                                        <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"></path>
                                                    </svg>
                                                </a>
                                            }
                                            {item.item.UrlInstagram &&
                                                <a href={item.item.UrlInstagram} target="_blank" rel="noreferrer" title="instagram">
                                                    <svg enableBackground="new 0 0 512 512" fill="#fff" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 488.73c-128.53 0-232.73-104.2-232.73-232.73s104.2-232.73 232.73-232.73 232.73 104.2 232.73 232.73-104.2 232.73-232.73 232.73z"></path>
                                                        <path d="m325.82 139.64h-139.64c-25.706 0-46.545 20.839-46.545 46.545v139.64c0 25.706 20.839 46.545 46.545 46.545h139.64c25.706 0 46.545-20.839 46.545-46.545v-139.64c1e-3 -25.707-20.838-46.546-46.545-46.546zm-23.273 34.909h34.909v34.909h-34.909v-34.909zm-46.545 34.91c25.706 0 46.545 20.839 46.545 46.545s-20.839 46.545-46.545 46.545-46.545-20.839-46.545-46.545 20.839-46.545 46.545-46.545zm93.091 116.36c0 12.853-10.42 23.273-23.273 23.273h-139.64c-12.853 0-23.273-10.42-23.273-23.273v-81.455h24.436c-6.988 37.921 18.087 74.327 56.008 81.316s74.327-18.087 81.316-56.008c0.831-4.508 1.216-9.087 1.149-13.671-0.058-3.905-0.447-7.797-1.164-11.636h24.436v81.454z"></path>
                                                    </svg>
                                                </a>
                                            }
                                        </div>
                                        <h3 className="pt-10">
                                            {item.item.Position === "br" && "BRAMKARZ"}
                                            {item.item.Position === "def" && "OBROŃCA"}
                                            {item.item.Position === "mid" && "POMOCNIK"}
                                            {item.item.Position === "forward" && "NAPASTNIK"}
                                        </h3>
                                        {item.item.Matches && item.item.Goals && item.item.Assists &&
                                            <div className="stats d-flex pt-10">
                                                <div className="pr-20"><span className="label">Mecze</span><br /><span className="value">{item.item.Matches}</span></div>
                                                <div className="pr-20"><span className="label">Bramki</span><br /><span className="value">{item.item.Goals}</span></div>
                                                <div><span className="label">Asysty</span><br /><span className="value">{item.item.Assists}</span></div>
                                            </div>
                                        }

                                        <div className="button-members pt-10 pb-10">
                                            {item.urlDetails &&
                                                <Links line={params.line} url={item.urlDetails} class="button empty-gold mb-10" name="PROFIL ZAWODNIKA" />
                                            }
                                            {item.item.UrlBuyShirt &&
                                                <Links line={params.line} url={item.item.UrlBuyShirt} target="true" class="button b-gold" name="KUP KOSZULKĘ" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {item.nextImg &&
                        <div className="member-img next d-flex ai-end jc-center">
                            <Image src={item.nextImg.path} altText="następny zawodnik" />
                        </div>
                    }
                </div>
            ))}
        </Slider>
    )

}

export default MembersCarouselItem