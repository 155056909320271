import React from 'react'

const IframeScriptIncluder = (params) => {
  console.log(params)
  // params.block['en'].ScriptIframeCode = ''

  let colorBack = ''
  if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
    colorBack = 'b-' + params.block['en']['Background']
  }
  let innerSection = 'iframe-script-includer';
  let innerContainer = 'container';
  if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
    if (params.block['en']['InnerBackground'] === true) {
      innerContainer = innerContainer + ' ' + colorBack;
    }
    else {
      innerSection = innerSection + ' ' + colorBack;
    }
  }

  return (
    <>
      {params.block['en']?.ScriptIframeCode && params.block['en']?.ScriptIframeCode !== '' &&
        <section className={innerSection}>
          <div className="wrapper">
            <div className={innerContainer}>
              <div className="iframe-container"
                dangerouslySetInnerHTML={
                  { __html: params.block['en'].ScriptIframeCode }
                }
              ></div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default IframeScriptIncluder