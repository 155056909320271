import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import TimetableCarouselItem from './TimetableCarouselItem';
import Links from '../../components/Links';

const TimetableCarousel = ({ item, params }) => {
  let finishedArray = [],
      notFinishedArray = [];
      
  if (window.origin.includes('localhost')) {
    console.log('TimetableCarousel')
    console.log(item)
  }

  const nextArrow = `
    <svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 128 128" x="0px" y="0px"xmlns="http://www.w3.org/2000/svg"><path fill="#FFFFFF" id="Right_Arrow_4_" d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>
  `;
  const prevArrow = `
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <path fill="#FFFFFF" id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7
      c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"
      />
    </svg>
  `;

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style, display: "block", width: '30px', height: '30px' }}
        dangerouslySetInnerHTML={
          { __html: nextArrow }
        }
      >
      </div>
    );
  }

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style, display: "block", width: '30px', height: '30px' }}
        dangerouslySetInnerHTML={
          { __html: prevArrow }
        }
      >
      </div>
    );
  }

  let settings = {
    dots: false,
    infinite: false,
    cssEase: 'ease-out',
    variableWidth: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    initialSlide: window.innerWidth > 768 ? 0 : 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  function sortJson(list) {
    list.sort((a, b) => (a.en.DateOfGame > b.en.DateOfGame) ? 1 : -1)
    list.map(item => {
      if (item.en.IsFinished === true) {
        finishedArray.push(item);
      } else if (item.en.IsFinished === false) {
        notFinishedArray.push(item);
      }
      return null
    })
    finishedArray.sort((a, b) => (a.en.DateOfGame < b.en.DateOfGame) ? 1 : -1)
    if (finishedArray.length > 0) {
      notFinishedArray.unshift(finishedArray[0])
    }
  }
  sortJson(item);
  function countdownValue() {
    let timestamp = null;
    if (finishedArray.length > 0) {
      if (notFinishedArray.length > 1) {
        timestamp = notFinishedArray[1]['en'].DateOfGame - Date.now();
      }
      else {
        //timestamp = notFinishedArray[0]['en'].DateOfGame - Date.now();
      }
    } else {
      timestamp = notFinishedArray[0]['en'].DateOfGame - Date.now();
    }
    return timestamp
  }
  return (
    <div className="timetable-carousel">
      <div className="timetable-carousel-header d-flex ai-start mb-30">
        <div className="first-squad d-flex f-column">
          <span className="mb-10">Mecze pierwszej drużyny</span>
          <div className="d-flex">
            <Links url="/rozgrywki/tabela-rozgrywek/" target="false" class="c-gold mr-10" name="Tabela" />
            <Links url="/rozgrywki/terminarz-i-wyniki/" target="false" class="c-gold hp-terminarz" name="Terminarz i wyniki" />
          </div>
        </div>
        <div className="timer-container d-flex f-column">
          {Date.now() < Date.now() + countdownValue() && 
            <>
              <div className="mb-6">Do następnego meczu pozostało</div>
              <Countdown date={Date.now() + countdownValue()} />
            </>
          }
        </div>
      </div>
      <div className={'timetable-carousel-wrapper' + (notFinishedArray.length === 1 ? ' d-flex last-game' : '')}>
        {(notFinishedArray.length > 0 && notFinishedArray[0] !== undefined) ? <Slider {...settings}>
          {notFinishedArray.map((i, index) => (
            <TimetableCarouselItem
              key={index}
              item={i}
              line={params.line}
              index={index}
            />
          ))}
        </Slider> : <h3 className="c-white pl-10">Brak meczy</h3>
        }
        {(notFinishedArray.length === 1 && notFinishedArray[0] !== undefined) ? <h3 className="c-white pl-10 last-info">Brak zaplanowanych spotkań</h3> : ''}
      </div>
    </div>
  )
}

export default TimetableCarousel