import React from 'react'

const TimetableSquad = ({ matchInProgressScore }) => {
  const squads = [];

  function getSquad() {
    matchInProgressScore && matchInProgressScore.map(item => {
        item.lineups.map(lineup => {
            squads.push(lineup)
        })
    })
  }
  getSquad();

  return (
    <>
      {matchInProgressScore && squads.length > 0 && (squads[0]['startXI'] && squads[1]['startXI']) && (squads[0]['startXI'] !== undefined && squads[1]['startXI'] !== undefined) 
      ?
      <div className="match-squad d-flex f-column ai-center">
        <div className="match-squad-header mb-20 mt-20">Skład wyjściowy</div>
        <div className="match-squad-group d-flex">
          {squads.map((i, index) => {
            return (
              <div className="squad-lineup-wrapper" key={index}>
                {i['startXI'].map(player => {
                  return (
                    <div className={index === 0 ? "squad-lineup" : "squad-lineup reverse"} key={player.player.id}>
                      {player.player.name}
                      <div className="player-number">{player.player.number ? player.player.number : '--'}</div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="match-squad-header mb-20 mt-40">Rezerwowi</div>
        <div className="match-squad-group d-flex">
          {squads.map((i, index) => {
            return (
              <div key={index} className="squad-lineup-wrapper">
                {i['substitutes'].map((player, index2) => {
                  return (
                    <div key={index2} className={index === 0 ? "squad-lineup" : "squad-lineup reverse"}>
                      {player.player.name}
                      <div className="player-number">{player.player.number ? player.player.number : '--'}</div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="match-squad-header mb-20 mt-40">Trenerzy</div>
        <div className="match-squad-group d-flex">
          {squads.map((i, index) => {
            return (
              <div key={index} className="squad-lineup-wrapper">
                <div className={index === 0 ? "squad-lineup" : "squad-lineup reverse"}>
                  {i.coach.name}
                </div>
              </div>
            )
          })}
        </div>
      </div >
      : 
      <div className="d-flex jc-center mt-10">
        <h3>Informacje dla tego spotkania nie są jeszcze dostępne</h3>
      </div>
      }
      {/* {
        (!matchInProgressScore || squads.length <= 0) &&
        <div className="d-flex jc-center mt-10">
          <h3>Informacje dla tego spotkania nie są jeszcze dostępne</h3>
        </div>

      } */}
    </>
  )
}

export default TimetableSquad