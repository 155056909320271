import React from "react";
import { Link } from "react-router-dom";
import parse, { domToReact } from 'html-react-parser';

function TextLinks(params) {
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
            if (params.line === 'offline' || attribs.href.includes("http://") || attribs.href.includes("https://")) {
              let url = attribs.href
              if (url.includes("offline-widzew")) {
                url = url.split('/sites/widzew');
                url = url[1];
                return <a href={url} className={attribs.class}>{domToReact(children)}</a>;
              }
              else {
                return <a href={attribs.href} className={attribs.class}>{domToReact(children)}</a>;
              }
            }
            else {
              let url = attribs.href
              if (attribs.href.includes("/dokumenty/") || attribs.href.includes("/dokumenty/") || attribs.href.includes(".pdf") || attribs.href.includes(".png") || attribs.href.includes(".jpg") ) {
                if (url.includes("offline-widzew")) {
                  url = url.split('/sites/widzew');
                  url = url[1];
                  return <a href={url} target="_blank" className={attribs.class}>{domToReact(children)}</a>;
                }
                else {
                  return <a href={attribs.href} target="_blank" className={attribs.class}>{domToReact(children)}</a>;
                }
              }
              else if (attribs.href.includes("mailto:")) {
                if (url.includes("/sites/widzew")) {
                  url = url.split('/sites/widzew');
                  url = url[1];
                  return <a href={url} className={attribs.class}>{domToReact(children)}</a>;
                }
                else {
                  return <a href={attribs.href} className={attribs.class}>{domToReact(children)}</a>;
                }
              }
              else {
                if (url.includes("offline-widzew")) {
                  url = url.split('/sites/widzew');
                  url = url[1];
                  return <Link to={url} className={attribs.class}>{domToReact(children)}</Link>;
                }
                else {
                  return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
                }
              }
            }
        }
        if (name === 'img' && attribs.src) {
          let image = attribs.src;
          if (image.includes("/sites/widzew") && image !== "") {
            image = image.split('/sites/widzew');
            image = image[1];
          }
      
          if ( window.location.origin.includes("http://localhost") && !(image.includes("http://") || image.includes("https://")) ) {
            image = 'https://dev-widzew.qrqs.eu'+image
          }
          if ( window.location.origin.includes('cms') && !(image.includes("http://") || image.includes("https://")) ) {
            image = 'https://dev-widzew.qrqs.eu'+image
          }

          return <img src={image} className={attribs.class} loading="lazy" alt={attribs.alt}/>;
        }
      }
    };
        
    return parse(params.text, options);
}

export default TextLinks;