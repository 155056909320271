import React from "react";
import SponsorsListItem from './SponsorsListItem'
function SponsorsList(params) {
    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }
    let itemLength = 'col-box-0'
    if (params.block.en.hasOwnProperty('Item')) {
        itemLength = 'col-box-' + Object.keys(params.block['en']['Item']).length
    }
    let innerSection = 'sponsors-list';
    let innerContainer = ' container ' + itemLength;
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer} style={{ paddingBottom: "0" }}>
                    <h1 className="main-title">{params.block.en.Title}</h1>
                    {params.block['en'].hasOwnProperty('Item') && Object.keys(params.block.en['Item']).map((item, index) => (
                        <SponsorsListItem
                            key={index}
                            title={params.block['en']['Item'][item].hasOwnProperty('Title') ? params.block['en']['Item'][item]['Title'] : ''}
                            item={params.block['en']['Item'][item]}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}
export default SponsorsList;