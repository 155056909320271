import React from "react";
import Image from "../../components/Image";
import Links from "../../components/Links";

function MembersTeaser(params) {
    if (window.origin.includes('localhost')) {
        console.log('MembersTeaser')
        console.log(params)
    }

    let memberClassPlayer;
    let memberClassStaff;
    let wrapper;
    
    if (params.line === 'offline') {
        memberClassPlayer = "";
        memberClassStaff = "";
        wrapper = "";
    } else {
        memberClassPlayer = `members-teaser mt-50 ${params.block.en.IsImageWithBackground ? "img-with-background" : ""} ` + params.block['en'].Position;
        memberClassStaff = `members-teaser mt-50 staff ${params.block.en.IsImageWithBackground ? "img-with-background" : ""} `;
        wrapper = "wrapper";
    }

    if (params.block['en'].Position === 'staff') {
        return (
            <div className={memberClassStaff}>
                <div className={wrapper}>
                    <div className="d-flex f-column jc-center">
                        {params.block['en'].StaffPosition &&
                            <div className="staff-title d-flex">
                                <h3 className="js-end"><p className="d-flex">{params.block['en'].StaffPosition}</p></h3>
                            </div>
                        }
                        <div className="b-red">
                            { params.block['en'].ImageFront && params.block['en'].ImageFront.hasOwnProperty('path') &&
                                <Image src={`${params.block['en'].ImageFront.path}_width=300`} altText={params.block['en'].Surname} />
                            }
                            <div className="small-h">
                                <h3 className="name">
                                { params.block['en'].Name &&
                                    params.block['en'].Name
                                }
                                <br/>
                                { params.block['en'].Surname &&
                                    params.block['en'].Surname
                                }
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={memberClassPlayer}>
                <div className={wrapper}>
                    <div className="d-flex f-column jc-center">
                        <div className="b-red">
                            { params.block['en'].Number && 
                                <span className="number">
                                    {params.block['en'].Number}
                                </span>
                            }
                            { params.block['en'].ImageFront && params.block['en'].ImageFront.hasOwnProperty('path') &&
                                <Image src={`${params.block['en'].ImageFront.path}_width=300`} altText={params.block['en'].Surname} />
                            }
                            <div className="small-h">
                                <h3 className="name">
                                { params.block['en'].Name &&
                                    params.block['en'].Name
                                }
                                <br/>
                                { params.block['en'].Surname &&
                                    params.block['en'].Surname
                                }</h3>
                            </div>
                            <div className="menu-member">
                                {params.block.link && 
                                    <Links line={params.line} url={params.block.link} class="button empty-gold" name="PROFIL ZAWODNIKA" />
                                }
                                {params.block['en'].UrlBuyShirt &&
                                    <Links line={params.line} url={params.block['en'].UrlBuyShirt} target="true" class="button b-gold" name="KUP KOSZULKĘ" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MembersTeaser;