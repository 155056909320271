import React from 'react'
import Galleries from '../../galleries/Galleries'

const TimetableMultimedia = (props) => {
  const youtubeVideo = props.params.block['en']['Item'];
  
  function renderVideo() {
  if (props.params.block['en']['Item'] && youtubeVideo.length > 0) {
      return youtubeVideo.map(item => {
        return (
          <div className="video-yt-item mb-40">
              <iframe src={item['Link']} title="Youtube video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <h3>{item['Header']}</h3>
          </div>
           )
      })
    }
  }

  return (
    <>
    {props.tags && props.params.block['en']['Item'] &&
      <div className="timetable-multimedia mt-10">
        {props.params.block['en']['Item'] && props.params.block['en']['Item'].length > 0 &&
          <section className="video-yt">
            <div className="wrapper">
              <div className='container mb-40'>
                  <h1>Video</h1>
                  <div className="video-yt-items d-flex f-wrap mt-20 mb-20">
                    {renderVideo()}
                  </div>
              </div>
            </div>
          </section>
        }
        {props.tags && props.tags.length > 0 &&
          <>
            <Galleries block={{en: {'ShowOnlyGalleriesByTags': props.tags, 'showTitle': 'Galerie zdjęć'}}} />
            {/* <NewsCarousel block={{en: {"Tags": props.tags, "Title": "Media o meczu", "ShowTitle": true, "Background": "white", "InnerBackground": false} }} /> */}
          </>
        }
      </div>
    }
    {!props.tags.length > 0 && props.params.block['en']['Item'].length <= 0 && props.tags.length <= 0 &&
      <div className="d-flex jc-center mt-10">
        <h3>Informacje dla tego spotkania nie są jeszcze dostępne</h3>
      </div>
    }
    </>
  )
}

export default TimetableMultimedia