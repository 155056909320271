import React from "react";
import Button from "../../components/Button";
import Links from "../../components/Links";

function Buttons(params) {
    let align = params.block.en.Align

    let innerSection = 'buttonItem';
    let innerContainerBef = 'container d-flex';
    let innerContainer = innerContainerBef;
    innerContainer = innerContainerBef + ' ' + align;
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <div className="d-flex">
                        <h3>{params.Title}</h3>
                    </div>
                    {params.block.en.InNewOpen ?
                        (
                            <Links line={params.line} url={params.block.en.Link} target="true" class={"button " + params.block.en.ButtonsType} name={params.block.en.LinkName} />
                        )
                        :
                        (
                            <Links line={params.line} url={params.block.en.Link} target="false" class={"button " + params.block.en.ButtonsType} name={params.block.en.LinkName} />
                        )
                    }
                </div>
            </div>
        </section>
    );
}

export default Buttons;