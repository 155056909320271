import React from 'react'

const WidzewFmScheduleItem = (params) => {
  if (window.origin.includes('localhost')) {
    console.log(params)
  }
  return (
    <div className="widzew-schedule-item d-flex jc-between">
      <div className="widzew-schedule-inner d-flex f-column">
        {params.item.AuditionTitle &&
          <span>
            {params.item.AuditionTitle}
          </span>
        }
        {params.item.AuditionDescription &&
          <div className="widzew-schedule-desc">
            <p>{params.item.AuditionDescription}</p>
          </div>
        }
      </div>
      <div className="d-flex ai-center">
        {params.item.AuditionTime &&
          <span className="widzew-schedule-time">
            {params.item.AuditionTime}
          </span>
        }
      </div>
    </div>
  )
}

export default WidzewFmScheduleItem