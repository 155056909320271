import React from "react";
import TextLinks from "../../components/TextLinks";

function TextBox(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
        console.log('TextBox')
        console.log(params)
    }
  
    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-'+params.block['en']['Background']
    }
    let innerSection = 'text-box';
    let innerContainer = 'container d-flex f-column jc-center';
    if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    {params.block['en']['Title'] &&
                        <h1>{params.block['en']['Title']}</h1>
                    }
                    {params.block['en']['Text'] && 
                        <div className="text-box">
                            <TextLinks text={params.block['en']['Text']} line={params.line} />
                        </div>
                    }   
                </div>
            </div>
        </section>
    );
}

export default TextBox;