import React, { useState, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import Axios from "axios";
import TextLinks from "../../components/TextLinks";
import { Link } from "react-router-dom";
import Links from "../../components/Links";
import Svg from "../../components/Svg";
import PromtList from "./PromtList";
import Loaders from "../../Loaders.js";
import Image from "../../components/Image.js";

function useOutsideAlerter(ref, ref2, changeState) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && ref2.current && !ref2.current.contains(event.target)) {
                changeState(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

function SearchBox(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew') || window.origin.includes('cms-widzew')) {
        console.log('SearchBox')
        console.log(params)
    }

    let [inputText, setInputText] = useState(""),
        [searchTipsResults, setSearchTipsResults] = useState([]),
        [searchTipsData, setSearchTipsData] = useState([]),
        [resultData, setResultData] = useState([]),
        [resultData2, setResultData2] = useState([]),
        [offclick, setOffclick] = useState(true),
        [numberOfResults, setNumberOfResults] = useState('empty'),
        [numberOfResults2, setNumberOfResults2] = useState('empty'),
        [pending, setPending] = useState(false),
        urlparam = urlParam('search'),
        pageparam = urlParam('page'),
        [falseClickJson, setFalseClickJson] = useState(false),
        [pageNumberPage, setPageNumberPage] = useState((pageparam !== 'empty' && pageparam !== 0) ? parseInt(pageparam) : 1),
        [pageNumberPage2, setPageNumberPage2] = useState((pageparam !== 'empty' && pageparam !== 0) ? parseInt(pageparam) : 1),
        resultLink = '/wyniki-wyszukiwania/',
        searchCSV = '/.includes/search/search-main.csv',
        searchFetch = 'https://search.interconsystems.pl/s/1910EC/default/',//test link dev
        SetClickOuter = useRef(null),
        refClickSearch = useRef(null),
        [activeWww, setActiveWww] = useState(false),
        [activeNews, setActiveNews] = useState(true),
        [isInputFocused, setIsInputFocused] = useState(true);

        const inputRef = useRef(null)
        const helpRef = useRef(null)

        useOutsideAlerter(inputRef, helpRef, setIsInputFocused)
        
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        let device = 'desktop'
        if (width < 1024) device = 'tablet'
        if (width < 768) device = 'mobile'
        return {
            width,
            height,
            device
        };
    }
    const { device } = getWindowDimensions()

    function urlParam(name) {
		var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (!results) {
			return 'empty'
		}
		if (!results[1])  {
			return 'empty';
		}
        if (results[1]) {
            return decodeURIComponent(results[1].replace(/\+/g, ' ')) || 0;
            //return decodeURI(results[1].replace(/%20/g, ' ')) || 0
        }
        else {
            return 'empty'
        }
	}
  
    let inputHandler = (e) => {

                let lowerCase = e.target.value.toLowerCase(),
                phrase = e.target.value,
                tipsResults = [],
                filteredTipsResults = [];
            
                if (phrase.length >= 3) {
                    tipsResults = searchTipsData.filter(result => result.phrase.toLowerCase().match(new RegExp(phrase.toLowerCase())));
                } 

                tipsResults.forEach((result) => {
                    let arr = filteredTipsResults.filter(el => el.url === result.url && el.description === result.description);
                    if (!arr.length) {
                        filteredTipsResults.push(result);
                    }
                });
                setSearchTipsResults(filteredTipsResults)
                setInputText(lowerCase);
                setIsInputFocused(true)

    }

    if (window.location.origin.includes("http://localhost")) {
        searchCSV = "https://dev-widzew.qrqs.eu" + searchCSV;
    }
    else {
        searchCSV = window.location.origin + searchCSV;
    }

    useEffect(() => {
        let getSVG = async () => {
            let response = "";
            try {
                response = await Axios(searchCSV);
            } 
            catch (err) {
                response = "error";
                console.log('cant get search csv: '+err)
            }
            if (response.hasOwnProperty('data')) {
                let data = response.data.split('\n');
                    data = data.map(line => ({
                        phrase: line.split(';')[0],
                        description: line.split(';')[1] ? line.split(';')[1].replace('\r', '') : '',
                        url: line.split(';')[2] ? line.split(';')[2].replace('\r', '') : ''
                    }));
                setSearchTipsData(data)
            }
            else {
                setSearchTipsData([])
                console.log('wrong data in search csv')
            }
        };
        getSVG();
    }, [searchCSV]);

    function checkWordLength() {
        let addinfo = document.getElementById('results-same-box'),
            removeinfo = document.getElementById('info-length')
        if (removeinfo) {
            removeinfo.remove()
        }
        if (inputText.length < 3) {
            if (addinfo) {
                const   elem = document.createElement('div'),
                        info = document.createTextNode('Podaj minimum 3 znaki');
                elem.setAttribute("id", "info-length");
                elem.appendChild(info);
                addinfo.prepend(elem);
            }
        }
    }
    function clearNavSearch() {
        document.getElementById('search-field-nav').value = '';
        setSearchTipsResults([])
        activeClick()
    }
    function ResultLink() {
        if (inputText.length >= 3 || (urlparam !== 'empty' && urlparam !== 0 && urlparam.length >= 3)) {
            if (params.block['en'].hasOwnProperty('ResultsBox') && params.block['en']['ResultsBox'] === true ) {
                let word = inputText,
                    page = pageNumberPage;
                if (word === '' && urlparam !== 'empty' && urlparam !== 0) word = urlparam;
                if (pageparam !== 'empty' && pageparam !== 0) page = parseInt(pageparam);
                setTimeout(() => {
                    let clickme = document.getElementById('result-button')
                    if (clickme && offclick === true) {
                        clickme.click();
                        let focusme = document.getElementById('search-field')
                        if (focusme) {
                            focusme.focus()
                        }
                        setOffclick(false)
                    }
                }, 10);
                if (inputText.length < 3 && offclick === false) {
                    return <Link className="button b-gold disabled" id="result-button" to={resultLink+'?search='+word} onClick={(e) => {e.preventDefault(); checkWordLength()}}>Wyszukaj</Link>
                }
                else {
                    return <Link className="button b-gold" id="result-button" to={resultLink+'?search='+word} onClick={(e) => {e.preventDefault(); getResultsJSON2(word, falseClickJson ? 1 : page); getResultsJSON(word, falseClickJson ? 1 : page); setPageNumberPage(falseClickJson ? 1 : page); setIsInputFocused(false)}}>Wyszukaj</Link>
                }
            }
            else {
                return <Link className="button b-gold" id="search-button" to={resultLink+'?search='+inputText}>Wyszukaj</Link>
            }
        }
        else {
            if (params.block['en'].hasOwnProperty('ResultsBox') && params.block['en']['ResultsBox'] === true ) {
                return <Link className="button b-gold disabled" id="result-button" to={resultLink} onClick={(e) => {e.preventDefault(); checkWordLength()}}>Wyszukaj</Link>
            }
            else {
                return <Link className="button b-gold disabled" id="search-button" to={resultLink} onClick={(e) => {e.preventDefault();}}>Wyszukaj</Link>
            }
        }
    }

    function SearchIcon() {
        if (inputText.length >= 3) {
            return (
                <Link className="search-icon search-link" id="search-link" to={resultLink+'?search='+inputText} onClick={(e) => {clearNavSearch();}}>
                    <Svg name="SearchIcon" />
                </Link>
            )
        }
        else {
            return (
                <Link className="search-icon search-link disabled" id="search-link" to={resultLink} onClick={(e) => {e.preventDefault();}}>
                    <Svg name="SearchIcon" />
                </Link>
            )
        }
    }
  
    useEffect(() => {
        if (urlParam('search') !== 'empty' && urlParam('search') !== 0) {
            setTimeout(() => {
                let searchInput = document.getElementById("search-field");
                urlparam = urlParam('search');
                let urlparamNoPlusSign = urlparam.replaceAll('+', ' ')
                searchInput.value = urlparamNoPlusSign;
                setInputText(urlparamNoPlusSign)
                //inputHandler({target: {value: urlParam('search')}}) //To show on entry
            }, 1);
        }
    }, [searchTipsData]);
    
    let activeClick = () => {
        let icon = document.getElementById("search");
        if (icon.classList.contains('active')) {
            icon.classList.remove('active');
        } 
        else { 
            icon.classList.add('active');         
        }
    }

    GetClickOuter(SetClickOuter);
    function GetClickOuter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    let icon = document.getElementById("search");
                    if (icon.classList.contains('active')) {
                        icon.classList.remove('active');
                    } 
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleClickSearch = () => {
      refClickSearch.current.focus();
    };

    let getResultsJSON2 = async (word, page) => {
        let arrResults = []
        let words = latinizator(decodeURIComponent(word.replace(/ /g, '+').replace(/%20/g, '+')))
        let url2 = '/api/jsonws/news/searchPhrase?phrase=' + words + ((page && page !== '') ? '&page='+page : '')
        if(window.origin.includes('localhost')) {
            url2 = 'https://dev-widzew.qrqs.eu/api/jsonws/news/searchPhrase?phrase=' + words + ((page && page !== '') ? '&page='+page : '') 
        }


        setPending(true)
        let response2 = "",
            setFine = false;
        setSearchParamUrl(decodeURIComponent(word.replace(/ /g, '+').replace(/%20/g, '+')), ((page && page !== '') ? page : ''))
        try {
            response2 = await Axios(url2);
        } 
        catch (err) {
            response2 = "error";
            console.log('results err: '+err)
        }
        if (response2.hasOwnProperty('data')) {
            setPending(false)
            setNumberOfResults2(response2.data.total)
            response2.data?.news.forEach((item, i) => {
                let urlFixed = ''
                urlFixed = item.link
                if(item.link.includes('/sites/widzew')){
                    urlFixed = item.link.split('/sites/widzew')
                    urlFixed = urlFixed[1]
                }

                
                if(window.origin.includes('localhost')) {
                    urlFixed = "https://test-widzew.qrqs.eu" + urlFixed 
                }

                arrResults.push({
                    title: item.en.Title || "Brak tytułu",
                    text: item.en.Teaser?.replace( /(<([^>]+)>)/ig, '') || "Brak teaser",
                    url: urlFixed || "Brak url",
                    img: item.en.Image || "Brak obrazka",
                    date: item.en.Date || new Date()
                })
            })
            
            setResultData2(arrResults)
            setFine = true
        }
        if (!setFine || response2 === 'error') {
            setPending(false);
            setResultData2('empty');
        }
    }

    let getResultsJSON = async (word, page) => {
        setPending(true)
        let words = latinizator(decodeURIComponent(word.replace(/ /g, '+').replace(/%20/g, '+'))),
            url = searchFetch+words+ ((page && page !== '') ? '/'+page : ''),
            response = "",
            setFine = false;
        setSearchParamUrl(decodeURIComponent(word.replace(/ /g, '+').replace(/%20/g, '+')), ((page && page !== '') ? page : ''))
        try {
            response = await Axios(url);
        } 
        catch (err) {
            response = "error";
            console.log('results err: '+err)
        }
        if (response.hasOwnProperty('data')) {
            setPending(false)
            createResultBox(response.data)
            setFine = true
        }
        if (!setFine || response === 'error') {
            setPending(false);
            createResultBox([]);
            setResultData('empty');
        }
        setFalseClickJson(true)
    };

    function boldier(text) {
        if (inputText.indexOf(' ') > -1) {
            var words = inputText.split(' '),
                allText = text
            words.forEach(function (word, i) {
                allText = allText.replace(RegExp(words[i], 'gi'), '<strong>$&</strong>')
            })
            return allText
        }
        else {
            return text.replace(RegExp(inputText, 'gi'), '<strong>$&</strong>')
        }
    }

    function createResultBox(text) {
        const fragment = new DocumentFragment();
        let results = [];

        fragment.appendChild(document.createElement('div'));
        fragment.querySelector('div').innerHTML = text;

        const   titleElements = fragment.querySelectorAll('.osscmnrdr.ossfieldrdr1'),
                textElements = fragment.querySelectorAll('.osscmnrdr.ossfieldrdr2'),
                linkElements = fragment.querySelectorAll('.osscmnrdr.ossfieldrdr3'),
                numberOfResultsElement = fragment.querySelector('.osscmnrdr.ossnumfound');
                
                setNumberOfResults(numberOfResultsElement && 
                    parseInt(numberOfResultsElement.textContent) ? parseInt(numberOfResultsElement.textContent) : 0);

        textElements.forEach((element, i) => {
            let title = 'Widzew Łódź',
                text = '',
                url = '';

            if (element.textContent) {
                text = boldier(element.innerHTML);
            }
            if (linkElements[i] && linkElements[i].textContent) {
                url = linkElements[i].textContent.replace(/\n/g, '').replace(/\t/g, '');
            }
            if (titleElements[i] && titleElements[i].textContent) {
                title = titleElements[i].textContent.replace(/\n/g, '').replace(/\t/g, '').replace('ﾅ�ﾃｳdﾅｺ', 'Łódź');
            }

            results.push({
                title: title,
                text: text,
                url: url
            });

        });
        setResultData(results);
    }
    
    function RenderResultsCount({numberOfResults}) {
		let label = '',
            textLabel = '';
		if (numberOfResults === 0) {
			label = 'wyników'
		} 
        else if (numberOfResults === 1) {
			label = 'wynik'
		} 
        else {
			const str = numberOfResults.toString()[numberOfResults.toString().length - 1];
			if (numberOfResults > 10 && numberOfResults < 20) {
				label = 'wyników';
			} else {
				label = parseInt(str) >= 5 || parseInt(str) <= 1 ? 'wyników' : 'wyniki';
			}
		}
		if (numberOfResults === 0) {
			textLabel = <h6 className="d-flex mb-10">Nie znaleziono wyników</h6>
		} 
        else if (numberOfResults === 'empty') {
			textLabel = ''
		} 
        else {
			textLabel = <h6 className="d-flex mb-10">{numberOfResults} {label} wyszukiwania</h6>
		}

		return textLabel;
	}

    function scrollToSearch() {
        const search = document.getElementById('search-results');
        window.scrollTo({top: search.offsetTop - 30, behavior: 'smooth'});
    }

    function RenderPagination({pageNumberPage, numberOfResults, variant}) {
		let pageNumber = pageNumberPage,
            numberOfPages = Math.ceil(numberOfResults / 10),
            pages = [],
            arr = [];

		if (numberOfPages) {
			for (let i = 1; i <= numberOfPages; i++) {
				pages.push(i);
			}
		}

		if (pages.length > 4) {
			for (let i = 2; i < pages.length; i++) {
				if (pageNumber <= 3 && i <= 4) {
					arr.push(i);
				} else if (pageNumber >= pages.length - 1 && i >= pages.length - 3) {
					arr.push(i);
				} else if (i >= pageNumber - 1 && i <= pageNumber + 1) {
					arr.push(i);
				} else {
					if (pageNumber === i - 2 || pageNumber === i + 2) {
						arr.push('...')
					} else {
						if (i === 5 && i > pageNumber) {
							arr.push('...')
						}
						if (i === pages.length - 4 && i < pageNumber) {
							arr.push('...')
						}
					}
				}
			}
			pages = [pages[0], ...arr, pages[pages.length - 1]];
		} else {
			arr = [pages[1], pages[2]];
		}

        if (variant === 1) {
            return (
                <div className="pagination-container osscmnrdr oss-paging text-center">
                    <div className={`pagination-icon`+(pageNumber !== 1 && numberOfPages > 2 ? ' show' : ' hide')}
                        onClick={() => {
                            if (pageNumber > 0) {
                                setPageNumberPage(pageNumber - 1);
                                getResultsJSON(inputText, pageNumber - 1);
                                scrollToSearch();
                            }
                        }}
                    >
                        <Svg name="ArrowLeft" class="btn-arrow l-arrow b-white" />
                    </div>
                    {pages.map((pageNumber, i) => {
                        return (
                            <div className={`pagination-item`+(pageNumberPage === pageNumber ? ` active` : '')}
                                key={'item-' + i}
                            >
                                <a href={`?page=`+pageNumber} className={`osscmnrdr oss-currentpage`+(pageNumber === '...' ? ' b-t' : '')}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (pageNumber !== '...') {
                                            setPageNumberPage(pageNumber);
                                            getResultsJSON(inputText, pageNumber);
                                            scrollToSearch()
                                        }
                                    }}
                                >
                                    {pageNumber}
                                </a>
                            </div>
                        );
                    })}
                    <div className={`pagination-icon`+(pageNumber !== numberOfPages && numberOfPages > 2 ? ' show' : ' hide')}
                        onClick={() => {
                            if (pageNumber < numberOfPages) {
                                setPageNumberPage(pageNumber + 1);
                                getResultsJSON(inputText, pageNumber + 1);
                                scrollToSearch()
                            }
                        }}
                    >
                        <Svg name="ArrowRight" class="btn-arrow r-arrow b-white" />
                    </div>
                </div>
            );
        }

        if(variant === 2) {
            return (
                <div className="pagination-container osscmnrdr oss-paging text-center">
                    <div className={`pagination-icon`+(pageNumber !== 1 && numberOfPages > 2 ? ' show' : ' hide')}
                        onClick={() => {
                            if (pageNumber > 0) {
                                setPageNumberPage2(pageNumber - 1);
                                getResultsJSON2(inputText, pageNumber - 1);
                                scrollToSearch();
                            }
                        }}
                    >
                        <Svg name="ArrowLeft" class="btn-arrow l-arrow b-white" />
                    </div>
                    {pages.map((pageNumber, i) => {
                        return (
                            <div className={`pagination-item`+(pageNumberPage === pageNumber ? ` active` : '')}
                                key={'item-' + i}
                            >
                                <a href={`?page=`+pageNumber} className={`osscmnrdr oss-currentpage`+(pageNumber === '...' ? ' b-t' : '')}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (pageNumber !== '...') {
                                            setPageNumberPage2(pageNumber);
                                            getResultsJSON2(inputText, pageNumber);
                                            scrollToSearch()
                                        }
                                    }}
                                >
                                    {pageNumber}
                                </a>
                            </div>
                        );
                    })}
                    <div className={`pagination-icon`+(pageNumber !== numberOfPages && numberOfPages > 2 ? ' show' : ' hide')}
                        onClick={() => {
                            if (pageNumber < numberOfPages) {
                                setPageNumberPage2(pageNumber + 1);
                                getResultsJSON2(inputText, pageNumber + 1);
                                scrollToSearch()
                            }
                        }}
                    >
                        <Svg name="ArrowRight" class="btn-arrow r-arrow b-white" />
                    </div>
                </div>
            );
        }
	}

    function setSearchParamUrl(words, page) {
        let pages = ''
        if (page && page !== 1) pages = '&page='+page;
        if (window.history.pushState) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?search='+words + pages;
            window.history.pushState({path:newurl},'',newurl);
        }
    }

    const polandLettersMap = {
        'ą': 'a',
        'ć': 'c', 
        'ę': 'e', 
        'ł': 'l', 
        'ń': 'n', 
        'ó': 'o', 
        'ś': 's', 
        'ź': 'z', 
        'ż': 'z',
        'Ą': 'A',
        'Ć': 'C', 
        'Ę': 'E', 
        'Ł': 'L', 
        'Ń': 'N', 
        'Ó': 'O', 
        'Ś': 'S', 
        'Ź': 'Z', 
        'Ż': 'Z'
    };

    function latinizator(str) {
        return String(str).replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, function (e) {
            return polandLettersMap[e];
        });
    }

    function MoreResults() {
        if (params.block['en'].hasOwnProperty('ResultsBox') && params.block['en']['ResultsBox'] === true ) {
            return (
                <>
                    <div id="results-box" className="mt-50">
                        {(params.block['en'].hasOwnProperty('ResultsBox') && params.block['en']['ResultsBox'] === true ) && 
                            <div id="promt-box" ref={helpRef}>
                                <ul>
                                {isInputFocused && <PromtList input={inputText} data={searchTipsResults} line={params.line} title={inputText}/>}
                                </ul>
                            </div>
                        }
                    </div>
                    <div id="results-same-box">
                        {activeNews && 
                            <>
                                <RenderResultsCount numberOfResults={numberOfResults2} />
                                <SetResultDataBox data={resultData2} variant={2} pageNumberPage={pageNumberPage} numberOfResults={numberOfResults2} />
                                <RenderPagination pageNumberPage={pageNumberPage2} numberOfResults={numberOfResults2} variant={2} />
                            </>
                        }

                        {activeWww && 
                            <div>
                                <RenderResultsCount numberOfResults={numberOfResults} />
                                <SetResultDataBox data={resultData} variant={1} pageNumberPage={pageNumberPage} numberOfResults={numberOfResults} />
                                <RenderPagination pageNumberPage={pageNumberPage} numberOfResults={numberOfResults} variant={1} />
                            </div>
                        }
                    </div>
                </>
            )
        }
        else {
            return ''
        }
    }

    function LoadImage(params) {
        getImage(params.src, params.id)
        return (
            <>
                <div className="before-image b-white" id={params.id}><Loaders type="circle" mode="true" color="b-t" /></div>
                <img id={`change-`+params.id} className='image-gallery-image' loading="lazy" alt={params.title} title={params.title} 
                    onError={(e) => {ImageError(e.target); ImageLoad(params.id, 'err')}}
                    onLoad={(e) => {ImageLoad(params.id, 'load')}} 
                />
            </>
        )
    }
   
    async function getImage(src, id) {
        let response = '',
            data = '',
            type = "page",
            image = 'https://widzew.com/.templates/img/logo/herbwidzewa.jpg';

        if (src.includes('/item/')) {
            type = 'news'
        }
        
        try {
            let url = src + 'index.html.json'
            if (src.toLowerCase().includes('.pdf') || src.toLowerCase().includes('.doc')) {
                url = src
            }
            response = await fetch(url);
            data = await response.json();       

            if (data.hasOwnProperty('linkedContents')) {
                Object.keys(data.linkedContents).map((path) => {
                    if (type === 'news') {
                        if (path.includes('/.content/news-details/news')) {
                            if (data.linkedContents[path]['en'].hasOwnProperty('Image')) {
                                if (data.linkedContents[path]['en']['Image'].hasOwnProperty('path')) {
                                    image = 'https://widzew.com'+data.linkedContents[path]['en']['Image']['path']+'_width=200'
                                }
                                else {
                                    response = "error";
                                }
                            }
                            else {
                                response = "error";
                            }
                        }
                    }
                    else {
                        if (path.includes('/.content/top-banner-title/banner')) {
                            if (data.linkedContents[path]['en'].hasOwnProperty('Image')) {
                                if (data.linkedContents[path]['en']['Image'].hasOwnProperty('path')) {
                                    image = 'https://widzew.com'+data.linkedContents[path]['en']['Image']['path']+'_width=200'
                                }
                                else {
                                    response = "error";
                                }
                            }
                            else {
                                response = "error";
                            }
                        }
                    }
                    return null
                })
            }
            else {
                response = "error";
            }
        } catch (err) {
            response = "error";
            console.log('response error' + err)
        }
        
        if (response === 'error') {
            image = 'https://widzew.com/.templates/img/logo/herbwidzewa.jpg'
        }
        let imageBox = document.getElementById(`change-`+id);
        if (imageBox) {
            imageBox.src = image;
        }
    }
    
    function ImageError(elem) {
        let span = document.createElement("div");
        span.classList.add("error-image");
        elem.before(span);
        const root = createRoot(span);
        root.render(
            <Svg name="preimage1" />
        );
    }
    function ImageLoad(elem) {
        let idl = document.getElementById(elem);
        if (idl) idl.remove()
    }

    function generateDisplayUrl(url) {
        if(window.origin.includes('localhost')) {
            return url
        } else if (url.includes('https')) {
            return url
        } else {
            return `${window.location.origin}${url}`
        }
    }

    function SetResultDataBox({data, variant, pageNumberPage, numberOfResults }) {
        if (data === 'empty') {
            return (
                <div className="results-item mb-20">
                    <h3>Coś poszło nie tak, spróbuj ponownie</h3>
                </div>
            )
        }
        else if (pageNumberPage > Math.ceil(numberOfResults / 10)) {
            return (
                <div className="results-item mb-20">
                    <h3>Strona niedostępna</h3>
                </div>
            )
        }
        else {
            if(variant === 1) {
                if (device === 'mobile') {
                    return data.map((item, i) => {
                        let url = item.url
                        if (url.includes('/item/')) {
                            url = url.slice(0, -6) + '...'; 
                        }
                        return (
                            <div className="results-item mb-20" key={`item-`+i}>
                                <div className="osscmnrdr ossfieldrdr1 title-item">
                                    <Links line={params.line} url={item.url} name={item.title} />
                                </div>
                                <div className="osscmnrdr ossfieldrdr2 text-item"
                                    dangerouslySetInnerHTML= {
                                        { __html: item.text }
                                    }
                                ></div>
                                <div className="osscmnrdr ossfieldrdr3 link-item">{generateDisplayUrl(url)}</div>
                            </div>
                        )
                    });
                }
                else {
                    return data.map((item, i) => {
                        let url = item.url
                        if (url.includes('/item/') && url.match(/-\d{5}\/$/)) {
                            url = url.slice(0, -7) + '...'; 
                        }
                        return (
                            <div className="results-item mb-20 d-flex" key={`item-`+i}>
                                <div className="image-box">
                                    <Links line={params.line} url={item.url} name={item.title} class="all-link"/>
                                    <LoadImage id={`item-image-`+i} src={item.url} title={item.title}/>
                                </div>
                                <div className="item-box">
                                    <div className="osscmnrdr ossfieldrdr1 title-item">
                                        <Links line={params.line} url={item.url} name={item.title} />
                                    </div>
                                    <div className="osscmnrdr ossfieldrdr2 text-item"
                                        dangerouslySetInnerHTML= {
                                            { __html: item.text }
                                        }
                                    ></div>
                                    <div className="osscmnrdr ossfieldrdr3 link-item">{generateDisplayUrl(url)}</div>
                                </div>
                            </div>
                        )
                    });
                }
            } else if (variant === 2) {
                if (device === 'mobile') {
                    return data.map((item, i) => {
                        let url = item.url
                        if (url.includes('/item/')) {
                            url = url.slice(0, -6) + '...'; 
                        }
                        return (
                            <div className="results-item mb-20" key={`item-`+i}>
                                <div className="osscmnrdr ossfieldrdr1 title-item">
                                    <Links line={params.line} url={item.url} name={item.title} />
                                </div>
                                <div className="search-box-item-date">
                                        {new Intl.DateTimeFormat('pl-PL', { dateStyle: 'full', timeStyle: 'short'} ).format(item.date)}
                                </div>
                                <div className="osscmnrdr ossfieldrdr2 text-item"
                                    dangerouslySetInnerHTML= {
                                        { __html: item.text }
                                    }
                                ></div>
                                <div className="osscmnrdr ossfieldrdr3 link-item">{generateDisplayUrl(url)}</div>
                            </div>
                        )
                    });
                } else {
                    return data.map((item, i) => {
                        let url = item.url
                        if (url.includes('/item/') && url.match(/-\d{5}\/$/)) {
                            url = url.slice(0, -7) + '...'; 
                        }
                        return (
                            <div className="results-item mb-20 d-flex" key={`item-`+i}>
                                <div className="image-box">
                                    <Links line={params.line} url={item.url} name={item.title} class="all-link"/>
                                    <Image 
                                        src={item.img && item.img}
                                        alt={item.title && item.title}
                                        haveLoader={true}
                                    />
                                </div>
                                <div className="item-box">
                                    <div className="osscmnrdr ossfieldrdr1 title-item info-page">
                                        <Links line={params.line} url={item.url} name={item.title} />
                                    </div>
                                    <div className="search-box-item-date">
                                        {new Intl.DateTimeFormat('pl-PL', { dateStyle: 'full', timeStyle: 'short'} ).format(item.date)}
                                    </div>
                                    <div className="osscmnrdr ossfieldrdr2 text-item"
                                        dangerouslySetInnerHTML= {
                                            { __html: item.text }
                                        }
                                    ></div>
                                    {console.log(window.location.host)}
                                    <div className="osscmnrdr ossfieldrdr3 link-item">{generateDisplayUrl(url)}</div>
                                </div>
                            </div>
                        )
                    });
                }
            }
        }
    }

    if (params.type !== 'nav') {
        let colorBack = '',
            title = '',
            innerSection = 'search-box',
            innerContainer = 'container d-flex f-column jc-center';
        if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
            colorBack = 'b-'+params.block['en']['Background']
        }
        if (params.block['en']['Title'] !== '' && params.block['en'].hasOwnProperty('Title') && 
            params.block['en']['ShowTitle'] === true && params.block['en'].hasOwnProperty('ShowTitle')) {
            title = (
                <h1>{params.block['en']['Title']}</h1>
            )
        } 
        if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
            if (params.block['en']['InnerBackground'] === true) {
                innerContainer = innerContainer + ' ' + colorBack;
            }
            else {
                innerSection = innerSection + ' ' + colorBack;
            }
        }

        let classListNews = activeNews ? 'link-style bigger active' : 'link-style bigger'
        let classListWww = activeWww ? 'link-style bigger active' : 'link-style bigger'

        function showNewsHandler() {
            setActiveNews(true)
            setActiveWww(false)
        }
        
        function showWwwHandler() {
            setActiveNews(false)
            setActiveWww(true)
        }


        return (
            <section className={innerSection}>
                <div className="wrapper">
                    <div className={innerContainer}>
                        {title}
                        {params.block['en']['Text'] && 
                            <div className="search-box">
                                <TextLinks text={params.block['en']['Text']} line={params.line} />
                            </div>
                        }
                        <div id="search-results">
                            <div className="search-switch-tabs">
                                <button className={classListNews} onClick={showNewsHandler} >Aktualności</button>
                                <button className={classListWww} onClick={showWwwHandler} >Strony informacyjne</button>
                            </div>
                            <div className="d-flex ai-center search-input-box" ref={inputRef}>
                                <div className="search-icon">
                                    <Svg name="SearchIcon" />
                                </div>
                                <fieldset>
                                    <input
                                        id="search-field"
                                        className="button b-white newsl" 
                                        name="search"
                                        onChange={inputHandler}
                                        // onBlur={() => {setIsInputFocused(false)}}
                                        onFocus={() => {setIsInputFocused(true)}}
                                        onKeyDown={(e) => {
                                            if (e.code === "Enter") {
                                                e.preventDefault();
                                                setIsInputFocused(false)
                                                let clickme = false
                                                if (params.block['en'].hasOwnProperty('ResultsBox') && params.block['en']['ResultsBox'] === true ) {
                                                    clickme = document.getElementById('result-button')
                                                }
                                                else {
                                                    clickme = document.getElementById('search-button')
                                                }
                                                if (clickme) {
                                                    clickme.click();
                                                }
                                            }
                                        }}
                                        label="Search"
                                        placeholder="Wyszukaj..."
                                    />
                                    {(params.block['en'].hasOwnProperty('ResultsBox') && params.block['en']['ResultsBox'] === false ) &&
                                        <div id="promt-box">
                                            <ul> 
                                                {isInputFocused && <PromtList input={inputText} data={searchTipsResults} line={params.line}/>}
                                            </ul>
                                        </div>
                                    }
                                </fieldset>
                                <div id="search-button-box">
                                    <ResultLink/>
                                </div>
                            </div>
                            {pending && 
                                <div><Loaders type="dots" color="b-black"/></div>
                            }
                            <MoreResults/>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
    else {
        return (
            <div id="search" ref={SetClickOuter}>
                <div className="d-flex ai-center search-input-box">
                    <div className="search-icon open-search" onClick={(e) => {activeClick(e); handleClickSearch()}}>
                        <Svg name="SearchIcon" />
                    </div>
                    <SearchIcon/>
                    <fieldset>
                        <input
                            id="search-field-nav"
                            className="button b-white newsl" 
                            name="search"
                            onChange={inputHandler}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                    clearNavSearch();
                                    let clickme = document.getElementById('search-link')
                                    if (clickme) {
                                        clickme.click();
                                    }
                                }
                            }}
                            ref={refClickSearch}
                            label="Search"
                            placeholder="Wyszukaj..."
                        />
                        <div id="promt-box-nav">
                            <ul id="promt-box-nav-ul">
                            {isInputFocused && <PromtList input={inputText} data={searchTipsResults} line={params.line}/>}
                            </ul>
                        </div>
                    </fieldset>
                </div>
            </div>
        );
    }
}

export default SearchBox;