import React from "react";
import TextLinks from "../../components/TextLinks";
import Image from "../../components/Image";
import Links from "../../components/Links";
import ShareBox from "../../components/ShareBox";
import Loaders from "../../Loaders.js";

function NewsTeaser(params) {
    //console.log('NewsTeaser')
    //console.log(params)
    let classTeaser = "news-teaser "+params.block['en']['Type']+"-type";
    let type = `<span className="article-type d-flex ai-center">
                    <svg id="article-type" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m22.5 9h-21c-.827 0-1.5-.673-1.5-1.5v-6c0-.827.673-1.5 1.5-1.5h21c.827 0 1.5.673 1.5 1.5v6c0 .827-.673 1.5-1.5 1.5zm-21-8c-.275 0-.5.224-.5.5v6c0 .276.225.5.5.5h21c.275 0 .5-.224.5-.5v-6c0-.276-.225-.5-.5-.5z"/></g><g><path d="m9.5 18h-8c-.827 0-1.5-.673-1.5-1.5v-4c0-.827.673-1.5 1.5-1.5h8c.827 0 1.5.673 1.5 1.5v4c0 .827-.673 1.5-1.5 1.5zm-8-6c-.275 0-.5.224-.5.5v4c0 .276.225.5.5.5h8c.275 0 .5-.224.5-.5v-4c0-.276-.225-.5-.5-.5z"/></g><g><path d="m23.5 12h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m23.5 15h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m23.5 18h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m23.5 21h-23c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h23c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m23.5 24h-23c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h23c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g></svg>
                </span>`;
    
    if (params.block['en']['Type'] === 'audio') {
        type = `<span className="audio-type d-flex ai-center">
                    <svg enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path d="M256,31C115.39,31,0,145.39,0,286v120c0,24.814,20.186,45,45,45h15V301H45c-5.284,0-10.285,1.082-15,2.763V286    C30,161.928,131.928,61,256,61s226,100.928,226,225v17.763c-4.715-1.681-9.716-2.763-15-2.763h-15v150h15    c24.814,0,45-20.186,45-45V286C512,145.39,396.61,31,256,31z"/>
                        <path d="m135 271h-15c-16.569 0-30 13.431-30 30v150c0 16.569 13.431 30 30 30h15c8.284 0 15-6.716 15-15v-180c0-8.284-6.716-15-15-15z"/>
                        <path d="m392 271h-15c-8.284 0-15 6.716-15 15v180c0 8.284 6.716 15 15 15h15c16.569 0 30-13.431 30-30v-150c0-16.569-13.431-30-30-30z"/>
                    </svg>
                </span>`;
    }
    else if (params.block['en']['Type'] === 'video') {
        type = `<span className="video-type d-flex ai-center">
                    <svg height="36" id="video-type" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g id="Watching_Movies" data-name="Watching Movies">
                        <path d="m41 6h-34a5.006 5.006 0 0 0 -5 5v21a5.006 5.006 0 0 0 5 5h12v3h-10a1 1 0 0 0 0 2h30a1 1 0 0 0 0-2h-10v-3h12a5.006 5.006 0 0 0 5-5v-21a5.006 5.006 0 0 0 -5-5zm-14 34h-6v-3h6zm17-8a3 3 0 0 1 -3 3h-34a3 3 0 0 1 -3-3v-21a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3z"></path><path d="m27.977 19.768-4.952-2.859a2 2 0 0 0 -3 1.732v5.718a2 2 0 0 0 3 1.732l4.953-2.859a2 2 0 0 0 0-3.464zm-5.953 4.591v-5.718l4.952 2.859z"></path><path d="m24 11a10.5 10.5 0 1 0 10.5 10.5 10.512 10.512 0 0 0 -10.5-10.5zm0 19a8.5 8.5 0 1 1 8.5-8.5 8.51 8.51 0 0 1 -8.5 8.5z"></path></g>
                    </svg>
                </span>`;
    }
    else if (params.block['en']['Type'] === 'photo') {
        type = `<span className="photo-type d-flex ai-center">
                    <svg id="photo-type" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m467 121h-86.703l-11.177-55.883c-2.794-13.974-15.166-24.117-29.417-24.117h-167.406c-14.25 0-26.623 10.143-29.417 24.117l-11.177 55.883h-86.703c-24.813 0-45 20.187-45 45v260c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45v-260c0-24.813-20.187-45-45-45zm-294.703-50h167.406l10 50h-187.406zm309.703 355c0 8.271-6.729 15-15 15h-422c-8.271 0-15-6.729-15-15v-260c0-8.271 6.729-15 15-15h422c8.271 0 15 6.729 15 15z"/><path d="m256 185c-61.206 0-111 49.794-111 111s49.794 111 111 111 111-49.794 111-111-49.794-111-111-111zm0 192c-44.664 0-81-36.337-81-81s36.336-81 81-81 81 36.337 81 81-36.336 81-81 81z"/><path d="m128 185h-48c-8.284 0-15 6.716-15 15s6.716 15 15 15h48c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/></g></svg>
                </span>`;
    }
    
    let currentTimestamp = new Date();
    if (params.block['en']['Date'] && params.block['en']['Date'] !== '') currentTimestamp = new Date(params.block['en']['Date']);
    let date = new Intl.DateTimeFormat('pl-PL', { dateStyle: 'full', timeStyle: 'short'} ).format(currentTimestamp)
        date = date.split(" ");

    let id = params.block.path.split("/").pop();
        id = id.substring(0, id.length - 4);

    function TypeBox() {
        if (params.block['en']['MediaNews'] && params.block['en']['MediaNews'] === true) {
            return (
                <span className="type-box media-type d-flex ai-center">
                    {params.block['en']['MediaLogo'] && params.block['en']['MediaLogo'].hasOwnProperty('path') && params.block['en']['MediaLogo']['path'] !== '' 
                        ? <Image src={params.block['en']['MediaLogo'].path+`_width=115`} alt={params.block['en']['Title']}/>
                        : ''
                    }
                </span>
            )
        }
        else {
            return (
                <span className="type-box"
                    dangerouslySetInnerHTML= {
                        { __html: type }
                    }
                ></span>
            )
        }
    }
    let tagArray = []
    if (params.block['en']['Tags']) {
        const splitTags = params.block['en']['Tags'] && params.block['en']['Tags'].split(',');
        splitTags.map((tag) => {
            let tags = tag.replace('/sites/widzew/.categories','').replace('/.categories',''),
                tagLast = tags.split('/'),
                last = tagLast[tagLast.length - 2]
            tagArray.push(last)
            return null
        })
        tagArray = JSON.stringify(tagArray).replace('[','').replace(']','').replace(/"/g,'');
    }
    
    return (
        <div className={classTeaser} data-type={params.block['en']['Type']} data-tags={tagArray}>
            <div className="wrapper">
                <div className="b-white news-item" title={params.block['en']['Title']}>
                    {params.block.link && 
                        <Links line={params.line} url={params.block.link} class="all-link" name={params.block['en']['Title']} />
                    }
                    <div className="top-box">
                        <div className="image-box">
                            {params.block['en']['Image'] && params.block['en']['Image'].hasOwnProperty('path') && params.block['en']['Image']['path'] !== '' 
                                ?   <>
                                        <Loaders type="circle" color="b-red" mode="true"/>
                                        <Image src={params.block['en']['Image'].path} width="400" alt={params.block['en']['Title']}/>
                                    </>
                                :   <Image src='/.templates/img/logo/herbwidzewa.jpg' class="no-img-logo" width="400" alt="Widzew Łódź" />
                            }
                        </div>
                        <ShareBox id={id} link={params.block.link} mode="details" type="slide" title={params.block['en']['Title']}/>
                    </div>
                    <div className="bottom-box">
                        <div className="about-box d-flex ai-center jc-between mb-10">
                            {params.block['en']['Date'] && 
                                <div className="date-box d-flex ai-center jc-center">
                                    <span className="day-box">
                                        {date[1]}
                                    </span>
                                    <span className="d-flex f-column">
                                        <span className="month-box">
                                            {date[2]}&nbsp;{date[3]}
                                        </span>
                                        <span className="time-box">
                                            {date[4] === 'o' ? date[5] : date[4]}
                                        </span>
                                    </span>
                                </div>
                            }
                            <TypeBox />
                        </div>
                        <div className="text-box">
                            {params.block['en']['Title'] &&
                                <h2>{params.block['en']['Title']}</h2>
                            }
                            {params.block['en']['Teaser'] && 
                                <div className="text mt-10">
                                    <TextLinks text={params.block['en']['Teaser']} line={params.line} />
                                </div>
                            }   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsTeaser;