import React from "react";
import Nav from "./Nav.js";
import NavSide from "./NavSide.js";
import Image from "./components/Image.js";
import Links from "./components/Links";
import Svg from "./components/Svg";
import { Link } from "react-router-dom";
import useWindowDimensions from "./Device.js";

function Header(params) {
  if (window.origin.includes('localhost') || window.origin.includes('dev-widzew') || window.origin.includes('cms-widzew')) {
    console.log('Header');
    console.log(params)
  }
  const { device } = useWindowDimensions();
  function OpenMenu() {
    const sidemenu = document.getElementById("sidemenu");
    if (sidemenu.classList.contains('open')) {
      sidemenu.classList.remove("open");
      CloseSide()
    }
    else if (!sidemenu.classList.contains('open')) {
      sidemenu.classList.add("open");
    }
  };
  function CloseMenu() {
    const sidemenu = document.getElementById("sidemenu");
    if (sidemenu.classList.contains('open')) {
      sidemenu.classList.remove("open");
      CloseSide()
    }
  };
  function CloseSide() {
    const sidenav = document.getElementById("sidenav");
    if (sidenav.classList.contains('show')) {
      sidenav.classList.remove("show");
    }
  };
  if (device !== 'desktop') {
    return (
      <div id="sidemenu">
        <div className="sidebar hovered-menu b-red">
          <NavSide line={params.line} />
        </div>
        <div className="topbar fix-menu">
          <div className="container b-red">
            <div className="topbar-container d-flex jc-between">
              <a className="logo-link no-anim" href="/">
                <Image src="/.templates/img/logo/herb.png" alt="Widzew Łódź" />
              </a>
              <div className="menu-button">
                <div id="nav-icon" className="nav-icon" onClick={(e) => {e.preventDefault();OpenMenu();}}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div id="nav" className="midd-logo">
                <div className="media-menu ta-center d-flex ai-center jc-center">
                  {params.line === "offline" ? 
                    <a href="/aktualnosci/tv/" title="TV" className="d-flex ai-center tv-fix">
                      <Svg name="svgTV" />
                      <span>TV</span>
                    </a> :
                    <Link to="/aktualnosci/tv/" title="TV" className="d-flex ai-center tv-fix">
                      <Svg name="svgTV" />
                      <span>TV</span>
                    </Link>
                  }
                  {params.line === "offline" ? 
                    <a href="/aktualnosci/radio/" title="FM" className="d-flex ai-center ml-15 fm-fix">
                      <Svg name="svgFM" />
                      <span>FM</span>
                    </a> :
                    <Link to="/aktualnosci/radio/" title="FM" className="d-flex ai-center ml-15 fm-fix">
                      <Svg name="svgFM" />
                      <span>FM</span>
                    </Link>
                  }
                  <script src="/elfsight/platform.js" defer></script>
                  <div className="elfsight-app-a32428dd-ca54-4249-982e-3945befe1759" id="radio-fm"></div>
                </div>
              </div>
              <div className="action-menu d-flex ai-center jc-right c-gold">
                <a href="https://bilety.widzew.com/" title="Kup bilety" className="button b-gold ml-20 ti-button d-flex ai-center jc-center" target="_blank" rel="noreferrer noopener" >
                  <span className="ti-text-h">
                    KUP&nbsp;BILETY
                  </span>
                  <Svg name="svgTicket" class="inner-icon"/>
                  <span className="ti-text">
                    KUP&nbsp;BILETY
                  </span>
                </a>
                <a href="https://sklep.widzew.com/" title="Sklep" className="button empty-gold ml-20 ti-button d-flex ai-center jc-center" target="_blank" rel="noreferrer noopener" >
                  <span className="ti-text-h">
                    SKLEP
                  </span>
                  <Svg name="svgMarket" class="inner-icon"/>
                  <span className="ti-text">
                    SKLEP
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-overlay" onClick={(e) => {e.preventDefault();CloseMenu();}}></div>
      </div>
    )
  }
  else {
    return (
      <div id="nav">
        <div className="d-flex jc-center project-eu-container">
          <Image src="/images/LOGOTYPY_2.jpg" alt="Projekt Unijny" />
        </div>
        <div className="top-menu b-black">
          <div className="container d-flex ai-center jc-between">
            <div className="extra-menu d-flex">
              <a href="https://www.ekstraklasa.org/" target="_blank"  rel="noreferrer noopener" title="Ekstra Klasa" className="d-inline-flex">
                <Image src="/.templates/img/logo/logo-ekstraklasa-pko.png" alt="Ekstra Klasa" />
              </a>
            </div>
            <div className="d-flex ai-center jc-end">
              <div className="soc-menu d-flex ai-center jc-center">
                <a href="https://www.facebook.com/widzew.ofi" target="_blank" rel="noreferrer" title="Facebook">
                  <Svg name="svgFB" />
                </a>
                <a href="https://twitter.com/RTS_Widzew_Lodz" target="_blank" rel="noreferrer" title="Twitter">
                  <Svg name="svgTwit" />
                </a>
                <a href="https://www.instagram.com/rtswidzewlodz/" target="_blank" rel="noreferrer" title="Instagram">
                  <Svg name="svgInsta" />
                </a>
                <a href="https://www.tiktok.com/@rts_widzew_lodz" target="_blank" rel="noreferrer" title="TikTok">
                  <Svg name="svgTikTok" />
                </a>
              </div>
              <div className="lang-menu mr-40 ml-40">
                <Links url="/en/" className="mr-5" name="EN" line={params.line}/>
                <span>/</span>
                <Links url="/pl/" className="active ml-5" name="PL" line={params.line} />
              </div>
            </div>
          </div>
        </div>
        <div className="middle-menu b-red">
          <div className="container d-flex ai-center jc-between">
            <div className="col-5 logo-menu d-flex ai-center">
              {params.line === "offline" ? 
                <a href="/" title="Widzew Łódź" className="mr-25 no-anim">
                  <Image src="/.templates/img/logo/herb.png" alt="herb widzew" />
                </a> :
                <Link to="/" title="Widzew Łódź" className="mr-25 no-anim">
                  <Image src="/.templates/img/logo/herb.png" alt="herb widzew" />
                </Link>
              }
              <div className="nav-fix d-flex">
                {params.line === "offline" ? 
                  <a href="/" title="Widzew Łódź" className="mr-25 no-anim">
                    <h1>WIDZEW ŁÓDŹ</h1>
                  </a> :
                  <Link to="/" title="Widzew Łódź" className="mr-25 no-anim">
                    <h1>WIDZEW ŁÓDŹ</h1>
                  </Link>
                }
              </div>
            </div>
            <div className="col-7 d-flex ai-center action-box jc-end">
              <div className="col-3 media-menu ta-center d-flex ai-center jc-center">
                {params.line === "offline" ? 
                  <a href="/aktualnosci/tv/" title="TV" className="d-flex ai-center tv-fix">
                    <Svg name="svgTV" />
                    <span>TV</span>
                  </a> :
                  <Link to="/aktualnosci/tv/" title="TV" className="d-flex ai-center tv-fix">
                    <Svg name="svgTV" />
                    <span>TV</span>
                  </Link>
                }
                {params.line === "offline" ? 
                  <a href="/aktualnosci/radio/" title="FM" className="d-flex ai-center ml-15 fm-fix">
                    <Svg name="svgFM" />
                    <span>FM</span>
                  </a> :
                  <Link to="/aktualnosci/radio/" title="FM" className="d-flex ai-center ml-15 fm-fix">
                    <Svg name="svgFM" />
                    <span>FM</span>
                  </Link>
                }
                <script src="/elfsight/platform.js" defer></script>
                <div className="elfsight-app-a32428dd-ca54-4249-982e-3945befe1759" id="radio-fm"></div>
              </div>
              <div className="col-8 action-menu d-flex ai-center jc-right c-gold">
                {/*<a href="https://accredito.com/pl/widzew-lodz" title="Zaloguj" className="login-box button empty-border ti-button no-anim d-flex ai-center jc-center pr-0" target="_blank" rel="noreferrer noopener">
                  <span className="ti-text-h">
                    DOŁĄCZ&nbsp;ZALOGUJ
                  </span>
                  <Svg name="svgUser" class="inner-icon"/>
                  <span className="ti-text c-gold">
                    <span title="Dołącz" className="c-gold mr-5">DOŁĄCZ</span> / <span title="Zaloguj" className="c-gold ml-5">ZALOGUJ</span>
                  </span>
                </a>*/}
                <a href="https://bilety.widzew.com/" title="Kup bilety" className="button b-gold ml-20 ti-button d-flex ai-center jc-center" target="_blank" rel="noreferrer noopener" >
                  <span className="ti-text-h">
                    KUP&nbsp;BILETY
                  </span>
                  <Svg name="svgTicket" class="inner-icon"/>
                  <span className="ti-text">
                    KUP&nbsp;BILETY
                  </span>
                </a>
                <a href="https://sklep.widzew.com/" title="Sklep" className="button empty-gold ml-20 ti-button d-flex ai-center jc-center" target="_blank" rel="noreferrer noopener" >
                  <span className="ti-text-h">
                    SKLEP
                  </span>
                  <Svg name="svgMarket" class="inner-icon"/>
                  <span className="ti-text">
                    SKLEP
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-menu b-white">
          <div className="container pt-0 pb-0">
            <nav id="nav-list" className="main-nav d-flex jc-between ai-center">
              <Nav line={params.line}/>
              {/* <Image src="/images/LOGOTYPY.jpg" alt="Projekt Unijny" /> */}
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;