import React, { useState, useEffect } from 'react';
import TextLinks from '../../components/TextLinks';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import NewsGalleries from '../../portlets/news/NewsGalleries';
import ShareBox from '../../components/ShareBox';
import Loaders from '../../Loaders.js';
import { TwitterTweetEmbed } from "react-twitter-embed";

function NewsDetails(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
        console.log('NewsDetails')
        console.log(params)
    }
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        let device = 'desktop'
        if (width < 1024) device = 'tablet'
        if (width < 768) device = 'mobile'
        return {
          width,
          height,
          device
        };
    }
    function generateTweetUrl(url) {
        const tweetPathname = new URL(url); 
        let tweetId = tweetPathname.pathname.split('/')[3]
        const embedUrl = `https://twitter.com${tweetPathname}`;
        return tweetId;
    }
    const { device, width } = getWindowDimensions()//useWindowDimensions();
    let id = params.block.path.split('/').pop();
        id = id.substring(0, id.length - 4);
        
    let backImage = '';
    let backImageMob = '';
    let image = (params.block['en']['Image'] && params.block['en']['Image'].path);

    if (image !== '' && image) {
        if (window.location.origin.includes('http://localhost') /* || isDevEnvironment*/ ) {
            image = 'https://test-widzew.qrqs.eu'+image;
            /*image = environmentUrl+image*/
        }
        backImageMob = image
        backImage = 'url('+image.replace('/sites/widzew', '')+'_width='+(width>1139?1140:width)+')';
    }
    else {
        if (window.location.origin.includes('http://localhost') /* || isDevEnvironment*/ ) {
            image = 'https://test-widzew.qrqs.eu'
            /*image = environmentUrl+image*/
        }
        backImage = 'url('+image+'/.templates/img/logo/herbwidzewa.jpg_width='+(width>1139?1140:width)+')';
        backImageMob = image+'/.templates/img/logo/herbwidzewa.jpg';
    }
    let currentTimestamp = new Date();
    if (params.block['en']['Date'] && params.block['en']['Date'] !== '') currentTimestamp = new Date(params.block['en']['Date']);
    let date = new Intl.DateTimeFormat('pl-PL', { dateStyle: 'full', timeStyle: 'short'} ).format(currentTimestamp);
        date = date.split(' ');

    let [catData, setCatData] = useState([]);
    useParams();
    useEffect(() => {
        let fetchCats = async () => {
            let url = null;
            if (window.location.origin.includes('cms-widzew')) {
                url = window.location.origin + '/.includes/categories/categories.json';
            }
            else if (window.location.origin.includes('http://localhost')) {
                url = 'https://test-widzew.qrqs.eu/.includes/categories/categories.json';
            }
            else {
                url = window.location.origin + '/.includes/categories/categories.json';
            }
            let response = '';
            try {
                response = await Axios(url);
            } catch (err) {
                response = 'error';
            }
            if (response.hasOwnProperty('data')) setCatData(response.data);
        };
        if (!window.location.pathname.includes('/media')) {
            fetchCats();
        }
    },[]);
    function Tags() {
        const splitTags = params.block['en']['Tags'].split(',');//useState?
        return (
            splitTags.map((tag) => {
                let name = catData[tag],
                    tagLast = tag.split('/'),
                    nameTag = tagLast[tagLast.length - 2];
                let pathOriginList = window.location.pathname.split('item/')[0],
                    href = pathOriginList+'#'+nameTag;
                if (name === '') name = nameTag;
                if (params.line === 'online') {
                    return (<Link key={href} to={href} className="tag-item c-black">{name}</Link>) 
                }
                else {
                    return (<a key={href} href={href} className="tag-item c-black" data-tags={tagLast[tagLast.length - 2]}>{name}</a>)  
                }
            })
        )
    }
    function Twitters() {
        return (
            params.block['en']['Twitter'].map((twit, index) => {
                if (twit.hasOwnProperty('TwitterLink')) {
                    if (twit['TwitterLink'] !== '') {
                        return (
                            <div class="twitter-wrapper mb-40">
                                <TwitterTweetEmbed
                                    tweetId={generateTweetUrl(twit['TwitterLink'])}
                                />
                            </div>
                        )
                    }
                }
                return null
            })
        )
    }
    function Videos() {
        return (
            params.block['en']['Video'].map((video, index) => {
                if (video.hasOwnProperty('YoutubeEmbed')) {
                    if (video['YoutubeEmbed'] !== '') {
                        return (
                            <div key={`video_`+index} className="video-wrapper mb-20">
                                <iframe width="560" 
                                        height="315" 
                                        src={video.YoutubeEmbed+`?origin=`+window.location.origin}
                                        title={params.block['en']['Title']}
                                        frameBorder="0" 
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                        allowFullScreen
                                >
                                </iframe>
                            </div>
                        )
                    }
                }
                return null
            })
        )
    }
    function Galleries(thumbW) {
        let listFolders = [];
        params.block['en']['Gallery'].map((gallery) => {
            if (gallery.hasOwnProperty('Folder')) {
                if (gallery['Folder'].hasOwnProperty('link')) {
                    if (gallery['Folder']['link'] !== '') {
                        let splitFolders = gallery['Folder']['link'];
                        if (splitFolders.includes('/sites/widzew')) {
                            splitFolders = splitFolders.split('/sites/widzew');
                            splitFolders = splitFolders[1];
                        }
                        listFolders.push(splitFolders)
                    }
                }
            }
            return null
        })
        listFolders = JSON.stringify(listFolders).replace('[','').replace(']','').replace(/"/g,'');
        return (<NewsGalleries folders={listFolders.split(',')} tW={thW(thumbW)}/>)
    }
    function Audios() {
        return (
            params.block['en']['Audio'].map((audio, index) => {
                if (audio.hasOwnProperty('URL')) {
                    if (audio['URL'] !== '') {
                        let audioUrl = '';
                        if (params.line === 'offline') {
                            audioUrl = audio['URL'] ? `${window.origin}/audio/${audio['URL']}` : '';
                        } else if (window.origin.includes('localhost')) {
                            audioUrl = audio['URL'] ? `https://test-widzew.qrqs.eu/audio/${audio['URL']}` : '';
                        } else {
                            audioUrl = audio['URL'] ? `${window.origin}/audio/${audio['URL']}` : '';
                        }
                        return (
                            <div key={`audio_`+index} className="audio-wrapper mb-20">
                                <div className="archives-player">
                                    {audio['URL'] &&
                                    <audio controls>
                                        <source src={audioUrl} type="audio/mpeg" />
                                        Twoja przeglądarka nie wspiera odtwarzacza audio.
                                    </audio>
                                    }
                                </div>
                            </div>
                        )
                    }
                }
                return null
            })
        )
    }
    function TopBox() {
        if (params.block['en']['MediaNews'] && params.block['en']['MediaNews'] === true) {
            return (
                <span className="cats-box d-flex ai-center jc-center media-logo">
                    {params.block['en']['MediaLogo'] && params.block['en']['MediaLogo'].hasOwnProperty('path') && params.block['en']['MediaLogo']['path'] !== '' 
                        ? <Image src={params.block['en']['MediaLogo'].path} width="115" alt={params.block['en']['Title']}/>
                        : ''
                    }
                </span>
            )
        }
        else {
            return (
                <div className="cats-box d-flex ai-center jc-center">
                    <span className="line b-black mr-20"></span>
                    {params.block['en']['Tags'] && 
                        <Tags/>
                    }
                </div>
            )
        }
    }
    function ImageBox() {
        if (device === 'mobile') {
            return (
                <div className={`container back-box mob-image`}>
                    <div className="image-box">
                        {backImageMob !== '' 
                            ?   <>
                                    <Loaders type="circle" color="b-white" mode="true"/>
                                    <Image src={backImageMob} width={width} alt={params.block['en']['Title']}/>
                                </>
                            :   <Image src="/.templates/img/logo/herbwidzewa.jpg" class="no-img-logo" width="400" alt="Widzew Łódź" />
                        }
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="container back-box" style={{backgroundImage: backImage}}></div>
            )
        }
    }
    function thW(params) {
        switch (device) {
            case 'desktop':
                return (params) > 1 ? 575 : 795
            case 'tablet':
                return (params) > 1 ? ((width-80)/2).toFixed(0) : ((width-80)*.75).toFixed(0)
            case 'mobile':
                return (width-30).toFixed(0)
            default:
                break;
        }
    }
    return (
        <section className="news-details">
            <div className="wrapper">
                <div className="top-banner-title hover-under">
                    <ImageBox />
                </div>
                <div className="container b-white">
                    <ShareBox id={id} link={window.location.href} mode="page" type="stable" />
                    <div className="top-box d-flex ai-center jc-between mb-15">
                        <TopBox />
                        {params.block['en']['Date'] && 
                            <div className="date-box d-flex ai-center jc-center">
                                <span className="day-box">
                                    {date[1]}
                                </span>
                                <span className="d-flex f-column">
                                    <span className="month-box">
                                        {date[2]}&nbsp;{date[3]}
                                    </span>
                                    <span className="time-box">
                                        {date[4] === 'o' ? date[5] : date[4]}
                                    </span>
                                </span>
                            </div>
                        }
                    </div>
                    {params.block['en']['Title'] &&
                        <div className="title-box">
                            <h1>{params.block['en']['Title']}</h1>
                        </div>
                    }
                    {params.block['en']['Teaser'] && 
                        <div className="text teaser mt-20">
                            <TextLinks text={params.block['en']['Teaser']} line={params.line} />
                        </div>
                    }
                    {params.block['en']['Text'] && 
                        <div className="text mt-20">
                            <TextLinks text={params.block['en']['Text']} line={params.line} />
                        </div>
                    }
                    {params.block['en']['Twitter'] && params.block['en']['Twitter'].length > 0 &&
                        <div class="twitter-box ta-center mt-30">
                            <Twitters />
                        </div>
                    }
                    {params.block['en']['Video'] && params.block['en']['Video'].length > 0 &&
                        <div className={`videos-box ta-center mt-40 d-flex` + (params.block['en']['Video'].length > 1 ? ' double' : '')}>
                            <Videos />
                        </div>
                    }
                    {params.block['en']['Audio'] && params.block['en']['Audio'].length > 0 &&
                        <div className={`audios-box ta-center d-flex ai-center f-wrap mt-30` + (params.block['en']['Audio'].length > 1 ? ' double' : '')}>
                            <Audios />
                        </div>
                    }
                    {params.block['en']['Gallery'] && params.block['en']['Gallery'].length > 0 &&
                        <div className={`galleries-box ta-center mt-40` + (params.block['en']['Gallery'].length > 1 ? ' double' : '')}>
                            <Galleries thumbW={params.block['en']['Gallery'].length}/>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}

export default NewsDetails;