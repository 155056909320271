import React, { useState } from "react";
import ImageGallery from 'react-image-gallery';
import Links from "../../components/Links";
import Image from "../../components/Image";

function Multimedia(params) {
    if (window.origin.includes('localhost')) {
        console.log('Multimedia')
        console.log(params)
    }

    const   [firstTab, setFirstTab] = useState('active'),
            [secondTab, setSecondTab] = useState(''),
            [firstTabActive, setFirstTabActive] = useState(''),
            [secondTabActive, setSecondTabActive] = useState('hidden'),
            [showGallery, setShowGallery] = useState('hidden'),
            [gallery, setGallery] = useState( new Array() );

    let [beforeScroll, setBeforeScroll] = useState(0);
    let videoSvg = `<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g id="Watching_Movies" data-name="Watching Movies"><path d="m41 6h-34a5.006 5.006 0 0 0 -5 5v21a5.006 5.006 0 0 0 5 5h12v3h-10a1 1 0 0 0 0 2h30a1 1 0 0 0 0-2h-10v-3h12a5.006 5.006 0 0 0 5-5v-21a5.006 5.006 0 0 0 -5-5zm-14 34h-6v-3h6zm17-8a3 3 0 0 1 -3 3h-34a3 3 0 0 1 -3-3v-21a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3z"/><path d="m27.977 19.768-4.952-2.859a2 2 0 0 0 -3 1.732v5.718a2 2 0 0 0 3 1.732l4.953-2.859a2 2 0 0 0 0-3.464zm-5.953 4.591v-5.718l4.952 2.859z"/><path d="m24 11a10.5 10.5 0 1 0 10.5 10.5 10.512 10.512 0 0 0 -10.5-10.5zm0 19a8.5 8.5 0 1 1 8.5-8.5 8.51 8.51 0 0 1 -8.5 8.5z"/></g></svg>`

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }

    let innerSection = 'multimedia';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    function renderGallerySlider(e) {
        setGallery(new Array())
        if (e) {
            let link = "";
            let linkImg = "";
            let linkImgThumbnail = "";
            if (params.line === 'offline') { 
                link = window.origin + '/json/sites/widzew' + e;
            } else if (window.origin.includes('localhost')) {
                link = 'https://test-widzew.qrqs.eu' + e + 'list.json';
            } else {
                link = window.origin + e + 'list.json';
            }
            fetch(link)
                .then(response => response.json())
                .then(data => {
                    const items = Object.values(data)
                    items.map(item => {
                        if (item.path.includes('/sites/widzew')) {
                            item.path = item.path.split('/sites/widzew');
                            item.path = item.path[1];
                        }
                        if (window.origin.includes('localhost')) {
                            linkImg = 'https://test-widzew.qrqs.eu' + item.path;
                            linkImgThumbnail = 'https:/test-widzew.qrqs.eu' + item.path + '_width=92';
                        } else {
                            linkImg = window.origin + item.path;
                            linkImgThumbnail = window.origin + item.path + '_width=92';
                        }

                        return setGallery( gallery, gallery.push({'original': linkImg+ '_width=1200', 'thumbnail': linkImgThumbnail,'fullscreen': linkImg, 'description': item.properties.Title, 'originalTitle': item.properties.Title, 'loading': 'lazy'}) )
                    })
                });
            setShowGallery('');

            setTimeout(function () {
                const   element = document.getElementById('gallery-div'),
                        header = document.getElementById('nav').offsetHeight,
                        y = element.getBoundingClientRect().top + window.pageYOffset - header - 40;
                setBeforeScroll(window.pageYOffset)
                window.scrollTo({top: y, behavior: 'smooth'});
            }, 500)
        } else {
            setShowGallery('hidden');
            setTimeout(function () {
                window.scrollTo({top: beforeScroll, behavior: 'smooth'});
            }, 100)
        }
    }

    function changeTabs() {
        setFirstTab('active');
        setFirstTabActive('');
        setSecondTab('');
        setSecondTabActive('hidden');
    }

    function changeTabsSecond() {
        setFirstTab('');
        setFirstTabActive('hidden');
        setSecondTab('active');
        setSecondTabActive('');
    }

    let innerClass = "d-flex f-column " + innerContainer;

    function renderVideoItems(number) {
        let group = params.block['en']['VideoItem'];
            
        return group.map((item, i) => {
            let date = item.VideoDate;
            let linkYT = 'https://youtube.com/'
            if (item.hasOwnProperty('VideoUrl')) {
                if (item['VideoUrl'].hasOwnProperty('link')) {
                    linkYT = item['VideoUrl']['link']
                }
            }
            const url = new URL(linkYT);
            const idThumbnail = url.searchParams.get("v");
            const linkVideoThumbnail = "https://i1.ytimg.com/vi/" + idThumbnail + '/maxresdefault.jpg'
            
            let currentTimestamp = new Date(date)
            let dates = new Intl.DateTimeFormat('pl-PL', { dateStyle: 'full', timeStyle: 'short'} ).format(currentTimestamp)
                dates = dates.split(" ");

            if (i === 0 && number === 'first') {
                return (
                    <div className="d-flex mt-30 ml-30 mb-30 mr-20 thumbnail" key={'video-'+i}>
                        {linkYT &&
                            <Links line={params.line} url={linkYT} target="true" class="all-link" name="Youtube Video" />
                        }
                        {linkYT && linkVideoThumbnail && 
                            <Image src={linkVideoThumbnail} class="thumbnail-home" alt="miniatura" />
                        }
                        <div className="d-flex ff-column pl-30 pt-30 h-100 jc-between">
                            <div>
                                {dates &&
                                    <span className="date d-flex">
                                        <span className="day"><p>{dates[1]}</p></span>
                                        <span className="msc-hours"><p>{dates[2]} {dates[3]}</p><p>{dates[4] === 'o' ? dates[5] : dates[4]}</p></span>
                                    </span>
                                }
                            </div>
                            <div className="mb-20">
                                    <div className="video-icon mb-20"
                                        dangerouslySetInnerHTML= {
                                            { __html: videoSvg }
                                        }
                                    ></div>
                                <div className="chip-tag d-flex f-row mb-20">
                                    {item.VideoTag &&
                                        <p className="tag d-flex ai-center">{item.VideoTag}</p>
                                    }
                                </div>
                                <div className="d-flex f-row pr-30">
                                    {item.VideoTitle &&
                                        <h3>
                                            {item.VideoTitle}
                                        </h3>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (i >= 1 && number === 'other') {
                return (
                    <div className="item mb-20" key={'video-'+i}>
                        {linkYT &&
                            <Links line={params.line} url={linkYT} target="true" class="all-link" name="Youtube Video" />
                        }
                        <div className="d-flex">
                            {linkYT && linkVideoThumbnail &&  
                                <Image src={linkVideoThumbnail} class="mini-img mr-30" alt="miniatura" />
                            }
                            <div>
                                {dates &&
                                    <span className="date d-flex">
                                        <span className="day"><p>{dates[1]}</p></span>
                                        <span className="msc-hours"><p>{dates[2]} {dates[3]}</p><p>{dates[4] === 'o' ? dates[5] : dates[4]}</p></span>
                                    </span>
                                }
                                <div className="chip-tag d-flex f-row">
                                    {item.VideoTag &&
                                        <p className="tag d-flex ai-center">{item.VideoTag}</p>
                                    }
                                </div>
                                <div className="d-flex f-row">
                                    {item.VideoTitle &&
                                        <h3>
                                            {item.VideoTitle}
                                        </h3>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } return null;
        })
    }

    function renderGalleryItems(number) {
        
        let group = params.block['en']['GalleryItem'];
            
        return group.map((item, i) => {
            let date = item.GalleryDate;
            let GalleryUrl = '';
            let linkGallery = '';

            if (item.GalleryThumbnail.hasOwnProperty('path')) {
                linkGallery = item.GalleryThumbnail.path;
            }

            if (linkGallery !== '' && linkGallery.includes("/sites/widzew")) {
                linkGallery = linkGallery.split('/sites/widzew');
                linkGallery = linkGallery[1];
            }

            if (item.GalleryUrl.hasOwnProperty('path')) {
                GalleryUrl = item.GalleryUrl.path
            }

            if (GalleryUrl !== '' && GalleryUrl.includes("/sites/widzew")) {
                GalleryUrl = GalleryUrl.split('/sites/widzew');
                GalleryUrl = GalleryUrl[1];
            }

            let currentTimestamp2 = new Date(date)
            let dates = new Intl.DateTimeFormat('pl-PL', { dateStyle: 'full', timeStyle: 'short'} ).format(currentTimestamp2)
                dates = dates.split(" ");
    
            if (i === 0 && number === 'first') {
                return (
                    <div className="d-flex mt-30 ml-30 mb-30 mr-20 thumbnail" key={'gallery-'+i}>
                        {GalleryUrl !== "" &&
                            <span onClick={() => renderGallerySlider(GalleryUrl)} className="gallery-url clean h-100 w-100">
                            {linkGallery !== "" &&
                                <Image src={linkGallery} class="thumbnail-home" width="490" alt="miniatura" />
                            }
                                <div className="d-flex ff-column pl-30 pt-30 h-100 jc-between">
                                    <div>
                                        {dates &&
                                            <span className="date d-flex">
                                                <span className="day"><p>{dates[1]}</p></span>
                                                <span className="msc-hours"><p>{dates[2]} {dates[3]}</p><p>{dates[4] === 'o' ? dates[5] : dates[4]}</p></span>
                                            </span>
                                        }
                                    </div>
                                    <div className="mb-20">
                                        <div className="gallery-icon mb-20"></div>
                                        <div className="chip-tag d-flex f-row mb-20">
                                            {item.GalleryTag &&
                                                <p className="tag d-flex ai-center">{item.GalleryTag}</p>
                                            }
                                        </div>
                                        <div className="d-flex f-row pr-30">
                                            {item.GalleryTitle &&
                                                <h3>
                                                    {item.GalleryTitle}
                                                </h3>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </span>
                        }
                    </div>
                )
            } else if (i >= 1 && number === 'other') {
                return (
                    <div className="item mb-20" key={'gallery-'+i}>
                        {GalleryUrl !== "" &&
                            <span onClick={() => renderGallerySlider(GalleryUrl)} className="gallery-url clean h-100 w-100">
                                <div className="d-flex">
                                {linkGallery !== "" &&
                                    <Image src={linkGallery} class="mini-img mr-30" width="150" alt="miniatura" />
                                }
                                    <div>
                                        {dates &&
                                            <span className="date d-flex">
                                                <span className="day"><p>{dates[1]}</p></span>
                                                <span className="msc-hours"><p>{dates[2]} {dates[3]}</p><p>{dates[4] === 'o' ? dates[5] : dates[4]}</p></span>
                                            </span>
                                        }
                                        <div className="chip-tag d-flex f-row">
                                            {item.GalleryTag &&
                                                <p className="tag d-flex ai-center">{item.GalleryTag}</p>
                                            }
                                        </div>
                                        <div className="d-flex f-row">
                                            {item.GalleryTitle &&
                                                <h3>
                                                    {item.GalleryTitle}
                                                </h3>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </span>
                        }
                    </div>
                )
            }
            return null;
        })
    }

    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerClass}>
                    <h1 className="d-flex f-column jc-center">{params.block['en']['Title']}</h1>
                    <div className="d-flex jc-between pt-30">
                        <div className="tabs-button d-flex ml-30">
                        <span className={'pl-20 pr-20 pt-10 pb-10 ' + firstTab} onClick={() => changeTabs()}><p>WIDZEW TV</p></span>
                            <span className={'pl-20 pr-20 pt-10 pb-10 ' + secondTab} onClick={() => changeTabsSecond()}><p>GALERIE</p></span>
                        </div>
                        <div className="youtube-subscribe d-flex ai-center mr-30">
                            {params.block['en']['YoutubeChannel'].hasOwnProperty('link') &&
                                <a href={params.block['en']['YoutubeChannel'].link} target="_blank" rel="noreferrer" className="clean d-flex">
                                    <div className="subs-icon"></div><p className="d-flex ai-center">SUBSKRYBUJ NASZ KANAŁ</p>
                                </a>
                            }
                        </div>
                    </div>
                    <div className={'d-flex col-12 b-red video ' + firstTabActive}>
                        {renderVideoItems('first')}
                        <div className='w-50 mt-30 mb-30 mr-30 ff-column right'>
                            {renderVideoItems('other')}
                            <div className="d-flex jc-end">
                                {params.block['en']['MoreVideoUrl'] && params.block['en']['MoreVideoText'] &&
                                    <Links line={params.line} url={params.block['en']['MoreVideoUrl']} class="clean button empty-white" name={params.block['en']['MoreVideoText']}/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex col-12 b-red gallery ' + secondTabActive}>
                        {renderGalleryItems('first')}
                        <div className='w-50 mt-30 mb-30 mr-30 ff-column right'>
                            {renderGalleryItems('other')}
                            <div className="d-flex jc-end">
                                {params.block['en']['MoreGalleryUrl'] && params.block['en']['MoreGalleryText'] &&
                                    <Links line={params.line} url={params.block['en']['MoreGalleryUrl']} class="clean button empty-white" name={params.block['en']['MoreGalleryText']}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'gallery-overlay ' + showGallery} onClick={() => renderGallerySlider()}></div> 
                {gallery && <div id="gallery-div" className={'gallery-div container ' + showGallery}>
                    <ImageGallery items={gallery} showIndex={true} lazyLoad={true} originalHeight={600}/>
                </div>}
            </div>
        </section>
    );
}

export default Multimedia;