import React from "react";
import MembersTeaser from "../../portlets/members/MembersTeaser";
const TeaserComponents = {
    "members-details": MembersTeaser,
};

function MembersList(params) {
    if (window.origin.includes('localhost')) {
        console.log('MembersList')
        console.log(params)
    }

    function renderGroups() {
        if (params.block.list[0].en.Position == 'br' || params.block.list[0].en.Position == 'mid' || params.block.list[0].en.Position == 'def' || params.block.list[0].en.Position == 'forward') {
            return (
                <div className="list-groups-members col-12">
                    <div className="br-group d-flex f-wrap mt-40 col-12">
                        <h2>Bramkarze</h2>
                        <div className="items d-flex f-wrap col-12">
                            {sortMembers('br')}
                        </div>
                    </div>
                    <div className="def-group d-flex f-wrap mt-40 col-12">
                        <h2>Obrońcy</h2>
                        <div className="items d-flex f-wrap col-12">
                            {sortMembers('def')}
                        </div>
                    </div>
                    <div className="mid-group d-flex f-wrap mt-40 col-12">
                        <h2>Pomocnicy</h2>
                        <div className="items d-flex f-wrap col-12">
                            {sortMembers('mid')}
                        </div>
                    </div>
                    <div className="forward-group d-flex f-wrap mt-40 col-12">
                        <h2>Napastnicy</h2>
                        <div className="items d-flex f-wrap col-12">
                            {sortMembers('forward')}
                        </div>
                    </div>
                </div>
            )
        } else {
            {return params.block.list.map((teaser, i) => {
                let type = teaser.attributes.type
                if (TeaserComponents.hasOwnProperty(type)) {
                    let key = teaser.path.split("/").pop();
                    key = key.substring(0, key.length - 4);
                    return (
                        <li className="list-entry" key={key + '_' + i}>
                            {/*portlet place*/}
                            {React.createElement(TeaserComponents[type], {
                                key: key + '_' + i,
                                block: teaser,
                                line: params.line
                            })}
                        </li>
                    )
                }
                return null
            })}
        }
    }

    function sortMembers(position) {
        let array = params.block.list

        array.sort(function(a, b){
            return parseFloat(a['en']['Number']) - parseFloat(b['en']['Number'])
        });

        {return array.map((teaser, i) => {
            let type = teaser.attributes.type
                if (TeaserComponents.hasOwnProperty(type)) {
                    let key = teaser.path.split("/").pop();
                    key = key.substring(0, key.length - 4);
                    if (teaser.en.Position == position) {
                        return (
                            <li className="list-entry" key={key + '_' + i}>
                                {/*portlet place*/}
                                {React.createElement(TeaserComponents[type], {
                                    key: key + '_' + i,
                                    block: teaser,
                                    line: params.line
                                })}
                            </li>
                        )
                    } else {
                        return null;
                    }
                }
                return null
            
        })}
    }

    return (
        <div className="list-box list-dynamic list-paginate">
            <ul className="list-entries d-flex f-wrap ">
                {renderGroups()}
            </ul>
            <div className="list-pagination "></div>
        </div>
    );
}

export default MembersList;