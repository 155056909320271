import React from 'react'
import Image from '../../components/Image';
import Links from '../../components/Links';

const TimetableDetailsBanner = ({ params, matchInProgressScore }) => {

  const svgTicket = `
    <svg id="ticket-icon" height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg" style="fill: #000;"><path d="m21.5 22h-19c-1.378 0-2.5-1.122-2.5-2.5v-2c0-.276.224-.5.5-.5h.5c1.103 0 2-.897 2-2s-.897-2-2-2h-.5c-.276 0-.5-.224-.5-.5v-2c0-1.378 1.122-2.5 2.5-2.5h19c1.378 0 2.5 1.122 2.5 2.5v2c0 .276-.224.5-.5.5h-.5c-1.103 0-2 .897-2 2 0 .534.208 1.036.586 1.414.377.378.879.586 1.413.586h.5c.276 0 .5.224.5.5v2c.001 1.378-1.121 2.5-2.499 2.5zm-20.5-4v1.5c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-1.5h-.001c-.801 0-1.554-.312-2.12-.878-.567-.567-.879-1.321-.879-2.122 0-1.654 1.346-3 3-3v-1.5c0-.827-.673-1.5-1.5-1.5h-19c-.827 0-1.5.673-1.5 1.5v1.5c1.654 0 3 1.346 3 3s-1.346 3-3 3z"/><path d="m8.5 10c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5s.5.224.5.5v1c0 .276-.224.5-.5.5z"/><path d="m8.5 18.461c-.276 0-.5-.224-.5-.5v-1.691c0-.276.224-.5.5-.5s.5.223.5.5v1.692c0 .276-.224.499-.5.499zm0-4.23c-.276 0-.5-.224-.5-.5v-1.692c0-.276.224-.5.5-.5s.5.224.5.5v1.692c0 .276-.224.5-.5.5z"/><path d="m8.5 22c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5s.5.224.5.5v1c0 .276-.224.5-.5.5z"/><path d="m2.5 9c-.203 0-.394-.124-.468-.325-.097-.259.034-.547.293-.643l15.9-5.937c.773-.29 1.681.144 1.945.923l1.803 5.321c.088.262-.052.545-.313.634-.259.089-.545-.052-.634-.313l-1.803-5.321c-.088-.26-.39-.402-.649-.308l-15.9 5.937c-.057.022-.116.032-.174.032z"/></svg>
  `;
  const svgMarket = `
    <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg" style="fill: #000;" id="bag-icon-id"><g id="bag-icon" data-name="bag-icon"><path d="m16 1a6 6 0 0 0 -6 6v1h-.83a3.27 3.27 0 0 0 -3.27 3.12l-.79 16.45a3.28 3.28 0 0 0 3.27 3.43h15.24a3.28 3.28 0 0 0 3.27-3.43l-.79-16.45a3.27 3.27 0 0 0 -3.27-3.12h-.83v-1a6 6 0 0 0 -6-6zm-4 6a4 4 0 0 1 8 0v1h-8zm12.1 4.21.79 16.46a1.31 1.31 0 0 1 -.35.94 1.29 1.29 0 0 1 -.92.39h-15.24a1.29 1.29 0 0 1 -.92-.39 1.31 1.31 0 0 1 -.35-.94l.79-16.46a1.27 1.27 0 0 1 1.27-1.21h13.66a1.27 1.27 0 0 1 1.27 1.21z"/></g></svg>
  `;
  const svgTV = `
    <svg height="36" fill="#000" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g id="Watching_Movies" data-name="Watching Movies"><path d="m41 6h-34a5.006 5.006 0 0 0 -5 5v21a5.006 5.006 0 0 0 5 5h12v3h-10a1 1 0 0 0 0 2h30a1 1 0 0 0 0-2h-10v-3h12a5.006 5.006 0 0 0 5-5v-21a5.006 5.006 0 0 0 -5-5zm-14 34h-6v-3h6zm17-8a3 3 0 0 1 -3 3h-34a3 3 0 0 1 -3-3v-21a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3z"/><path d="m27.977 19.768-4.952-2.859a2 2 0 0 0 -3 1.732v5.718a2 2 0 0 0 3 1.732l4.953-2.859a2 2 0 0 0 0-3.464zm-5.953 4.591v-5.718l4.952 2.859z"/><path d="m24 11a10.5 10.5 0 1 0 10.5 10.5 10.512 10.512 0 0 0 -10.5-10.5zm0 19a8.5 8.5 0 1 1 8.5-8.5 8.51 8.51 0 0 1 -8.5 8.5z"/></g></svg>
  `;

  let link = "";
  if (params.line === 'offline') {
    link = window.origin + '/images/teams-logos/logos_png/widzew_lodz.png';
  } else if (window.origin.includes('localhost')) {
    link = 'https://test-widzew.qrqs.eu/images/teams-logos/logos_png/widzew_lodz.png';
  } else {
    link = window.origin + '/images/teams-logos/logos_png/widzew_lodz.png';
  }

  let isHome = null;
  if (params.block.hasOwnProperty('en')) {
    if (params.block['en'].hasOwnProperty('MatchType')) {
      isHome = params.block['en'].MatchType ? params.block['en'].MatchType === 'home' : false;
    }
  }
  function getMatchDate(x) {
    let startTime = x && new Date(x);
    const yyyy = startTime.getFullYear();
    let mm = startTime.getMonth() + 1;
    let dd = startTime.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    startTime = dd + '.' + mm + '.' + yyyy;
    return startTime;
  }

  let away = null
  const events = [];
  function getSquad() {
    matchInProgressScore && matchInProgressScore.map(item => {
      item.events.map(event => {
        events.push(event)
        return null
      })
      away = item.teams.away.id
      return null
    })
  }
  getSquad();

  function generateName(event, time, player, details, left) {
    switch (event.toLowerCase()) {
      case "goal":
        if (details.toLowerCase() === "missed penalty") {
          return (
            <>
            </>
          )
        } else {
          if (left) {
            return (
              <>
                <span>{player} {time}'</span>
              </>
            )
          } else {
            return (
              <>
                <span>{time}' {player}</span>
              </>
            )
          }

        }
    }
  }

  const matchSponsorAlt = `Sponsor meczu ${params['block']['en'].OpponentName ? 'Widzew Łódź - ' + params['block']['en'].OpponentName : ''}`

  let noAnimationClass;
  noAnimationClass = matchInProgressScore && matchInProgressScore[0].fixture.status.elapsed && (matchInProgressScore[0].fixture.status.short === "FT" || matchInProgressScore[0].fixture.status.short === "AET" || matchInProgressScore[0].fixture.status.short === "PEN") ? "no-animation" : ""
  return (
    <section className="timetable-details-banner">
      <div className="wrapper">
        <div className="d-flex f-column jc-start back-box">
          <div className="timetable-details-wrapper container d-flex jc-center f-column">
            <div className="timeplay-top d-flex w-100">
              <div className={matchInProgressScore && (matchInProgressScore[0].fixture.status.elapsed || matchInProgressScore[0].fixture.status.short === "BT") ? "w-40 d-flex jc-end ai-center team-item f-wrap ac-center live" : "w-40 d-flex jc-end ai-center team-item f-wrap ac-center"} >
                <div className='team-information d-flex ai-center jc-center js-center left'>
                  {isHome &&
                    <>
                      <h3>
                        Widzew Łódź
                      </h3>
                      <img src={link} alt="Widzew Łódź" />
                    </>
                  }
                  {!isHome &&
                    <>
                      <h3>
                        {params['block']['en'].OpponentName && params['block']['en'].OpponentName}
                      </h3>
                      {(params.block['en'].OpponentCrest.path && params['block']['en'].OpponentName) &&
                        <Image src={params.block['en'].OpponentCrest.path} altText={params.block['en'].OpponentName} />
                      }
                    </>
                  }
                </div>
                <div className='goals-players left'>
                  {events.map((i, index) => {
                    return (
                      <>
                        {away !== i.team.id && generateName(i.type, i.time.elapsed, i.player.name, i.detail, 'left')}
                      </>
                    )
                  })}
                </div>
              </div>
              <div className={matchInProgressScore && (matchInProgressScore[0].fixture.status.elapsed || matchInProgressScore[0].fixture.status.short === "BT") ? 'date-container d-flex f-column ai-center jc-center w-30 ml-20 mr-20 live' : "date-container d-flex f-column ai-center jc-center w-30 ml-20 mr-20"}>
                {params['block']['en'].DateOfGame &&
                  <span className={matchInProgressScore && (matchInProgressScore[0].fixture.status.elapsed || matchInProgressScore[0].fixture.status.short === "BT") ? 'date-of-game live' : "date-of-game"}>{getMatchDate(params['block']['en'].DateOfGame)}</span>
                }
                {params['block']['en'].IsFinished === false &&
                  <span className={matchInProgressScore && (matchInProgressScore[0].fixture.status.elapsed || matchInProgressScore[0].fixture.status.short === "BT") ? 'timetable-time mt-10 mb-10 c-white live' : "timetable-time mt-10 mb-10 c-white"}>{params['block']['en'].StartTime && params['block']['en'].StartTime}</span>
                }
                {matchInProgressScore && matchInProgressScore[0].fixture.status.elapsed &&
                  <span className={`timetable-live-time ${noAnimationClass ?? noAnimationClass} c-gold`}>{matchInProgressScore && matchInProgressScore[0].fixture.status.elapsed && (matchInProgressScore[0].fixture.status.short === "FT" || matchInProgressScore[0].fixture.status.short === "AET" || matchInProgressScore[0].fixture.status.short === "PEN") ? "KONIEC" : matchInProgressScore[0].fixture.status.elapsed + "'"}</span>
                }
                {matchInProgressScore && matchInProgressScore[0].fixture.status.short === "BT" &&
                  <span className="timetable-live-time c-gold">{matchInProgressScore && matchInProgressScore[0].fixture.status.short === "BT" ? "PRZERWA" : matchInProgressScore[0].fixture.status.elapsed + "'"}</span>
                }
                {matchInProgressScore && matchInProgressScore[0].goals && matchInProgressScore[0].fixture.status.short !== "NS" && matchInProgressScore[0].fixture.status.short !== "FT" && matchInProgressScore[0].fixture.status.short !== "AET" && params['block']['en'].IsFinished === false &&
                  <span className="timetable-live-results mt-10 mb-10 c-gold">{matchInProgressScore[0].goals.home} : {matchInProgressScore[0].goals.away}</span>
                }
                {matchInProgressScore && (matchInProgressScore[0].fixture.status.short === "FT" || matchInProgressScore[0].fixture.status.short === "AET" || matchInProgressScore[0].fixture.status.short === "PEN") && params['block']['en'].IsFinished === false &&
                  <span className="timetable-live-results timetable-result">{matchInProgressScore[0].goals.home} : {matchInProgressScore[0].goals.away}</span>
                }
                {params['block']['en'].IsFinished === true &&
                  <span className="timetable-result">{params['block']['en'].MatchResult && params['block']['en'].MatchResult}</span>
                }
                {params['block']['en'].StadiumName &&
                  <small
                    dangerouslySetInnerHTML={
                      { __html: params['block']['en'].StadiumName }
                    }
                  >
                  </small>
                }
              </div>
              <div className={matchInProgressScore && (matchInProgressScore[0].fixture.status.elapsed || matchInProgressScore[0].fixture.status.short === "BT") ? "w-40 d-flex jc-start ai-center team-item f-wrap ac-center live" : "w-40 d-flex jc-start ai-center team-item f-wrap ac-center"} >
                <div className='team-information d-flex ai-center jc-center js-center right'>
                  {isHome &&
                    <>
                      {(params.block['en'].OpponentCrest.path && params.block['en'].OpponentName) &&
                        <Image src={params.block['en'].OpponentCrest.path} altText={params.block['en'].OpponentName} />
                      }
                      <h3>
                        {params['block']['en'].OpponentName && params['block']['en'].OpponentName}
                      </h3>
                    </>
                  }
                  {!isHome &&
                    <>
                      <img src={link} alt="Widzew Łódź" />
                      <h3>
                        Widzew Łódź
                      </h3>
                    </>
                  }
                </div>
                <div className='goals-players right'>
                  {events.map((i, index) => {
                    return (
                      <>
                        {away === i.team.id && generateName(i.type, i.time.elapsed, i.player.name, i.detail)}
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="timetable-bottom d-flex jc-between ai-center mt-20">
              <div className="timetable-buttons jc-center w-100">
                {params['block']['en'].TicketPurchasePage &&
                  //<Links line={params.line} url={params['block']['en'].TicketPurchasePage} target="true" class="button b-gold" name="BILETY" />
                  <a href={params['block']['en'].TicketPurchasePage} target="_blank" rel="noreferrer" className="button b-gold gi-button d-flex ai-center jc-center">
                    <span className="gi-text-h">
                      BILETY
                    </span>
                    <span className="svgTicket inner-icon"
                      dangerouslySetInnerHTML={
                        { __html: svgTicket }
                      }
                    >
                    </span>
                    <span className="gi-text">
                      BILETY
                    </span>
                  </a>
                }
                {params['block']['en'].BroadcastPurchasePage &&
                  //<Links line={params.line} url={params['block']['en'].BroadcastPurchasePage} target="true" class="button b-gold" name="TRANSMISJA" />
                  <a href={params['block']['en'].BroadcastPurchasePage} target="_blank" rel="noreferrer" className="button b-gold gi-button d-flex ai-center jc-center">
                    <span className="gi-text-h">
                      TRANSMISJA
                    </span>
                    <span className="svgTV inner-icon"
                      dangerouslySetInnerHTML={
                        { __html: svgTV }
                      }
                    >
                    </span>
                    <span className="gi-text">
                      TRANSMISJA
                    </span>
                  </a>
                }
                {params['block']['en'].ProductPurchasePage &&
                  //<Links line={params.line} url={params['block']['en'].ProductPurchasePage} target="true" class="button b-gold" name="Sklep" />
                  <a href={params['block']['en'].ProductPurchasePage} target="_blank" rel="noreferrer" className="button b-gold gi-button d-flex ai-center jc-center">
                    <span className="gi-text-h">
                      SKLEP
                    </span>
                    <span className="svgMarket inner-icon"
                      dangerouslySetInnerHTML={
                        { __html: svgMarket }
                      }
                    >
                    </span>
                    <span className="gi-text">
                      SKLEP
                    </span>
                  </a>
                }
                {(params['block']['en'].CustomButtonLink && params['block']['en'].CustomButtonName) &&
                  <Links line={params.line} url={params['block']['en'].CustomButtonLink} target="true" class="button b-gold custom-button-timetable" name={params['block']['en'].CustomButtonName} />
                }
              </div>
            </div>
            <div className="timetable-bottom-logos d-flex jc-between ai-center mt-20">
              <div className="d-flex jc-start competition-logo">
                {(params['block']['en'].CompetitionLogoWhite && params['block']['en'].CompetitionLogoWhite.path) &&
                  <Image src={params['block']['en'].CompetitionLogoWhite.path} alt={params['block']['en'].CompetitionName} />
                }
              </div>
              <div className="d-flex jc-end competition-logo">
                {(params['block']['en'].MatchSponsorLogoWhite && params['block']['en'].MatchSponsorLogoWhite.path) &&
                  <>
                    {params['block']['en'].MatchSponsorLink && params['block']['en'].MatchSponsorLink.link &&
                      <a href={params['block']['en'].MatchSponsorLink.link} target="_blank" title={matchSponsorAlt} rel="noreferrer noopener">
                        <Image src={params['block']['en'].MatchSponsorLogoWhite.path} alt={matchSponsorAlt} />
                      </a>
                    }
                    {!params['block']['en'].MatchSponsorLink && !params['block']['en'].MatchSponsorLink.link &&
                      <Image src={params['block']['en'].MatchSponsorLogoWhite.path} alt={matchSponsorAlt} />
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TimetableDetailsBanner