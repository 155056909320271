import React from "react";
import { createRoot } from "react-dom/client";

import TopBannerCarousel from "./portlets/topBannerCarousel/TopBannerCarousel";
import TopBannerTitle from "./portlets/topBannerTitle/TopBannerTitle";
import Achievements from "./portlets/achievements/Achievements";
import Academy from "./portlets/academy/Academy";
import Sponsors from "./portlets/sponsors/Sponsors";
import TextBox from "./portlets/textBox/TextBox";
import ElfsightVideos from "./portlets/elfsightVideos/ElfsightVideos";
import VideoBox from "./portlets/video-box/VideoBox";
import SponsorsCarousel from "./portlets/sponsorsCarousel/SponsorsCarousel";
import ManagementBox from "./portlets/managementBox/ManagementBox";
import StaffBox from "./portlets/staffBox/StaffBox";
import ShopBox from "./portlets/shopBox/ShopBox";
import PartnersClub from "./portlets/partnersClub/PartnersClub";
import ListBox from "./portlets/listBox/ListBox";
import NewsTeaser from "./portlets/news/NewsTeaser";
import MembersTeaser from "./portlets/members/MembersTeaser";
import TimetableTeaser from "./portlets/timetable/TimetableTeaser";
import NewsDetails from "./portlets/news/NewsDetails";
import MembersDetails from "./portlets/members/MembersDetails";
import TimetableDetails from "./portlets/timetable/TimetableDetails";
import GameplayTable from "./portlets/gameplayTable/GameplayTable";
import Button from "./portlets/buttonBox/Button";
import ImageBox from "./portlets/imageBox/ImageBox";
import Multimedia from "./portlets/multimedia/Multimedia";
import FormBox from "./portlets/formBox/FormBox";
import Galleries from "./portlets/galleries/Galleries";
import ImageCarousel from "./portlets/imageCarousel/ImageCarousel";
import SponsorsList from "./portlets/sponsorsList/SponsorsList"
import MembersCarousel from "./portlets/members-carousel/MembersCarousel";
import ShirtCreator from "./portlets/shirtCreator/ShirtCreator";
import WidzewFmSchedule from "./portlets/widzewFmSchedule/WidzewFmSchedule"
import NewsLatest from "./portlets/newsLatest/NewsLatest";
import NewsCarousel from "./portlets/newsCarousel/NewsCarousel";
import WidzewFmArchives from "./portlets/widzewFmArchives/WidzewFmArchives";
import IframeScriptIncluder from "./portlets/iframeScriptIncluder/IframeScriptIncluder";
import ContactForm from "./portlets/contactForm/ContactForm";
import TextImage from "./portlets/textImage/TextImage";
import ChipItemBox from "./portlets/chipItemBox/ChipItemBox";
import SearchBox from "./portlets/searchBox/SearchBox";

const Components = {
    "top-banner-carousel": TopBannerCarousel,
    "top-banner-title": TopBannerTitle,
    "text-box": TextBox,
    "achievements": Achievements,
    "sponsors": Sponsors,
    "elfsight-videos": ElfsightVideos,
    "video-box": VideoBox,
    "academy": Academy,
    "management-box": ManagementBox,
    "staff-box": StaffBox,
    "sponsors-carousel": SponsorsCarousel,
    "shop-box": ShopBox,
    "partners-club": PartnersClub,
    "news-details": NewsDetails,
    "members-details": MembersDetails,
    "timetable-details": TimetableDetails,
    "news-details-teaser": NewsTeaser,
    "members-details-teaser": MembersTeaser,
    "timetable-details-teaser": TimetableTeaser,
    "gameplay-table": GameplayTable,
    "button": Button,
    "image-box": ImageBox,
    "multimedia": Multimedia,
    "form-box": FormBox,
    "galleries": Galleries,
    "image-carousel": ImageCarousel,
    "sponsors-list": SponsorsList,
    "members-carousel": MembersCarousel,
    "shirt-creator": ShirtCreator,
    "widzew-fm-schedule": WidzewFmSchedule,
    "news-latest": NewsLatest,
    "news-carousel": NewsCarousel,
    "widzew-fm-archives": WidzewFmArchives,
    "iframe-script-includer": IframeScriptIncluder,
    "contact-form": ContactForm,
    "text-image": TextImage,
    "chip-item-box": ChipItemBox,
    "search-box": SearchBox
};

function Portlets(line, block, pageData, index, locale) {
    if (line === 'online') {
        if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
            console.log('Portlets list www')
            console.log(block)
        }
        if (typeof pageData !== "undefined" && !block.hasOwnProperty('exception')) {
            if (block.hasOwnProperty('listInfo')) {
                if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
                    console.log('potlet list box');
                }
                //must be wrapped into LIST portlet
                let key = pageData.split("/").pop();
                key = key.substring(0, key.length - 4);
                return <ListBox block={block} line={line} key={key + '_' + index} />
            }
            else {
                if (block.hasOwnProperty('attributes') && block.attributes.hasOwnProperty('type')) {
                    let type = block.attributes.type
                    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
                        console.log('portlet type - ' + type)
                    }
                    if (Components.hasOwnProperty(type)) {
                        let key = pageData.split("/").pop();
                        key = key.substring(0, key.length - 4);
                        return React.createElement(Components[type], {
                            key: key + '_' + index,
                            block: block,
                            line: line
                        });
                    }
                }
                else {console.error('portlet type - is undefined')}
            }
        }
    }
    if (line === 'offline') {
        function CreateComp(portlet, name, index) {
            const Portlet = Components[name];
            return <Portlet block={portlet} key={index} line={line} />;
        }
        let listComp = []
        block.map((cont, i) => {
            //const type = cont.type; //get container info
            //const id = cont.name; //may be usefull later
            cont.elements.map((item, ii) => {
                const uuid = item.formatterKey;
                const path = item.path;
                listComp.push({ uuid, path })
                return null;
            })
            return null;
        })
        if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
            console.log('Portlets list cms')
            console.log(pageData.linkedContents)
        }

        listComp.map((elem, i) => {
            let portlet = pageData.linkedContents[elem.path]
            if (!portlet.hasOwnProperty('exception')) {
                if (portlet.hasOwnProperty('list')) {
                    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
                        console.log('potlet list box');
                    }
                    portlet.list.map((teaser, i) => {
                        let type = teaser.attributes.type;
                        if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
                            console.log('portlet type - ' + type)
                        }
                        if (Components.hasOwnProperty(type)) {
                            let id = teaser.path.split("/").pop();
                            id = id.substring(0, id.length - 4);
                            let teaserId = document.getElementById(id);
                            if (teaserId) {
                                let box = createRoot(teaserId);
                                box.render(
                                    CreateComp(teaser, type + '-teaser', id + "_" + i)
                                )
                            }
                        }
                        return null;
                    })
                }
                else {
                    if (portlet.hasOwnProperty('attributes')) {
                        if (portlet.attributes.hasOwnProperty('type')) {
                            let type = portlet.attributes.type;
                            if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
                                console.log('portlet type - ' + type)
                            }
                            if (Components.hasOwnProperty(type)) {
                                let id = elem.path.split("/").pop();
                                id = id.substring(0, id.length - 4);
                                let portletId = document.getElementById(id);
                                if (portletId) {
                                    let box = createRoot(portletId);
                                    box.render(
                                        CreateComp(portlet, type, id + "_" + i)
                                    )
                                }
                            }
                        }
                    }
                }
            }
            return null;
        });
    }
};
export default Portlets;