import React from "react";
import Svg from "./components/Svg";
import { Link } from "react-router-dom";
function SearchField(params) {
    return (
      <div id="search">
        <Link to="/wyniki-wyszukiwania/">
          <Svg name="SearchIcon" />
        </Link>
        
        <form readonly id="search-form" method="GET" action="/wyniki-wyszukiwan/" role="search">
          <input className="placeholder" type="text" name="q" title="...Szukaj..." placeholder="...Szukaj..." autocomplete="off"/>
          <button className="search-button" name="s" type="submit" title="Szukaj w serwisie">
            <span className="offscreen">Szukaj w serwisie</span>
          </button>
        </form>
      </div>
    )
}

export default SearchField