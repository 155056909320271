import React from 'react'

const TimetableStats = ({ matchInProgressScore }) => {
  if (window.origin.includes('localhost')) {
    console.log(matchInProgressScore)
  }
  const statistics = [];
  const statOrder = [
    {
      'Total Shots': 'Strzały',
      'Shots on Goal': 'Strzały celne',
      'Shots off Goal': 'Strzały niecelne',
    },
    {
      'Fouls': 'Faule',
      'Yellow Cards': 'Żółte kartki',
      'Red Cards': 'Czerwone kartki',
    },
    {
      'Corner Kicks': 'Rzuty Rożne',
      'Offsides': 'Spalone',
    },
    {
      'Ball Possession': 'Posiadanie piłki'
    }
  ];

  let away = null;

  function getStatistics() {
    matchInProgressScore && matchInProgressScore.map(item => {
      item.statistics.map(statistic => {
        statistics.push(statistic)
      })
      away = item.teams.away.id
    })
  }
  getStatistics();

  statOrder.map((item, index) => {
    let isDeleted = false
    let arrayKeyToDelete = ''

    for (let i = 0; i < statistics.length; i++) {
      for (let squad in statistics[i].statistics) {
        for (let d = 0; d < Object.keys(item).length; d++) {
          if (Object.keys(item)[d] === statistics[i].statistics[squad].type && statistics[i].statistics[squad].value === null) {
            isDeleted = true
            arrayKeyToDelete = Object.keys(item)[d]
            break;
          } else {
            isDeleted = false;
          }
        }
        if (isDeleted)
          break
      }
    }
    if (isDeleted) {
      delete statOrder[index][arrayKeyToDelete]
    }
  })

  return (
    <>
      {matchInProgressScore && statistics.length > 0 && matchInProgressScore[0].fixture.status.short !== "NS" ?
        <>
        <div className='container mb-30 d-flex jc-center small-h'>
          <span className='ta-center alert'>STATYSTYKI ODŚWIEŻAJĄ SIĘ AUTOMATYCZNIE CO 1 MINUTĘ</span>
        </div>
        <div className="timetable-statistics mt-10">
          <div className="statistics-group">
            <div className="statistic-row">
              <div className="statistics-group-name">Bramki</div>
              <div className={`statistics-value-0 statistic-value ${matchInProgressScore[0].goals.away < matchInProgressScore[0].goals.home ? 'statistic-higher' : ''}`}>
                {matchInProgressScore[0].goals.home && matchInProgressScore[0].goals.home}
              </div>
              <div className={`statistics-value-1 statistic-value ${matchInProgressScore[0].goals.away > matchInProgressScore[0].goals.home ? 'statistic-higher' : ''}`}>
                {matchInProgressScore[0].goals.away && matchInProgressScore[0].goals.away}
              </div>
            </div>
          </div>
          {
            statOrder.map((item, index1) => {
              return (
                <div key={index1} className="statistics-group">
                  {Object.keys(item).map((it, index) => {

                    let elem1 = "";
                    let elem2 = "";

                    if (statistics[0] && statistics[0].statistics) {
                      elem1 = statistics[0].statistics.find(element => element.type === it)
                    }
                    if (statistics[1] && statistics[1].statistics) {
                      elem2 = statistics[1].statistics.find(element => element.type === it)
                    }

                    return (
                      <div key={index} className="statistic-row">
                        <div className="statistics-group-name">{item[it]}</div>                        
                        {elem1 !== "" ?
                        <div className={`statistics-value-0 statistic-value ${elem1.value > elem2.value ? 'statistic-higher' : ''}`}>
                          {elem1.value === null ? 0 : elem1.value}
                        </div>
                        : <div className={'statistics-value-0 statistic-value'}>0</div>
                        }
                        {elem2 !== "" ?
                        <div className={`statistics-value-1 statistic-value ${elem2.value > elem1.value ? 'statistic-higher' : ''}`}>
                          {elem2.value === null ? 0 : elem2.value}
                        </div>
                        : <div className={'statistics-value-1 statistic-value'}>0</div>
                        }
                      </div>
                    )
                  })}
                </div>
              )
            })
          }

        </div>
        </>
      :
        <div className="d-flex jc-center mt-10">
          <h3>Informacje dla tego spotkania nie są jeszcze dostępne</h3>
        </div>
      }
    </>
  )
}

export default TimetableStats