import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Bio from "../managementBox/Bio";
import { CSSTransition } from "react-transition-group";
const Modal = (props, { item }) => {
    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);


    return ReactDOM.createPortal(
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className="modal modal-management d-flex jc-center ai-center" onClick={props.onClose}>
                <div className="modal-content container" onClick={e => e.stopPropagation()}>
                    <button onClick={props.onClose} className="button-close">
                        x
                    </button>
                    <div className="modal-header">
                        <h1 className="modal-title d-flex jc-center c-white">{props.title}</h1>
                    </div>
                    <div className="modal-body">
                        {props.setFormBox && <Bio Text={props.item.Text} underText={props.item.underText} ImageModal={props.item.image} textAbout={props.item.textAbout} />}
                        {props.children && <p>{props.children}</p>}
                    </div>
                </div>
            </div>
        </CSSTransition>,
        document.getElementsByTagName("BODY")[0]
    );
};

export default Modal;
