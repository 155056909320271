import React from "react";
import Image from "../../components/Image.js";

function SponsorsListImages(params) {
    let image = params.block.hasOwnProperty('Image') ? (params.block['Image'][0].hasOwnProperty('link') ? params.block['Image'][0]['link'] : '/.templates/img/logo/herbwidzewa.jpg') : '/.templates/img/logo/herbwidzewa.jpg',
        altText = params.block.hasOwnProperty('AltText') ? params.block['AltText'] : '',
        link = params.block.hasOwnProperty('Link') ? (params.block['Link'].hasOwnProperty('link') ? params.block['Link']['link'] : '') : '';

    return (
        <div className="sponsors-img">
            {link !== '' &&
                <a
                    target="true"
                    href={link}
                >
                    <Image src={image} alt={altText} />
                </a>
            }
            {link === '' &&
                <Image src={image} alt={altText} />
            }
        </div>
    )
}
export default SponsorsListImages;