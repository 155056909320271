import React from "react";
import Image from "../../components/Image";
import Links from "../../components/Links";

const TextImage = (params) => {

  let colorBack = "";
  if (
    params.block["en"]["Background"] !== "" &&
    params.block["en"].hasOwnProperty("Background")
  ) {
    colorBack = "b-" + params.block["en"]["Background"];
  }
  let innerSection = "text-image";
  let innerContainer = "container";
  if (
    params.block["en"]["InnerBackground"] !== "" &&
    params.block["en"].hasOwnProperty("InnerBackground")
  ) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack;
    } else {
      innerSection = innerSection + " " + colorBack;
    }
  }

  let bgImgUrl = ''
  let image = params.block['en'].BackgroundImage['path']
  if (window.origin.includes('localhost')) {
    bgImgUrl = `url('https://dev-widzew.qrqs.eu${params.block['en'].BackgroundImage['path']}')`
  } else {
    bgImgUrl = 'url('+image.replace('/sites/widzew', '')
  }

  return (
    <section className={innerSection}>
      <div className="wrapper">
      <div className={innerContainer}>
          <div className="text-image-wrapper">
            <div className="text-image-text">
              {params.block['en'].Title && 
                <h1>{params.block['en'].Title}</h1>
              }
              {params.block['en'].ContentText &&
                <span
                  dangerouslySetInnerHTML={{__html: params.block['en'].ContentText}}
                ></span>
              }
              {params.block['en'].ButtonUrl.link && params.block['en'].ButtonText &&
                <Links line={params.line} url={params.block['en'].ButtonUrl} target={params.block['en'].NewTab.toString()} class={`button ${params.block['en'].ButtonsType}`} name={params.block['en'].ButtonText} />
              }
            </div>
            <div className="text-image">
              {params.block['en'].LabelText && params.block['en'].LabelImage &&
                <div className="label-container">
                  <p>{params.block['en'].LabelText}</p>
                  <div>
                    <Image src={params.block['en']['LabelImage']['path']} alt={params.block['en']['LabelImageAlt'] ? params.block['en']['LabelImageAlt'] : 'Widzew Image'} />
                  </div>
                </div>
              }
            </div>
            <div></div>
            <div className={`text-image-bg ${params.block['en'].Title ? '' : 'no-title'}`} style={{backgroundImage: bgImgUrl}} ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextImage;
