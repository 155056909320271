import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import NewsTeaser from "../../portlets/news/NewsTeaser";
import Loaders from "../../Loaders.js";

function NewsCarousel(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
        console.log("NewsCarousel")
        console.log(params)
    }

    let title = ''
    if (params.block['en']['Title'] !== '' && params.block['en'].hasOwnProperty('Title') && 
        params.block['en']['ShowTitle'] === true && params.block['en'].hasOwnProperty('ShowTitle')) {
        title = (
            <h1>{params.block['en']['Title']}</h1>
        )
    }
    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-'+params.block['en']['Background']
    }
    let innerSection = 'news-carousel';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }
    const nextArrow = `
        <svg id="nextArrow" enable-background="new 0 0 512 512" viewBox="0 0 128 128" x="0px" y="0px"xmlns="http://www.w3.org/2000/svg"><path id="Right_Arrow_4_" d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>
    `;
    const prevArrow = `
        <svg version="1.1" id="prevArrow    " xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <path id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7
            c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"
            />
        </svg>
    `;
    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, display: "block", width: '30px', height: '30px' }}
                dangerouslySetInnerHTML={
                    { __html: nextArrow }
                }
            >
            </div>
        );
    }
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, display: "block", width: '30px', height: '30px' }}
                dangerouslySetInnerHTML={
                    { __html: prevArrow }
                }
            >
            </div>
        );
    }
    let settings = {
        dots: false,
        infinite: false,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1
            }
          }
        ]
    };

    const [data, setData] = useState('empty');
    let { jsonNewsUrl } = useParams()
    useParams();
    let cmsrel = window.location.origin;
    switch (window.location.origin) {
        case 'https://dev-cms-widzew.qrqs.eu':
            cmsrel = 'https://dev-widzew.qrqs.eu'
            break;
        case 'https://test-cms-widzew.qrqs.eu':
            cmsrel = 'https://test-widzew.qrqs.eu'
            break;
        case 'https://prod-cms-widzew.qrqs.eu':
            cmsrel = 'https://prod-widzew.qrqs.eu'
            break;
        default:
            break;
    }

    let media = false;
    let tagList = ''       
    let tagArray = [];

    jsonNewsUrl = '/api/jsonws/news/search/'
    if (params.block['en']['NewsFolderFrom'] && params.block['en']['NewsFolderFrom'].hasOwnProperty('path')) {
        if (params.block['en']['NewsFolderFrom']['path'] !== '') {
            media = params.block['en']['NewsFolderFrom']['path'].includes("/media") ? true : false
        }
    }
    if (window.location.origin.includes("cms-widzew")) {
        jsonNewsUrl = media ? cmsrel + '/api/jsonws/news/search/?index=media' : cmsrel + '/api/jsonws/news/search/?index=news'
    }
    else if (window.location.origin.includes("http://localhost")) {
        jsonNewsUrl = media ? 'https://dev-widzew.qrqs.eu/api/jsonws/news/search/?index=media' : 'https://dev-widzew.qrqs.eu/api/jsonws/news/search/?index=news'
    }
    else {
        jsonNewsUrl = media ? window.location.origin + '/api/jsonws/news/search/?index=media' : window.location.origin + '/api/jsonws/news/search/?index=news'
    }               
    if (params.block['en']['Tags'] && params.block['en']['Tags'] !== '') {
        const splitTags = params.block['en']['Tags'].split(',');
        splitTags.map((tag) => {
            tag = tag.replace('/sites/widzew/.categories','').replace('/.categories','')
            tagArray.push(tag)
            return null
        })
        tagList = JSON.stringify(tagArray).replace('[','').replace(']','').replace(/"/g,'');
    }
    
    useEffect(() => {
        let fetchNews = async () => {
            let response = "",
                setFine = false;
            try {
                if (tagList !== '') jsonNewsUrl = jsonNewsUrl+'&tags='+tagList
                let size = 10;
                if (params.block['en']['ItemsCount'] && params.block['en']['ItemsCount'] !== '') {
                    if (isNaN(params.block['en']['ItemsCount'])) 
                        size = parseInt(params.block['en']['ItemsCount'])
                }
                jsonNewsUrl = jsonNewsUrl + '&maxResults='+size
                response = await Axios(jsonNewsUrl);
            } catch (err) {
                response = "error";
                console.log(err)
            }

            if (response.hasOwnProperty('data')) {
                if (response.data.hasOwnProperty('news')) {
                    setData(response.data['news']);
                    setFine = true
                }
            }
            if (!setFine || response === 'error') {
                setData([]);
            }
        };
        fetchNews();
    },[jsonNewsUrl]);
    
    function PreLoader() {
        let bc = 'b-white'
        if (params.block['en']['Background'] === 'white') {
            bc = 'b-red'
        }
        return (
            <li className="list-entry">
                <div><h3 className="c-white"><Loaders type="dots" color={bc} /></h3></div>
            </li>
        )
    }
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    {title}
                    {data.length === 0 && 
                        <div><h3>NIE ZNALEZIONO AKTUALNOŚCI</h3></div>
                    }
                    {data === 'empty' && 
                        <PreLoader/>
                    }
                    {data.length > 0 && data !== 'empty' && 
                        <div className="carousel-wrapper">
                            <Slider {...settings}>
                                {data.map((item, i) => (
                                    <div className="p-10" key={'sponsCarouItem' + i}>
                                        {React.createElement(NewsTeaser, {
                                            key: 'news-teaser-carousel_' + i,
                                            block: item,
                                            line: params.line
                                        })}
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}

export default NewsCarousel;