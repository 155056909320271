import React from "react";
import StaffItem from "./StaffItem";

function StaffBox(params) {

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }

    let innerSection = 'staff-box';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <div>
                        <div className='staff-list d-flex f-wrap'>
                            {params.block['en'].hasOwnProperty('Item') && Object.keys(params.block['en']['Item']).map((item, i) => (
                                <StaffItem
                                    key={`staff-box-`+item}
                                    block={params.block['en']['Item'][item]}
                                    line={params.line}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default StaffBox;