import React from "react";
import Image from "../../components/Image.js";
import '../../.templates/css/portlets/academy/Academy.css';


function AcademyItem(params) {
    return (
        <>
            <div className="carousel-item">
                {params.Link &&

                    <a
                        target={params.NewTab == true ? '_blank' : ''}
                        href={params.Link}
                    >
                        <Image
                            src={params.image && params.image}
                            alt={params.altText && params.altText} />
                    </a>
                }
                {!params.Link &&
                    <Image
                        src={params.image && params.image}
                        alt={params.altText && params.altText}
                    />
                }
            </div>

        </>
    )

}
export default AcademyItem;