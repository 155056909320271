import React from "react";
import Image from "../../components/Image";
import Links from "../../components/Links";

const ChipItemBox = (params) => {
  let colorBack = "";
  if (
    params.block["en"]["Background"] !== "" &&
    params.block["en"].hasOwnProperty("Background")
  ) {
    colorBack = "b-" + params.block["en"]["Background"];
  }
  let innerSection = "chip-item-box";
  let innerContainer = "container";
  if (
    params.block["en"]["InnerBackground"] !== "" &&
    params.block["en"].hasOwnProperty("InnerBackground")
  ) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack;
    } else {
      innerSection = innerSection + " " + colorBack;
    }
  }

  const ChipElement = ({ item }) => {
    return (
      <div className="chip-item">
        {item["Image"] && (
          <Image src={item["Image"]["path"]} alt={item["AltText"] ? item['AltText'] : 'Widzew Image'} />
        )}
        {item.Title && <h2>{item.Title}</h2>}
        {item.Text && (
          <p>
            {item.Text}
            {item.TextStar == true && <span className="red"> *</span>}
          </p>
        )}
        {item.ButtonUrl.link && item.ButtonText && (
          <Links
            line={params.line}
            url={item.ButtonUrl}
            target={item.NewTab.toString()}
            class={`button ${item.ButtonsType}`}
            name={item.ButtonText}
          />
        )}
      </div>
    );
  };

  const smallerTitle = params.block['en'].SmallerTitle ? 'smaller-h' : ''
  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          {params.block["en"].Title && (
            <h1 className={`ta-center ${smallerTitle}`}>{params.block["en"].Title}</h1>
          )}
          <div
            className={`chip-item-box-wrapper ${params.block["en"].Type} ${params.block["en"].Variant}`}
          >
            {params.block["en"]?.Item.map((item, index) => {
              return <ChipElement key={index} item={item} />;
            })}
          </div>
          {params.block["en"].EndText && (
            <span className="endText" dangerouslySetInnerHTML={{ __html: params.block["en"].EndText}}></span>
          )}
        </div>
      </div>
    </section>
  );
};

export default ChipItemBox;
