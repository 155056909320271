import React from 'react'
import NewsList from '../../news/NewsList'

const TimetableNews = (props) => {
  return (
    <>
    <div className="timetable-news mt-10">
      {props.tags && props.tags.length > 0 &&
        <section className="list-box b-white" id="news-list">
          <NewsList block={{'Category': props.tags}}/>
        </section>
      }
    </div>
    {!props.tags &&
      <div className="d-flex jc-center mt-10">
        <h3>Informacje dla tego spotkania nie są jeszcze dostępne</h3>
      </div>
    }
    </>
  )
}

export default TimetableNews