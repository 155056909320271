import React from "react";
import { useState, useEffect } from "react";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import axios from 'axios';
import ModalForm from "../modalBox/Modal.js";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function ContactForm(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
        console.log('ContactForm')
        console.log(params)
    }
  
    let colorBack = '',
        innerSection = 'contact-form',
        innerContainer = 'container d-flex f-column jc-center';
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-'+params.block['en']['Background']
    }
    if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    let captchaKey = "",
        hostname = window.location.hostname,
        dataToSend = {};
    if (hostname === "dev-widzew.qrqs.eu" || hostname === "localhost" || hostname === "127.0.0.1") {
        captchaKey = '6LfMiRQgAAAAACft3ooQNtAkziRMPgOLuY0qSkCK';
    }
    else if (hostname === "test-widzew.qrqs.eu") {
        captchaKey = '6LdDjBQgAAAAAGcbrt4z1XImLP07t6JgxPGmNcVd';
    }
    else if (hostname === "widzew.com") {
        captchaKey = '6Lch2E0gAAAAANGDpyTeLLij8iYzrr3AFvZFIvKe'
    }

    const   [agreeRodo, setAgreeRodo] = useState(false),
            [agreeTele, setAgreeTele] = useState(false),
            [name, setName] = useState(null),
            [nameReg, setNameReg] = useState(''),
            [surname, setSurname] = useState(null),
            [surnameReg, setSurnameReg] = useState(''),
            [valueNumber, setValueNumber] = useState(null),
            [valueNumberReg, setValueNumberReg] = useState(null),
            [email, setEmail] = useState(null),
            [subject, setSubject] = useState(''),
            [message, setMessage] = useState(null);
    
    const   [show, setShow] = useState(false),
            [yellowErrorMail, setYellowErrorMail] = useState(false),
            [yellowStatus, setYellowStatus] = useState(false),
            [validateStatus, setValidateStatus] = useState(false),
            [isFormError, setIsFormError] = useState(false),
            [isFormSuccess, setIsFormSuccess] = useState(false),
            [IsAlreadyExist, setIsAlreadyExist] = useState(false);

    useEffect(() => {
        ReactRecaptcha3.init(captchaKey).then(
            (status) => {

            }
        );
    }, [])
    const [formData, setFormData] = useState({
        // email: '',
        // number: '',
        // name: '',
        // surname: '',
        // message: '',
    });
    const reletters = /^(?![\s.]+$)[a-zA-Z-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s.]*$/;//not numbers and not special characters but space
    const rephone = /^[0-9\-\+]{9,16}$/;
    const changeName = name => {
        if (name === "" || reletters.test(name)) {
            setName(name);
            setNameReg(name)
        }
    }
    const changeSurName = surname => {
        if (surname === "" || reletters.test(surname)) {
            setSurname(surname);
            setSurnameReg(surname)
        }
    }
    const changePhone = phone => {
        if (phone === "" || rephone.test(phone)) {
            setValueNumber(phone);
            setValueNumberReg(phone)
        }
    }
    const changeSubject = subject => {
        setSubject(subject);
    }
    const changeMessage = message => {
        setMessage(message);
    }
    const statusHandlerRodo = () => {
        setAgreeRodo(!agreeRodo)
    }
    const statusHandlerTele = () => {
        setAgreeTele(!agreeTele)
    }
    const YellowHandler = () => {
        if (email === '' || email == null || !name || !surname || !message || !valueNumber || !subject || subject === '' ) {
            setValidateStatus(true);
        }
        if (email === 'wrong') setYellowErrorMail(true)
        if (!agreeRodo || !agreeTele) {
            setYellowStatus(true);
        }

        setTimeout(() => {
            setYellowErrorMail(false);
            setYellowStatus(false);
            setValidateStatus(false);
        }, 3000);
    }
    const validateEmail = email => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            setEmail(email);
            setYellowErrorMail(false)
        }
        else {
            setEmail('wrong');
        }
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        let allData = false;
        if ((email && (email !== '' && email !== 'wrong')) && name && surname && valueNumber && (subject && subject !== '')) {
            if (agreeRodo && agreeTele) {
                allData = true
                dataToSend = {
                    ...formData,
                    email: email,
                    phone: valueNumber,
                    name: name,
                    surname: surname,
                    message: message,
                    subject: subject,
                    agreeRodo: agreeRodo,
                    agreeTele: agreeTele
                }
            }
        }
        if (allData) {
            ReactRecaptcha3.getToken().then(
                (token) => {
                    dataToSend = {
                        ...dataToSend,
                        'g-recaptcha-response': token
                    }

                    let url = '',
                        hostname = window.location.hostname;
                    if (hostname === "localhost") {
                        url = 'https://dev-widzew.qrqs.eu/api/jsonws/contact/send';
                    } else {
                        url = '/api/jsonws/contact/send'
                    }
                    
                    axios.post(url, dataToSend)
                         .then((response) => {
                            const success = response.data.status === 'success';
                            const alreadyExist = response.data.message === 'alreadyExists';

                            setIsAlreadyExist(false)
                            setIsFormSuccess(false);
                            setIsFormError(false);

                            if (success) {
                                if (alreadyExist) {
                                    setIsAlreadyExist(true)
                                }
                                else {
                                    setIsFormSuccess(true);
                                }
                                cleanForm()
                            } 
                            else {
                                setIsFormError(true);
                            }

                            setShow(true);
                            const timer = setTimeout(() => {
                                setIsFormError(false);
                                setIsFormSuccess(false);
                            }, 5000);

                            return () => clearTimeout(timer);

                        }).catch(error => {
                            console.error('Error: ', error);
                            setShow(true);   
                            setIsFormError(true)
                            cleanForm()
                        });
                },
                (error) => {
                    console.log(error);
                    setShow(true);   
                    setIsFormError(true)
                    cleanForm()
                }
            );
        }
    }
    function cleanForm() {
        let name = document.getElementById('name');
        name.value = ''
        let surname = document.getElementById('surname');
        surname.value = ''
        let email = document.getElementById('email');
        email.value = ''
        let message = document.getElementById('message');
        message.value = ''
        let subject = document.getElementById('subject');
        subject.value = ''

        let rodo = document.getElementById('c-rodo');
        rodo.checked = false
        let tele = document.getElementById('c-tele');
        tele.checked = false

        setEmail('')
        setName('')
        setSurname('')
        setNameReg('')
        setSurnameReg('')
        setMessage('')
        setSubject('')
        setAgreeRodo(false)
        setAgreeTele(false)
        setValueNumber('')
        setValueNumberReg('')
    }
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    {params.block['en']['Title'] &&
                        <h1>{params.block['en']['Title']}</h1>
                    }
                    <form className="form-box" id="contactForm" name="sentMessage" action="/ajax/contact/" method="post" onSubmit={submitHandler}>
                        <div className="foem-box d-flex f-wrap jc-center mb-20 mt-20">
                            <div className="from-box d-flex f-wrap jc-center mb-20">
                                <fieldset>
                                    <input id="name" className="button b-white newsl" type="text" placeholder="Imię *" name="name"
                                        value={nameReg}
                                        onChange={
                                            e => { changeName(e.target.value); }
                                        }
                                    />
                                </fieldset>
                                <fieldset>
                                    <input id="surname" className="button b-white newsl" type="text" placeholder="Nazwisko *" name="surname"
                                        value={surnameReg}
                                        onChange={
                                            e => { changeSurName(e.target.value) }
                                        }
                                    />
                                </fieldset>
                                <fieldset>
                                    <input id="email" className="button b-white newsl" type="text" placeholder="Adres email *" name="email" 
                                        onChange={
                                            e => { validateEmail(e.target.value) }
                                        }
                                    />
                                </fieldset>
                                <fieldset>
                                    <PhoneInput
                                        defaultCountry="PL"
                                        value={valueNumberReg}
                                        placeholder="Numer telefonu *"
                                        onChange={
                                            number => { changePhone(number) }
                                        }
                                        limitMaxLength={true}
                                        className="button b-white newsl d-flex"
                                    />                            
                                </fieldset>
                                <fieldset>
                                    <select name="subject" id="subject" className="button b-white newsl" 
                                        onChange={
                                            e => { changeSubject(e.target.value) }
                                        }
                                        value={subject}
                                    >
                                        <option value="" disabled>Wybierz temat: *</option>
                                        <option value="bilety">bilety i karnety</option>
                                        <option value="wizyty">wizyty w szkołach</option>
                                        <option value="wycieczki">wycieczki po stadionie</option>
                                        <option value="csr">CSR</option>
                                        <option value="akademia">akademia</option>
                                        <option value="sprawy">Sprawy księgowe</option>
                                        <option value="inne">inne</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <textarea className="w-100 c-black" id="message" name="message" rows="10" cols="100" placeholder="Treść wiadomości *" 
                                        onChange={
                                            e => { changeMessage(e.target.value);}
                                        }
                                    >
                                    </textarea>
                                </fieldset>
                            </div>
                            <fieldset className="mb-30 short-box">
                                <div className="d-flex f-row form-button mb-10">
                                    <div>
                                        <small>* Pola obowiązkowe. </small>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="short-box">
                                <div className="d-flex f-row form-button">
                                    <div>
                                        <div>
                                            <label>
                                                <input className="approval-checkbox" name="agreeRodo" id="c-rodo" type="checkbox" onChange={statusHandlerRodo} />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <small>
                                            <label for="c-rodo">
                                                Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z art. 6 ust. 1 lit. a) ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r (Dz. Urz. UE L 119 z 04.05.2016), dla celów związanych z kontaktem z Klubem. *
                                            </label>
                                        </small>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="short-box">
                                <div className="d-flex f-row form-button">
                                    <div>
                                        <div>
                                            <label>
                                                <input className="approval-checkbox" name="agreeTele" id="c-tele" type="checkbox" onChange={statusHandlerTele} />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <small>
                                            <label for="c-tele">
                                                Wyrażam zgodę na używanie telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem (telefonu, komputera) przez WIDZEW ŁÓDŹ S.A. oraz zaufanych partnerów WIDZEW ŁÓDŹ S.A. dla celów kontaktowych.*
                                            </label>
                                        </small>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className="d-flex f-row form-button jc-center">
                            <button className="form-field button b-gold mb-20" type="submit" onClick={YellowHandler}>WYŚLIJ</button>
                        </div>

                        {isFormSuccess && <ModalForm onClose={() => setShow(false)} show={show} children={`
                            Dziękujemy za kontakt. 
                            W najbliższym czasie pracownik Widzewa Łódź skontaktuje się z Tobą za pośrednictwem skrzynki mailowej.
                        `} title="DZIĘKUJEMY" />}

                        {isFormError && <ModalForm onClose={() => setShow(false)} show={show} children={`
                            Przepraszamy, nastąpił nieoczekiwany błąd.
                            Twoja wiadomość nie została wysłana.
                        `} title="NIEOCZEKIWANY BŁĄD" />}

                        {IsAlreadyExist && <ModalForm onClose={() => setShow(false)} show={show} children={`
                            Twój adres e-mail znajduje się już w naszej bazie.
                            Sprawdź swoją skrzynkę e-mail w poszukiwaniu wiadomości o Widzewie.
                        `} title="E-MAIL ISTNIEJE" />}    

                        <div className="ta-center m-auto">
                            {yellowErrorMail && <><span className="form-confirmation error">Email nieprawidłowy</span><br/></>}

                            {(yellowStatus && !validateStatus) &&<span className="form-confirmation error">Zaakceptuj zgody</span>}
                            {validateStatus && <span className="form-confirmation error">Wypełnij wymagane pola</span>}
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default ContactForm;