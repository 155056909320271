import React, { useState } from "react";
import Image from "./components/Image.js";
import { Link } from "react-router-dom";
import Links from "./components/Links";
import Svg from "./components/Svg";
import Modal from "./portlets/modalBox/Modal.js";

function Foo(params) {
  if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
    console.log('Footer');
    console.log(params);
  }
  const [show, setShow] = useState(false);

  return (
    <section className="footer">
      <div className="t-footer b-red small-p">
        <div className="container d-flex jc-between">
          <div className="col-4 left-foot d-flex">
            <div className="mr-15">
              {params.line === "offline"
                ? <a href="/" className="no-anim">
                  <Image src="/.templates/img/logo/herb.png" alt="herb widzew" />
                </a>
                : <Link to="/" className="no-anim">
                  <Image src="/.templates/img/logo/herb.png" alt="herb widzew" />
                </Link>
              }
            </div>
            <div className="mt-20">
              <p>al. Piłsudskiego 138, 92-230 Łódź <br />539 265 286</p><br />
              <a href="mailto:sekretariat@widzew.com" className="c-gold" title="sekretariat@widzew.com">sekretariat@widzew.com</a><br />
              <a href="mailto:bilety@widzew.com" className="c-gold" title="bilety@widzew.com">bilety@widzew.com</a>
            </div>
          </div>
          <div className="col-4 center-foot d-flex jc-center">
            <div className="soc-menu mt-20 ta-center">
              <p className="mb-15">DOŁĄCZ DO NAS</p>
              <div className="d-flex ai-center jc-center">
                <a href="https://www.facebook.com/widzew.ofi" target="_blank" rel="noreferrer" title="facebook">
                  <Svg name="svgFB" />
                </a>
                <a href="https://twitter.com/RTS_Widzew_Lodz" target="_blank" rel="noreferrer" title="twitter">
                  <Svg name="svgTwit" />
                </a>
                <a href="https://www.instagram.com/rtswidzewlodz/" target="_blank" rel="noreferrer" title="instagram">
                  <Svg name="svgInsta" />
                </a>
                <a href="https://www.tiktok.com/@rts_widzew_lodz" target="_blank" rel="noreferrer" title="tiktok">
                  <Svg name="svgTikTok" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-4 right-foot d-flex ">
            <div className="mt-20 w-100">
              <p className="mb-15">ZAPISZ SIĘ DO NEWSLETTERA</p>
              <div className="modal-click" onClick={() => setShow(true)}>
                <div className="button b-white newsl">podaj swój email
                  <Svg name="ArrowLeft" class="btn-arrow r-arrow d-flex ai-center jc-center b-gold" />
                </div>
                <Modal onClose={() => setShow(false)} show={show} setShow={setShow} setFormBox={true} title="Zapisz się do newslettera" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-footer">
        <div className="container d-flex jc-center">
          <Image src="/images/LOGOTYPY.jpg" alt="Projekt Unijny" />
        </div>
      </div>
      <div className="b-footer b-black small-p">
        <div className="container d-flex ai-center">
          <p className="mr-30">Wszelkie prawa zastrzeżone © Widzew Łódź</p>
          <div className="f-links d-flex ai-center">
            <Links line={params.line} url="/regulaminy/" name="Regulaminy" />
            <Links line={params.line} url="/polityka-prywatnosci/" name="Polityka prywatności" />
            <Links line={params.line} url="/cookies/" name="Polityka Cookies" />
            <Links line={params.line} url="/rodo/" name="Rodo" />
          </div>
          <div className="powered">
            <a href="https://qrqs.eu/" target="_blank" rel="noreferrer" title="Quercus – Software on demand, on time">
              <span>powered by </span>
              <Svg name="svgQRQS" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Foo;