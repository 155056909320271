import React from 'react'
import Loaders from "../../Loaders.js";
import Svg from "../../components/Svg";

const VideoBox = (params) => {

  let colorBack = ''
  if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
    colorBack = 'b-' + params.block['en']['Background']
  }
  let innerSection = 'video-box';
  let innerContainer = 'container d-flex p-30';
  if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
    if (params.block['en']['InnerBackground'] === true) {
      innerContainer = innerContainer + ' ' + colorBack;
    }
    else {
      innerSection = innerSection + ' ' + colorBack;
    }
  }

  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          <div className="col-8">
            <div className="video-wrapper">
              {params.block['en']['VideoLink'] && params.block['en']['VideoLink'] !== '' &&
                <>
                  <Loaders type="circle" color={`b-`+params.block['en']['Background']} mode="true" />
                  <iframe
                    width="560"
                    height="315"
                    src={params.block['en']['VideoLink']}
                    frameBorder="0"
                    allowFullScreen
                    title={params.block['en']['Title'] ? params.block['en']['Title'] : 'Video Box YT'}
                  >
                  </iframe>
                </>
              }
              {(!params.block['en']['VideoLink'] || params.block['en']['VideoLink'] === '') &&
                <Svg name="svgTV" class="no-yt-icon d-flex jc-center"/>
              }
            </div>
          </div>
          <div className="col-4 pl-30 text-box">
            {params.block['en']['Title'] &&
              <h3
                className="mb-30"
                dangerouslySetInnerHTML={
                  { __html: params.block['en']['Title'] }
                }
              >
              </h3>}
            {params.block['en']['Text'] &&
              <div
                className="text"
                dangerouslySetInnerHTML={
                  { __html: params.block['en']['Text'] }
                }
              >
              </div>}
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoBox