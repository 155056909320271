import React, { useState, useEffect } from "react";
import NewsTeaser from "../../portlets/news/NewsTeaser";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Loaders from "../../Loaders.js";
import useWindowDimensions from "../../Device.js";

const TeaserComponents = {
    "news-details": NewsTeaser,
};

function NewsList(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew')) {
        console.log('NewsList')
        console.log(params);
    }

    const   { device } = useWindowDimensions(),
            count = (device === 'mobile' ? 3 : 6),
            [firstTime, setFirstTime] = useState(true),
            [pending, setPending] = useState(true),
            [data, setData] = useState(null),
            [choosenTagsList, setChoosenTagsList] = useState([]),
            [group, setGroup] = useState({
                sortTag: 'empty',
                sortType: 'all',
                results: count,
                getCats: false
            }),
            closeIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 682.66669 682.66669" style="enable-background:new 0 0 512 512" xml:space="preserve"><g><path xmlns="http://www.w3.org/2000/svg" d="m460.175781 215.175781-104.800781 104.800781 104.800781 104.796876-35.351562 35.359374-104.800781-104.800781-104.796876 104.800781-35.359374-35.359374 104.800781-104.796876-104.800781-104.800781 35.359374-35.351562 104.796876 104.800781 104.800781-104.800781zm179.824219 104.824219c0 85.472656-33.285156 165.835938-93.726562 226.273438-60.4375 60.441406-140.800782 93.726562-226.273438 93.726562s-165.835938-33.285156-226.273438-93.726562c-60.441406-60.4375-93.726562-140.800782-93.726562-226.273438s33.285156-165.835938 93.726562-226.273438c60.4375-60.441406 140.800782-93.726562 226.273438-93.726562s165.835938 33.285156 226.273438 93.726562c60.441406 60.4375 93.726562 140.800782 93.726562 226.273438zm-50 0c0-148.875-121.125-270-270-270s-270 121.125-270 270 121.125 270 270 270 270-121.125 270-270zm0 0" fill="#cca34a" data-original="#000000" /></g></svg>`;

    useParams();
    let { jsonNewsUrl } = useParams(),
        [catDataAll, setCatDataAll] = useState([]),
        cmsrel = window.location.origin
  
    switch (window.location.origin) {
        case 'https://dev-cms-widzew.qrqs.eu':
            cmsrel = 'https://dev-widzew.qrqs.eu'
            break;
        case 'https://test-cms-widzew.qrqs.eu':
            cmsrel = 'https://test-widzew.qrqs.eu'
            break;
        case 'https://prod-cms-widzew.qrqs.eu':
            cmsrel = 'https://prod-widzew.qrqs.eu'
            break;
        default:
            break;
    }

    useEffect(() => {
        let fetchCats = async () => {
            let url = null;
            if (window.location.origin.includes("cms-widzew")) {
                url = window.location.origin + "/.includes/categories/categories.json";
            }
            else if (window.location.origin.includes("http://localhost")) {
                url = "https://test-widzew.qrqs.eu/.includes/categories/categories.json";
            }
            else {
                url = window.location.origin + "/.includes/categories/categories.json";
            }
            let response = "";
            try {
                response = await Axios(url);
                setGroup({sortTag: group.sortTag, sortType: group.sortType, results: group.results, getCats: true})
            } 
            catch (err) {
                response = "error";
                console.log('cats err: '+err)
                setGroup({sortTag: group.sortTag, sortType: group.sortType, results: group.results, getCats: true})
            }
            if (response.hasOwnProperty('data')) {
                let allCat = []
                Object.keys(response.data).map(function(key) {
                    let longTag = key.replace('/sites/widzew/.categories','').replace('/.categories',''),
                        tagLast = key.split('/'),
                        last = tagLast[tagLast.length - 2],
                        name = response.data[key];
                        if (name === '') name = last;
                    allCat[longTag] = name;
                    return null
                });
                setCatDataAll(allCat);
            }
        };
        if (!window.location.pathname.includes("/media")) {
            fetchCats();
        }
        else {
            setGroup({sortTag: group.sortTag, sortType: group.sortType, results: group.results, getCats: true})
        }
    }, []);
    useEffect(() => {
        if (group.getCats) fetchNews();
    },[group]);

    let media = false,
        tagList = '',
        tagArray = [];
  
    function GenerateLink() {
        jsonNewsUrl = '/api/jsonws/news/search/';

        if (window.location.pathname.includes("/media")) media = true;
        if (window.location.origin.includes("cms-widzew")) {
            jsonNewsUrl = media ? cmsrel + '/api/jsonws/news/search/?index=media' : cmsrel + '/api/jsonws/news/search/?index=news'
        }
        else if (window.location.origin.includes("http://localhost")) {
            jsonNewsUrl = media ? 'https://test-widzew.qrqs.eu/api/jsonws/news/search/?index=media' : 'https://test-widzew.qrqs.eu/api/jsonws/news/search/?index=news'
        }
        else {
            jsonNewsUrl = media ? window.location.origin + '/api/jsonws/news/search/?index=media' : window.location.origin + '/api/jsonws/news/search/?index=news'
        }    

        if (choosenTagsList !== 'empty' && choosenTagsList.length > 0) {
            tagList = JSON.stringify(choosenTagsList).replace('[','').replace(']','').replace(/"/g,'');
        }
        else {
            if (params.block['Category'] && params.block['Category'] !== '') {
                const splitTags = params.block['Category'].split(',');
                splitTags.map((tag) => {
                    tag = tag.replace('/sites/widzew/.categories','').replace('/.categories','')
                    tagArray.push(tag)
                    return null
                })
                tagList = JSON.stringify(tagArray).replace('[','').replace(']','').replace(/"/g,'');
            }
            else {
                if (window.location.hash && group.sortTag !== 'all') {
                    Object.keys(catDataAll).map(function(key) {
                        if (key.includes(window.location.hash.substring(1)+'/')) {
                            tagList = key
                        }
                        return null
                    });
                }
            }
        }
        
        if (group.sortType !== 'all') jsonNewsUrl = jsonNewsUrl + '&type=' + group.sortType;
        if (tagList !== '' && group.sortTag !== 'all') jsonNewsUrl = jsonNewsUrl+'&tags='+tagList;
        jsonNewsUrl = jsonNewsUrl + '&maxResults=' + group.results;

        return jsonNewsUrl
    }

    let fetchNews = async () => {
        setPending(true)
        let url = GenerateLink(),
            response = "",
            setFine = false;
        try {
            response = await Axios(url);
        } 
        catch (err) {
            response = "error";
            console.log('news err: '+err)
        }

        if (response.hasOwnProperty('data')) {
            if (response.data.hasOwnProperty('news')) {
                setPending(false)
                setData(response.data['news']);
                setFine = true
            }
        }
        if (!setFine || response === 'error') {
            setPending(false)
            setData([]);
        }
    };

    const Items = () => {
        function LoadButton() {
            if (pending) {
                return ( 
                    <div className="ta-center">
                        <a href="#loadmore" className="button b-gold mt-20 loading-button">
                            ZAŁADUJ WIĘCEJ
                        </a>
                    </div>
                )
            }
            else if (data && data.length < group.results && !pending) {
                return ( 
                    <div className="ta-center"></div>
                )
            }
            else if (data === null) {
                return ( 
                    <div className="ta-center pt-20"><div className="mt-50"></div></div>
                )
            }
            else {
                return (
                    <div className="ta-center">
                        <a href="#loadmore" id="loadmore" className="button b-gold mt-20" onClick={(e) => {e.preventDefault();setPending(true);setGroup({sortTag: group.sortTag, sortType: group.sortType, results: group.results + count, getCats: group.getCats})}}>ZAŁADUJ WIĘCEJ</a>
                    </div>
                )
            }
        }
        return (
            <div>
                <ul className="list-entries d-flex f-wrap">
                    {data && data !== 'empty' && data.length > 0 &&
                        data.map((teaser, i) => {
                            let type = teaser.attributes.type;
                            if (TeaserComponents.hasOwnProperty(type)) {
                                let key = teaser.path.split("/").pop();
                                    key = key.substring(0, key.length - 4);
                                return (
                                    <li className="list-entry" key={key + '_' + i}>
                                        {React.createElement(TeaserComponents[type], {
                                            key: key + '_' + i,
                                            block: teaser,
                                            line: params.line
                                        })}
                                    </li>
                                )
                            }
                            return null
                        })
                    }
                    {data && data.length === 0 && 
                        <div><h3 className="c-white">NIE ZNALEZIONO AKTUALNOŚCI Z WYBRANEJ KATEGORII</h3></div>
                    }
                    {(data === null || data === 'empty') && 
                        <li className="list-entry">
                            <div><h3 className="c-red"><Loaders type="dots" color="b-white"/></h3></div>
                        </li>
                    }
                    {/*{pending && 
                        <li className="list-entry">
                            <div><h3 className="c-red"><Loaders type="dots" color="b-white"/></h3></div>
                        </li>
                    }*/}
                </ul>
                <LoadButton/>
            </div>
        )
    }

    function TopBox () {
        if (window.location.pathname.includes("/media")) {
            return (
                <div className="top-banner-title container-wide title-box pt-15">
                    <div className="pt-30 pb-30"></div>
                    <div className="tags-container mt-50 mb-50"></div>
                </div>
            )
        }
        else {
            if (params.block['Category'] !== '') {
                return (
                    <div className="top-banner-title container-wide title-box pt-15">
                        <Types />
                        <div className="tags-container mt-50 mb-50">
                            <CategoriesBox/>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="top-banner-title container-wide title-box pt-15 b-red">
                        <Types />
                        <div className="tags-container mt-50 mb-50">
                            <CategoriesBox />
                        </div>
                    </div>
                )
            }
        }
    }
    function Types() {
        if (params.block['Category'] !== '') {
            return <div className="pt-30 pb-30"></div>
        }
        else {
            return (
                <div className="d-flex ai-end pb-0 b-red">
                    <h1>Aktualności</h1>
                    <TypeStyle />
                </div>
            )
        }
    }
    function TypeStyle() {
        if (device !== 'desktop') {
            return (
                <div className="links-item d-flex b-red">
                    <select onChange={e => {setChoosenTagsList([]);setGroup({sortTag: 'all', sortType: e.target.value, results: count, getCats: group.getCats})}} value={group.sortType}>
                        <option value="all">WSZYSTKIE</option>
                        <option value="article">ARTYKUŁY</option>
                        <option value="video">WIDEO</option>
                        <option value="audio">AUDIO</option>
                        <option value="photo">GALERIE</option>
                    </select>
                </div>
            )
        }
        else {
            return (
                <div className="links-item d-flex">
                    <a href="#wszystkie" className={group.sortType === 'all' ? 'act-true' : ''} data-type="all" onClick={(e) => {e.preventDefault();activeClick(e);setChoosenTagsList([]);setGroup({sortTag: 'all', sortType: e.target.attributes['data-type'].value, results: count, getCats: group.getCats})}}>WSZYSTKIE</a>
                    <a href="#artykuly" className={group.sortType === 'article' ? 'act-true' : ''} data-type="article" onClick={(e) => {e.preventDefault();activeClick(e);setChoosenTagsList([]);setGroup({sortTag: 'all', sortType: e.target.attributes['data-type'].value, results: count, getCats: group.getCats})}}>ARTYKUŁY</a>
                    <a href="#wideo" className={group.sortType === 'video' ? 'act-true' : '' } data-type="video" onClick={(e) => {e.preventDefault();activeClick(e);setChoosenTagsList([]);setGroup({sortTag: 'all', sortType: e.target.attributes['data-type'].value, results: count, getCats: group.getCats})}}>WIDEO</a>
                    <a href="#audio" className={group.sortType === 'audio' ? 'act-true' : ''} data-type="audio" onClick={(e) => {e.preventDefault();activeClick(e);setChoosenTagsList([]);setGroup({sortTag: 'all', sortType: e.target.attributes['data-type'].value, results: count, getCats: group.getCats})}}>AUDIO</a>
                    <a href="#galerie" className={group.sortType === 'photo' ? 'act-true' : ''} data-type="photo" onClick={(e) => {e.preventDefault();activeClick(e);setChoosenTagsList([]);setGroup({sortTag: 'all', sortType: e.target.attributes['data-type'].value, results: count, getCats: group.getCats})}}>GALERIE</a>
                </div>
            )
        }
    }
    function CategoriesBox () {
        if (params.block['Category'] !== '') {
            return ''
        }
        else {
            return (
                <div className="tags-box pb-20 pt-20">
                    <div className="chosen-tags d-flex ai-center f-wrap">
                        <ChooseTags />
                    </div>
                    <div className="tags-item d-flex ai-center f-wrap">
                        <Tags />
                    </div>
                </div>
            )
        }
    }
    function ChooseTags() {
        return (
            choosenTagsList.map((tag) => {
                const   tagLast = tag.split('/'),
                        last = tagLast[tagLast.length - 2],
                        href = '#'+last,
                        idTag = 'tag-'+last,
                        name = catDataAll[tag]
                return (
                    <div className="chosen-tag d-flex ai-center mb-20" key={tag} id={idTag}>
                        <a href={href} className="all-link" data-tag={tag} onClick={(e) => {e.preventDefault();RemoveTags(e.target.attributes['data-tag'].value);}}>
                            {href}
                        </a>
                        <div className="close-tag">
                            <span className="close-icon"
                                dangerouslySetInnerHTML= {
                                    { __html: closeIcon }
                                }
                            >
                            </span>
                        </div>
                        <p className="c-gold">{name}</p>
                    </div>
                )
            })
        )
    }
    function RemoveTags(e) {
        choosenTagsList.splice(choosenTagsList.indexOf(e),1);
        setChoosenTagsList(choosenTagsList);
        let tagsList = 'all'
        if (choosenTagsList.length !== 0) tagsList = JSON.stringify(choosenTagsList).replace('[','').replace(']','').replace(/"/g,'')
        setGroup({sortTag: tagsList, sortType: group.sortType, results: group.results, getCats: group.getCats})
    }
    let tagsList = [];
    function Tags() {
        Object.keys(catDataAll).map(function(key) {
            tagsList.push(key)
            return null
        });
        
        if (tagsList.length === 0) {
            return (<div><span className="c-white tag-item">NIE ZNALEZIONO TAGÓW</span></div>)
        }
        else {
            let tagsLists = []
            Object.values(tagsList).map((tag) => {
                if (!(tag in tagsLists)) {
                    tagsLists.push(tag)
                }
                return null;
            })
            return (
                tagsLists.map((tag) => {
                    let tagLast = tag.split('/'),
                        nameTag = tagLast[tagLast.length - 2],
                        name = catDataAll[tag],
                        href = '#'+tag,
                        addHide = '';
                    if (!tag.includes('/tagi-glowne/')) addHide = ' hidden'
                    if (Object.values(choosenTagsList).includes(tag)) {
                        return (<a key={href} href={href} className={`tag-item c-white active`+addHide} data-tags={tag} onClick={(e) => {e.preventDefault();}}>{name}</a>)
                    }
                    else if (window.location.hash && nameTag === window.location.hash.substring(1)) {
                        if (firstTime) setTimeout(() => {setActiveCats(window.location.hash.substring(1));setFirstTime(false)},0);
                        return (<a key={href} href={href} id={`add-`+nameTag} className={`tag-item c-white`+addHide} data-tags={tag} onClick={(e) => {e.preventDefault();activeClickOne(e);setGroup({sortTag: addTags(e.target.attributes['data-tags'].value), sortType: group.sortType, results: group.results, getCats: group.getCats})}}>{name}</a>)
                    }
                    else {
                        return (<a key={href} href={href} id={`add-`+nameTag} className={`tag-item c-white`+addHide} data-tags={tag} onClick={(e) => {e.preventDefault();activeClickOne(e);setGroup({sortTag: addTags(e.target.attributes['data-tags'].value), sortType: group.sortType, results: group.results, getCats: group.getCats})}}>{name}</a>)
                    }
                })
            )
        }
    }
    const addTags = tag => {
        if (tag !== 'all') {
            if (!Object.values(choosenTagsList).includes(tag)) {
                choosenTagsList.push(tag)
            }
        }
        return JSON.stringify(choosenTagsList).replace('[','').replace(']','').replace(/"/g,'');
    };
    const setActiveCats = cat => {
        let setTag = document.getElementById('add-'+cat)
        if (setTag) {
            setTag.click();
        }
    };
    let activeClick = (event) => {
        event.target.parentElement.childNodes.forEach( e => {
            if (e.classList.contains('act-true')) {
                e.classList.remove('act-true')
            }
        });
        if (event.target.classList.contains('act-true')) {
            event.target.classList.remove('act-true');
        } 
        else { 
            event.target.classList.add('act-true');         
        }
    }
    let activeClickOne = (event) => {
        if (event.target.classList.contains('active')) {
            event.target.classList.remove('active');
        } 
        else { 
            event.target.classList.add('active');         
        }
    }

    return (
        <div className="news-list list-box list-dynamic list-paginate">
            {(window.origin.includes('localhost') || window.origin.includes('dev-widzew')) && console.log('dataaaaaaaaa')}
            {(window.origin.includes('localhost') || window.origin.includes('dev-widzew')) && console.log(data)}
            <TopBox />
            <Items />
            <div className="list-pagination"></div>
        </div>
    ); 
}

export default NewsList;