import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';
import Links from '../../components/Links';

const TimetableCarouselItem = (params) => {
  let intervalActive = true;
  const [isInProgress, setIsInProgress] = useState(false);
  const [isInEnd, setIsInEnd] = useState(false);
  const [ticketsData, setTicketsData] = useState([])
  const [matchInProgressScore, setMatchInProgressScore] = useState();
  const [activeSeason, setActiveSeason] = useState(new Date(params.item.en.DateOfGame).getFullYear())

  useEffect(() => {
    let fetchTicketsData = async () => {
      let link
      if (params.line === 'offline') {
        link = window.origin + '/api/matches/tickets.json';
      } else if (window.origin.includes('localhost')) {
        link = 'https://test-widzew.qrqs.eu/api/matches/tickets.json';
      } else {
        link = window.origin + '/api/matches/tickets.json';
      }
      let response = "";
      try {
        response = await Axios(link);
        if (response.data) {
          setTicketsData(response.data);
        }
      } catch (err) {
        response = "error";
      }
    };
    fetchTicketsData();

    let match = null,
      item = null,
      letStart = null,
      today = new Date(),
      year = today.getFullYear();


      let fetchCurrentActiveSeason = async () => {
        let link2
        let response = "";
        if (params.line === 'offline') {
          link2 = window.origin + '/api/matches/current.json?' + Date.now();
        } else if (window.origin.includes('localhost')) {
          link2 = 'https://test-widzew.qrqs.eu/api/matches/current.json?' + Date.now();
        } else {
          link2 = window.origin + '/api/matches/current.json?' + Date.now();
        }
  
        try {
          response = await Axios(link2);
          if (response.hasOwnProperty('data')) {
            // year = response.data[0]
            setActiveSeason(response.data[0])
          }
          
        } catch (err) {
          response = "error";
        }
      }
  
      fetchCurrentActiveSeason()




    let fetchAllMatchesData = async () => {
      let link
      if (params.line === 'offline') {
        link = window.origin + '/api/matches/' + activeSeason + '.json?' + Date.now();
      } else if (window.origin.includes('localhost')) {
        link = 'https://test-widzew.qrqs.eu/api/matches/' + activeSeason + '.json?' + Date.now();
      } else {
        link = window.origin + '/api/matches/' + activeSeason + '.json?' + Date.now();
      }
      let response = "";

      try {
        response = await Axios(link);
        if (response.hasOwnProperty('data')) {
          item = response.data.find(e => e.timestamp === params.item['en'].DateOfGame)
        }
        match = item;

        if (item && match !== null) {
          letStart = params.item['en'].DateOfGame - (59 * 60000);
          if (match !== null && Date.now() > letStart) {
            fetchMatchesData();
          }
        }
      } catch (err) {
        response = "error";
      }
    }
    fetchAllMatchesData();

    let responseMatch = ""
    let fetchMatchesData = async () => {
      let link = ''
      if (match) {
        if (params.line === 'offline') {
          link = window.origin + '/api/matches/' + activeSeason + '/' + match.id + '.json?' + Date.now();
        } else if (window.origin.includes('localhost')) {
          link = 'https://test-widzew.qrqs.eu/api/matches/' + activeSeason + '/' + match.id + '.json?' + Date.now();
        } else {
          link = window.origin + '/api/matches/' + activeSeason + '/' + match.id + '.json?' + Date.now();
        }

        try {
          responseMatch = await Axios(link);
        } catch (err) {
          responseMatch = "error";
          console.log('fetchMatchesData - ' + err)
        }
        if (responseMatch.data && Date.now() > letStart) {
          setMatchInProgressScore(responseMatch.data);

          if (intervalActive === true && responseMatch.data && responseMatch.data[0].fixture.status.short !== "FT" && responseMatch.data[0].fixture.status.short !== "AET" && responseMatch.data[0].fixture.status.short !== "PEN") {
            intervalActive = false;

            setInterval(() => {
              fetchMatchesData();
              //fetchTicketsData();
            }, 60000);
          }
        }
      };
    }
  }, [activeSeason])

  useEffect(() => {
    if (matchInProgressScore) {
      if (((matchInProgressScore[0].fixture.status.short === 'FT' || matchInProgressScore[0].fixture.status.short === 'AET' || matchInProgressScore[0].fixture.status.short === 'PEN') || params.item['en'].IsFinished === true)) {
        setIsInProgress(false);
        setIsInEnd(true);
      } else if ((matchInProgressScore[0].fixture.status.short !== 'FT' || matchInProgressScore[0].fixture.status.short !== "AET" || matchInProgressScore[0].fixture.status.short !== 'NS') && (matchInProgressScore[0].fixture.status.short === "BT" || matchInProgressScore[0].fixture.status.elapsed !== null)) {
        setIsInProgress(true);
      } else {
        setIsInProgress(false);
      }
    } else {
      setIsInProgress(false);
    }
  }, [matchInProgressScore])

  const svgTicket = `
    <svg id="ticket-icon" height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg" style="fill: #000;"><path d="m21.5 22h-19c-1.378 0-2.5-1.122-2.5-2.5v-2c0-.276.224-.5.5-.5h.5c1.103 0 2-.897 2-2s-.897-2-2-2h-.5c-.276 0-.5-.224-.5-.5v-2c0-1.378 1.122-2.5 2.5-2.5h19c1.378 0 2.5 1.122 2.5 2.5v2c0 .276-.224.5-.5.5h-.5c-1.103 0-2 .897-2 2 0 .534.208 1.036.586 1.414.377.378.879.586 1.413.586h.5c.276 0 .5.224.5.5v2c.001 1.378-1.121 2.5-2.499 2.5zm-20.5-4v1.5c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-1.5h-.001c-.801 0-1.554-.312-2.12-.878-.567-.567-.879-1.321-.879-2.122 0-1.654 1.346-3 3-3v-1.5c0-.827-.673-1.5-1.5-1.5h-19c-.827 0-1.5.673-1.5 1.5v1.5c1.654 0 3 1.346 3 3s-1.346 3-3 3z"/><path d="m8.5 10c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5s.5.224.5.5v1c0 .276-.224.5-.5.5z"/><path d="m8.5 18.461c-.276 0-.5-.224-.5-.5v-1.691c0-.276.224-.5.5-.5s.5.223.5.5v1.692c0 .276-.224.499-.5.499zm0-4.23c-.276 0-.5-.224-.5-.5v-1.692c0-.276.224-.5.5-.5s.5.224.5.5v1.692c0 .276-.224.5-.5.5z"/><path d="m8.5 22c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5s.5.224.5.5v1c0 .276-.224.5-.5.5z"/><path d="m2.5 9c-.203 0-.394-.124-.468-.325-.097-.259.034-.547.293-.643l15.9-5.937c.773-.29 1.681.144 1.945.923l1.803 5.321c.088.262-.052.545-.313.634-.259.089-.545-.052-.634-.313l-1.803-5.321c-.088-.26-.39-.402-.649-.308l-15.9 5.937c-.057.022-.116.032-.174.032z"/></svg>
  `;
  const svgMarket = `
    <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg" style="fill: #000;" id="bag-icon-id"><g id="bag-icon" data-name="bag-icon"><path d="m16 1a6 6 0 0 0 -6 6v1h-.83a3.27 3.27 0 0 0 -3.27 3.12l-.79 16.45a3.28 3.28 0 0 0 3.27 3.43h15.24a3.28 3.28 0 0 0 3.27-3.43l-.79-16.45a3.27 3.27 0 0 0 -3.27-3.12h-.83v-1a6 6 0 0 0 -6-6zm-4 6a4 4 0 0 1 8 0v1h-8zm12.1 4.21.79 16.46a1.31 1.31 0 0 1 -.35.94 1.29 1.29 0 0 1 -.92.39h-15.24a1.29 1.29 0 0 1 -.92-.39 1.31 1.31 0 0 1 -.35-.94l.79-16.46a1.27 1.27 0 0 1 1.27-1.21h13.66a1.27 1.27 0 0 1 1.27 1.21z"/></g></svg>
  `;
  const svgTV = `
    <svg height="36" fill="#000" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g id="Watching_Movies" data-name="Watching Movies"><path d="m41 6h-34a5.006 5.006 0 0 0 -5 5v21a5.006 5.006 0 0 0 5 5h12v3h-10a1 1 0 0 0 0 2h30a1 1 0 0 0 0-2h-10v-3h12a5.006 5.006 0 0 0 5-5v-21a5.006 5.006 0 0 0 -5-5zm-14 34h-6v-3h6zm17-8a3 3 0 0 1 -3 3h-34a3 3 0 0 1 -3-3v-21a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3z"/><path d="m27.977 19.768-4.952-2.859a2 2 0 0 0 -3 1.732v5.718a2 2 0 0 0 3 1.732l4.953-2.859a2 2 0 0 0 0-3.464zm-5.953 4.591v-5.718l4.952 2.859z"/><path d="m24 11a10.5 10.5 0 1 0 10.5 10.5 10.512 10.512 0 0 0 -10.5-10.5zm0 19a8.5 8.5 0 1 1 8.5-8.5 8.51 8.51 0 0 1 -8.5 8.5z"/></g></svg>
  `;
  const svgCentrum = `
    <svg id="Icons" height="32" fill="#CCA34A" viewBox="0 0 74 74" xmlns="http://www.w3.org/2000/svg"><path d="m37 48a11 11 0 1 1 11-11 11 11 0 0 1 -11 11zm0-20a9 9 0 1 0 9 9 9 9 0 0 0 -9-9z"/><path d="m59 72h-44a3 3 0 0 1 -3-3v-64a3 3 0 0 1 3-3h44a3 3 0 0 1 3 3v64a3 3 0 0 1 -3 3zm-44-68a1 1 0 0 0 -1 1v64a1 1 0 0 0 1 1h44a1 1 0 0 0 1-1v-64a1 1 0 0 0 -1-1z"/><path d="m61 38h-48a1 1 0 0 1 0-2h48a1 1 0 0 1 0 2z"/><path d="m50 12h-26a1 1 0 0 1 -1-1v-8a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1zm-25-2h24v-6h-24z"/><path d="m37 18a9 9 0 0 1 -8.72-6.75 1 1 0 1 1 1.94-.5 7 7 0 0 0 13.56 0 1 1 0 0 1 1.94.5 9 9 0 0 1 -8.72 6.75z"/><path d="m50 72h-26a1 1 0 0 1 -1-1v-8a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1zm-25-2h24v-6h-24z"/><path d="m44.75 64a1 1 0 0 1 -1-.75 7 7 0 0 0 -13.56 0 1 1 0 1 1 -1.94-.5 9 9 0 0 1 17.44 0 1 1 0 0 1 -.69 1.25 1.07 1.07 0 0 1 -.25 0z"/></svg>
  `;

  let isHome = null;
  if (params.item.hasOwnProperty('en')) {
    if (params.item['en'].hasOwnProperty('MatchType')) {
      isHome = params.item['en'].MatchType ? params.item['en'].MatchType === 'home' : false;
    }
  }

  let link = "";
  if (params.item.line === 'offline') {
    link = window.origin + '/images/teams-logos/logos_png/widzew_lodz.png';
  } else if (window.origin.includes('localhost')) {
    link = 'https://dev-widzew.qrqs.eu/images/teams-logos/logos_png/widzew_lodz.png';
  } else {
    link = window.origin + '/images/teams-logos/logos_png/widzew_lodz.png';
  }

  function tickets() {
    let ticketNumb = undefined;
    let isShow = false;
    let ticketsDiv;
    ticketsData && ticketsData.map(a => {
      if (params.item['en'].TicketsAvailability === "available") {
        if (params.item['en'].DateOfGame === a.timestamp) {
          ticketNumb = a.availableTickets?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          isShow = true;
        } else {
          isShow = false;
        }
      }
      if (params.item['en'].TicketsAvailability === "sold") {
        if (params.item['en'].DateOfGame === a.timestamp) {
          ticketNumb = a.soldTickets?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          isShow = true;
        } else {
          isShow = false;
        }
      }
      if (isShow) {
        if (params.item['en'].TicketsAvailability === "available" && ticketNumb !== undefined) {
          ticketsDiv = 
            <div className="d-flex f-column mt-20 tickets-big">
              <span className="tickets-amount">{ticketNumb}</span>
              <small>dostępnych biletów</small>
            </div>
        }
        if (params.item['en'].TicketsAvailability === "sold" && ticketNumb !== undefined) {
          ticketsDiv = 
            <div className="d-flex f-column mt-20 tickets-big">
              <span className="tickets-amount">{ticketNumb}</span>
              <small>sprzedanych biletów</small>
            </div>
        }
  
      }
    });
    return ticketsDiv;
  }

  function returnScore() {
    if (matchInProgressScore !== undefined) {
      return `${matchInProgressScore[0].goals.home !== null ? matchInProgressScore[0].goals.home : 0}:${matchInProgressScore[0].goals.away !== null ? matchInProgressScore[0].goals.away : '0'}`
    }
  }

  function returnLiveTime() {
    if (matchInProgressScore !== undefined) {
      if (matchInProgressScore[0].fixture.status.elapsed !== null) {
        return matchInProgressScore[0].fixture.status.elapsed + "'"
      } else {
        return "Na żywo"
      }
    }
  }

  let currentTimestamp = new Date(params.item['en'].DateOfGame)
  let dates = new Intl.DateTimeFormat('pl-PL', { dateStyle: 'full', timeStyle: 'short' }).format(currentTimestamp)
  dates = dates.split(" ");

  function generateDetailsLink() {
    let url = "";
    let hrefOrLink = "";
    if (params.item.link !== "") {
      if (params.item.link.hasOwnProperty('link')) {
        url = params.item.link;
      } else {
        url = params.item.link;
      }
    } else {
      url = "";
    }

    if (url.includes("/sites/widzew") && url !== "") {
      url = url.split('/sites/widzew');
      url = url[1];
      hrefOrLink = "isLink";
    } else if ((url.includes("http://") || url.includes("https://")) && url !== "") {
      if (url.includes("offline-widzew") && params.line === 'online') {//for details broken link with no export/sites
        let uri = new URL(url);
        url = uri.pathname;
        hrefOrLink = "isLink";
      }
      else {
        hrefOrLink = "isHref";
      }
    } else {
      hrefOrLink = "isLink";
    }
    return (
      (params.line === 'offline' || hrefOrLink === "isHref")
        ?
        <a href={url} className="button empty-gold  gi-button d-flex ai-center jc-center">
          <span className="gi-text-h">
            Centrum&nbsp;Meczowe
          </span>
          <span className="svgCentrum inner-icon"
            dangerouslySetInnerHTML={
              { __html: svgCentrum }
            }
          >
          </span>
          <span className="gi-text">
            Centrum&nbsp;Meczowe
          </span>
        </a>
        :
        <Link to={url} className="button empty-gold  gi-button d-flex ai-center jc-center">
          <span className="gi-text-h">
            Centrum&nbsp;Meczowe
          </span>
          <span className="svgCentrum inner-icon"
            dangerouslySetInnerHTML={
              { __html: svgCentrum }
            }
          >
          </span>
          <span className="gi-text">
            Centrum&nbsp;Meczowe
          </span>
        </Link>
    )
  }

  const matchSponsorAlt = `Sponsor meczu ${params.item['en'].OpponentName ? 'Widzew Łódź - ' + params.item['en'].OpponentName : ''}`

  return (
    <div className="timetable-carousel-item d-flex f-column jc-between">
      <div className="timeplay-upper d-flex jc-between">
        <div className="timeplay-date d-flex">
          <span className="timeplay-day">{dates[1]}</span>
          <span className="d-flex f-column timeplay-month">
            {dates[2]}
            <span className="timeplay-week">{dates[0].slice(0, -1)}</span>
          </span>
        </div>
        <div className="timetable-logos-container">
          {(params.item['en'].MatchSponsorLogo && params.item['en'].MatchSponsorLogo.path && params.item['en'].IsFinished === false) &&
            <>
              {params.item['en'].MatchSponsorLink && params.item['en'].MatchSponsorLink.link &&
                <a href={params.item['en'].MatchSponsorLink.link} target="_blank" title={matchSponsorAlt} rel="noreferrer noopener">
                  <Image src={params.item['en'].MatchSponsorLogo.path} alt={matchSponsorAlt} />
                </a>
              }
              {!params.item['en'].MatchSponsorLink && !params.item['en'].MatchSponsorLink.link &&
                <Image src={params.item['en'].MatchSponsorLogo.path} alt={matchSponsorAlt} />
              }
            </>
          }
          {(params.item['en'].CompetitionLogo && params.item['en'].CompetitionName) &&
            <Image src={params.item['en'].CompetitionLogo.path} alt={params.item['en'].CompetitionName} />
          }
        </div>
      </div>
      <div className="timeplay-top d-flex ai-start jc-center">
        <div className="w-30 d-flex jc-end ai-center">
          {isHome &&
            <>
              <div className="timeplay-logo-container">
                <img src={link} alt="Widzew Łódź" />
                Widzew Łódź
              </div>
            </>
          }
          {!isHome &&
            <>
              <div className="timeplay-logo-container">
                {(params.item['en'].OpponentCrest.path && params.item['en'].OpponentName) &&
                  <Image src={params.item['en'].OpponentCrest.path} altText={params.item['en'].OpponentName} />
                }
                {params.item['en'].OpponentName && params.item['en'].OpponentName}
              </div>
            </>
          }
        </div>
        <div className="date-container d-flex f-column ai-center jc-center w-30 ">
          {(params.item['en'].IsFinished === false && !isInProgress && !isInEnd) &&
            <span className="timetable-time">{params.item['en'].StartTime && params.item['en'].StartTime}</span>
          }
          {(params.item['en'].IsFinished === false && isInProgress && !isInEnd && params.item['en'].IsFriendly === true) &&
            <span className="timetable-time">{params.item['en'].StartTime && params.item['en'].StartTime}</span>
          }
          {matchInProgressScore && isInProgress && !isInEnd &&
            <span className="timetable-info gold">{returnLiveTime()}</span>
          }
          {matchInProgressScore && !isInProgress && isInEnd && params.item['en'].IsFinished !== true &&
            <span className="timetable-info gold">KONIEC</span>
          }
          {(matchInProgressScore && isInProgress && !isInEnd && params.item['en'].IsFinished !== true) &&
            <span className="timetable-time">{returnScore() ? returnScore() : '0:0'}</span>
          }
          {matchInProgressScore && !isInProgress && isInEnd && params.item['en'].IsFinished !== true &&
            <span className="timetable-result">{returnScore()}</span>
          }
          {params.item['en'].IsFinished === true &&
            <span className="timetable-result">{params.item['en'].MatchResult && params.item['en'].MatchResult}</span>
          }
          {params.item['en'].StadiumName &&
            <small
              className="stadium-big"
              dangerouslySetInnerHTML={
                { __html: params.item['en'].StadiumName }
              }
            >
            </small>
          }
          {/* {params.item['en'].TicketsAvailability === "available" &&
            <div className="d-flex f-column mt-20 tickets-big">
              <span className="tickets-amount">{tickets()}</span>
              <small>sprzedanych biletów</small>
            </div>
          } */}
          {tickets()}
        </div>
        <div className="w-30 ">
          {isHome &&
            <div className="timeplay-logo-container">
              {(params.item['en'].OpponentCrest.path && params.item['en'].OpponentName) &&
                <Image src={params.item['en'].OpponentCrest.path} altText={params.item['en'].OpponentName} />
              }
              {params.item['en'].OpponentName && params.item['en'].OpponentName}
            </div>
          }
          {!isHome &&
            <>
              <div className="timeplay-logo-container">
                <img src={link} alt="Widzew Łódź" />
                Widzew Łódź
              </div>
            </>
          }
        </div>
      </div>
      <div className="jc-center stadium-small ta-center mt-10">
        {params.item['en'].StadiumName &&
          <small
            dangerouslySetInnerHTML={
              { __html: params.item['en'].StadiumName }
            }
          >
            {/* {params.item['en'].StadiumName.replace(/\<br\>/g, " ")} */}
          </small>
        }
      </div>
      <div className="timetable-bottom d-flex jc-center ai-center mt-20">
        <div className="timetable-buttons jc-center">
          {params.item['en'].IsFriendly === false &&
            generateDetailsLink()
          }
          {params.item['en'].TicketPurchasePage &&
            // <Links line={params.line} url={params.item['en'].TicketPurchasePage} target="true" class="button b-gold" name="Kup bilet" />
            <a href={params.item['en'].TicketPurchasePage} target="_blank" rel="noreferrer" className="button b-gold gi-button d-flex ai-center jc-center">
              <span className="gi-text-h">
                BILETY
              </span>
              <span className="svgTicket inner-icon"
                dangerouslySetInnerHTML={
                  { __html: svgTicket }
                }
              >
              </span>
              <span className="gi-text">
                BILETY
              </span>
            </a>
          }
          {params.item['en'].BroadcastPurchasePage &&
            // <Links line={params.line} url={params.item['en'].BroadcastPurchasePage} target="true" class="button b-gold" name="Kup transmisję" />
            <a href={params.item['en'].BroadcastPurchasePage} target="_blank" rel="noreferrer" className="button b-gold gi-button d-flex ai-center jc-center">
              <span className="gi-text-h">
                TRANSMISJA
              </span>
              <span className="svgTV inner-icon"
                dangerouslySetInnerHTML={
                  { __html: svgTV }
                }
              >
              </span>
              <span className="gi-text">
                TRANSMISJA
              </span>
            </a>
          }
          {params.item['en'].ProductPurchasePage &&
            // <Links line={params.line} url={params.item['en'].ProductPurchasePage} target="true" class="button b-gold" name="Sklep" />
            <a href={params.item['en'].ProductPurchasePage} target="_blank" rel="noreferrer" className="button b-gold gi-button d-flex ai-center jc-center">
              <span className="gi-text-h">
                SKLEP
              </span>
              <span className="svgMarket inner-icon"
                dangerouslySetInnerHTML={
                  { __html: svgMarket }
                }
              >
              </span>
              <span className="gi-text">
                SKLEP
              </span>
            </a>
          }
          {(params.item['en'].CustomButtonLink && params.item['en'].CustomButtonName) &&
            <Links line={params.line} url={params.item['en'].CustomButtonLink} target="true" class="button b-gold custom-button" name={params.item['en'].CustomButtonName} />
          }
        </div>
      </div>
    </div >
  )
}

export default TimetableCarouselItem