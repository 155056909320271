import React from "react";
import Image from "../../components/Image.js";

function StaffItem(params) {
    let image = params.block.hasOwnProperty('Image') ? (params.block['Image'].hasOwnProperty('link') ? params.block['Image']['link'] : '/.templates/img/logo/herbwidzewa.jpg') : '/.templates/img/logo/herbwidzewa.jpg',
        Email = params.block.hasOwnProperty('Email') ? params.block['Email'] : '',
        phone = params.block.hasOwnProperty('phone') ? params.block['phone'] : '',
        Text = params.block.hasOwnProperty('Text') ? params.block['Text'] : '',
        Title = params.block.hasOwnProperty('Title') ? params.block['Title'] : '',
        AltText = params.block.hasOwnProperty('AltText') ? params.block['AltText'] : Title,
        fooLenght = Email.length;

    return (
        <div className="staff-item col-4 pr-20">
            <div className="staff-title d-flex">
                {Title !== '' &&
                    <h3 className="js-end" dangerouslySetInnerHTML={
                        { __html: Title }
                    }
                    >
                    </h3>
                }
            </div>
            <div className="staff-image mt-15 d-flex f-row" direction="column">
                <Image src={`${image}_width=350`} alt={AltText} />
                <div className="staff-information d-flex f-column jc-between ml-15 mt-30 mb-15">
                    <div className="small-h">
                        <h3>{Text}</h3>
                    </div>
                    <div className="d-flex f-column">
                        {phone &&
                            <div>
                                <a className="phone" href={`tel:${phone}`}>{phone}</a>
                            </div>
                        }
                        {Email &&
                            <div>
                                <a className="mail" href={Email} style={fooLenght > 20 ? { fontSize: "1.2rem" } : { fontSize: "1.5rem" }}>{Email}</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default StaffItem;