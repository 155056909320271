import '../../.templates/css/portlets/partnersClub/PartnersClub.css';
import PartnersClubItem from "./PartnersClubItem";
import React from "react";

function PartnersClub(params) {
    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }

    let innerSection = 'partners-club';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <div className="custom-small">

                        {Object.keys(params.block['en']['Item']).map((item, i) => (
                            <PartnersClubItem
                                image={params.block['en']['Item'][item]['Image']['path']}
                                altText={params.block['en']['Item'][item]['AltText']}
                                title={params.block['en']['Item'][item]['Title']}
                                Text={params.block['en']['Item'][item]['Text']}
                                Link={params.block['en']['Item'][item]['Link']}
                                LinkName={params.block['en']['Item'][item]['LinkName']} />

                        ))}

                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnersClub;