import React from 'react'
import WidzewFmScheduleItem from './WidzewFmScheduleItem';

const WidzewFmScheduleGroup = (params) => {
  if (window.origin.includes('localhost')) {
    console.log(params.item)
  }
  const today = new Date(params.item.Date);
  const todayFormatted = new Intl.DateTimeFormat('pl-PL').format(today)
  return (
    <div className="widzew-schedule-group d-flex f-column mb-30">
      {params.item.Date &&
        <>
          <h3>
            {todayFormatted}
          </h3>
          {params.item.DayItem && params.item.DayItem.map((i, index) => (
            <WidzewFmScheduleItem key={index} item={i} />
          ))}
        </>
      }
    </div>
  )
}

export default WidzewFmScheduleGroup