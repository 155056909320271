import React from "react";
import AcademyItem from "./AcademyItem";
import Slider from "react-slick";
import AcademyButton from "./AcademyButton";


function Academy(params) {
    if (window.origin.includes('localhost') || window.origin.includes('dev-widzew') || window.origin.includes('dev-cms-widzew')) {
        console.log('Academy')
        console.log(params)
    }

    const nextArrow = `<svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 128 128" x="0px" y="0px"xmlns="http://www.w3.org/2000/svg"><path fill="white" id="Right_Arrow_4_" d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>`;
    const prevArrow = `
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <path fill="white" id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7 c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"/>
        </svg>
    `;

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, display: "block", width: '30px', height: '30px' }}
                dangerouslySetInnerHTML={
                    { __html: nextArrow }
                }
            >
            </div>
        );
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, display: "block", width: '30px', height: '30px' }}
                dangerouslySetInnerHTML={
                    { __html: prevArrow }
                }
            >
            </div>
        );
    }
    
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }
    let innerSection = 'academy';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }
    function Buttons() {
        if (params.block['en'].hasOwnProperty('Buttons')) {
            return (Object.keys(params.block['en']['Buttons']).map((button, i) => (
                <AcademyButton
                    key={'AcademyButton' + i}
                    block={params.block.en['Buttons'][button]}
                    line={params.line}
                />
            )))
        }
        else {
            return('')
        }
    }
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <div className="staff-title d-flex mt-20">
                        <h1> {params.block['en']['Title']}</h1>
                    </div>
                    <div className="academy-wrapper jc-between d-flex">
                        <div className="buttons-wrapper d-flex jc-center ai-end mb-10">
                            <Buttons />
                        </div>
                        <div className="mini-carousel-wrapper d-flex f-column">
                            <div className="carousel-title d-flex js-center">Sponsorzy Akademii</div>
                            <div className="mini-carousel">
                                <Slider {...settings}>
                                    {Object.keys(params.block['en']['Item']).map((item, i) => (
                                        <AcademyItem
                                            key={'AcademyItem' + i}
                                            image={params.block['en']['Item'][item]['Image'].path}
                                            altText={params.block['en']['Item'][item]['Image']['AltText']}
                                            Link={params.block['en']['Item'][item].Link.link}
                                            NewTab={params.block['en']['Item'][item].NewTab}
                                        />
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Academy;