import React, { useState } from 'react'

const Select = (params) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  function toggleOptions() {
    setIsOptionsOpen(!isOptionsOpen);
  };
  return (
    <div className={params.className}>
      <button
        type="button"
        className="select"
        aria-haspopup="listbox"
        aria-expanded={isOptionsOpen}
        onClick={toggleOptions}
      >
        {params.optionsList[params.selectedOption].title}
      </button>
      <ul
        className={`options ${isOptionsOpen ? "show" : ""}`}
        role="listbox"
        aria-activedescendant={params.optionsList[params.selectedOption].title}
        tabIndex={-1}
      >
        {params.optionsList.map((option, index) => (
          <li
            key={index}
            id={`select-id-${index}`}
            role="option"
            aria-selected={params.selectedOption === index}
            tabIndex={0}
            onClick={() => {
              params.setSelectedOption(index);
              params.setSelectedOptionString(option.showId)
              setIsOptionsOpen(false);
            }}
          >
            {option.title}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select