import React from 'react';
import Image from '../../components/Image';
import Slider from "react-slick";

const ImageCarousel = (params) => {
    const nextArrow = `
  <svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 128 128" x="0px" y="0px"xmlns="http://www.w3.org/2000/svg"><path id="Right_Arrow_4_" d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>
`;
    const prevArrow = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path id="Right_Arrow_4_" d="M336,80c4.1,0,8.2,1.6,11.3,4.7c6.3,6.3,6.3,16.4,0,22.6L198.6,256l148.7,148.7
 c6.3,6.3,6.3,16.4,0,22.6c-6.3,6.2-16.4,6.3-22.6,0l-160-160c-6.3-6.3-6.3-16.4,0-22.6l160-160C327.8,81.6,331.9,80,336,80L336,80z"
 />
</svg>
  `;

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, display: "block", width: '30px', height: '30px' }}
                dangerouslySetInnerHTML={
                    { __html: nextArrow }
                }
            >
            </div>
        );
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, display: "block", width: '30px', height: '30px' }}
                dangerouslySetInnerHTML={
                    { __html: prevArrow }
                }
            >
            </div>
        );
    }


    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }
    let innerSection = 'image-carousel';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }

    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <div className="d-flex jc-center">
                        {params.block['en']['Title'] && <h3>{params.block['en']['Title']}</h3>}
                    </div>
                    <div className="carousel-wrapper">
                        <Slider {...settings}>
                            {Object.keys(params.block['en']['Item']).map((item, i) => (
                                <div className="item" key={'sponsCarouItem' + i}>
                                    {params.block['en']['Item'][item]['Link'] &&
                                        <a
                                            target={params.block['en']['Item'][item]['NewTab'] == true ? '_blank' : ''}
                                            href={params.block['en']['Item'][item]['Link']['link']}
                                            className="all-link-img"
                                        >
                                            <Image
                                                src={params.block['en']['Item'][item]['Image']['path'] && params.block['en']['Item'][item]['Image']['path']}
                                                alt={params.block['en']['Item'][item]['AltText'] && params.block['en']['Item'][item]['AltText']}
                                            />
                                        </a>}
                                    {!params.block['en']['Item'][item]['Link'] &&
                                        <Image
                                            src={params.block['en']['Item'][item]['Image']['path'] && params.block['en']['Item'][item]['Image']['path']}
                                            alt={params.block['en']['Item'][item]['AltText'] && params.block['en']['Item'][item]['AltText']}
                                        />
                                    }
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImageCarousel