import React from "react";
import Links from "../../components/Links";

function TopBannerTitleItem(params) {
    let active = 'act-false'
    if (params.active !== '' && params.active && params.active === true) {
        active = 'act-true'
    }
  
    return (
        <div className="item">
            <Links line={params.line} url={params.link} class={active} name={params.name} />
        </div>
    );
}

export default TopBannerTitleItem;